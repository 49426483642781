

































































import Vue, { PropType } from 'vue';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { TaskStatusEnum } from '@/bundles/Tasks/enums/TaskStatusEnum';
import { TaskTypesLabels } from '@/bundles/Tasks/enums/TaskTypesEnum';
import { DateTime } from 'luxon';

import CollectionTile from '@/bundles/Tasks/components/CollectionTile.vue';

export default Vue.extend({
  name: 'TaskTableContentItem',

  components: { CollectionTile },

  props: {
    item: {
      type: Object as PropType<ITask>,
      required: true,
    },
    isSelected: Boolean,
  },

  computed: {
    taskStatusEnum (): typeof TaskStatusEnum {
      return TaskStatusEnum;
    },
    taskTypesLabels (): typeof TaskTypesLabels {
      return TaskTypesLabels;
    },
    assignedToLabel (): string {
      if (this.item.assignee_info) {
        return this.item.assignee_info.name;
      }
      return 'N/A';
    },
    taskDotInfo (): { text: string; class: string } {
      if (this.item.status === TaskStatusEnum.completed) {
        return { text: 'Completed', class: 'completed' };
      }

      const dateDue = DateTime.fromISO(`${this.item.due_date}T${this.item.due_time}`);
      const currentDate = DateTime.now();

      const isExpired = dateDue < currentDate && this.item.status !== TaskStatusEnum.completed;
      const isExpiring = dateDue < currentDate.plus({ hours: 7 }) && dateDue > currentDate && this.item.status !== TaskStatusEnum.completed;

      if (isExpiring) {
        return { text: 'Expiring soon', class: 'expiring' };
      }

      if (isExpired) {
        return { text: 'Expired', class: 'expired' };
      }

      return { text: 'Active', class: '' };
    },
    formattedDueDate (): string {
      return DateTime.fromISO(`${this.item.due_date}T${this.item.due_time}`).toLocaleString(DateTime.DATETIME_SHORT);
    },
  },
});
