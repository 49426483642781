































































































































































import Vue, { PropType } from 'vue';
import { ITableOptions, IHeader } from '@/bundles/BaseTable/interfaces';

interface IBaseTableInstance {
  options: ITableOptions,
  footerOptions: {
    itemsPerPageText: string;
    disableItemsPerPage: boolean;
    itemsPerPageOptions: number[];
    showFirstLastPage: boolean;
  };
}

export default Vue.extend({
  name: 'BaseTable',

  props: {
    tableOptionsEntry: {
      type: Object as PropType<ITableOptions>,
      required: true,
    },
    itemsEntry: {
      type: Array,
      default: () => [],
    },
    totalRowsEntry: {
      type: Number,
      default: 0,
    },
    headersDataEntry: {
      type: Array as PropType<IHeader[]>,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    tableHeight: {
      type: Number,
      default: null,
    },
    allowSelect: Boolean,
    loading: Boolean,
    fixedHeaders: Boolean,
    hidePagination: Boolean,
    allowShowCheckAll: {
      type: Boolean,
      default: true,
    },
    disableSort: Boolean,
    isMergeTable: Boolean,
    itemKey: {
      type: String,
      default: '_key',
    },
    singleSelect: Boolean,
  },

  data: (): IBaseTableInstance => ({
    options: {
      groupBy: [],
      itemsPerPage: 50,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
      itemsPerPageOptions: [
        { title: '50', value: 50 },
        { title: '100', value: 100 },
        { title: '250', value: 250 },
        { title: '500', value: 500 },
        { title: '750', value: 750 },
        { title: '1000', value: 1000 },
        { title: '1500', value: 1500 }
      ]
    },
    footerOptions: {
      itemsPerPageText: '',
      disableItemsPerPage: true,
      itemsPerPageOptions: [10, 25, 100],
      showFirstLastPage: true,
    },
  }),

  computed: {
    pageCount: function (): number {
      if (this.totalRowsEntry <= this.options.itemsPerPage) {
        return 1;
      }

      return Math.ceil(this.totalRowsEntry / this.options.itemsPerPage);
    },
    calcTableHeight: function () {
      if (this.selected.length && this.tableHeight) {
        return this.tableHeight - 40;
      }

      return this.tableHeight;
    },
    dataTableSelected (): Record<string, any>[] {
      const isSelectedObjects = this.selected.every((item) => typeof item === 'object' && item !== null);
      if (isSelectedObjects) {
        return this.selected as Record<string, any>[];
      }

      return this.selected.map((item) => ({ [this.itemKey]: item }));
    }
  },

  watch: {
    options: {
      handler: function (value) {
        this.$emit('options:change', value);
      },
      deep: true,
    },
    tableOptionsEntry: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.options)) {
          this.options = { ...value };
        }
      },
      deep: true,
      immediate: true
    },
  },

  created () {
    this.$eventBus.$emit('refresh:table-height');
  },

  mounted () {
    this.$eventBus.$on('filter-change', this.unCheckAll);
  },

  beforeDestroy () {
    this.$eventBus.$off('filter-change', this.unCheckAll);
  },

  methods: {
    updatePerPage (item) {
      this.options.itemsPerPage = item.value
      this.options.page = 1
    },
    generateIconName: function (isAscending: boolean): string {
      return `mdi-arrow-${ isAscending ? 'up' : 'down' }-thin`;
    },
    isActiveSort: function (item: IHeader): boolean {
      const sortBy = Array.isArray(this.options.sortBy)
        ? this.options.sortBy[0]
        : this.options.sortBy;
      const valueText = item.value;
      return sortBy === valueText;
    },
    setSort: function ({ value, sort, text }: { value: string; sort: boolean, text: string }) {
      this.options = { ...this.options, sortBy: [value], sortDesc: [sort], sortText: text };
      document.querySelector('.base-table .v-data-table__wrapper')!.scrollTop = 0
    },

    onToggleSelectAll: function (event) {
      const selectAllEventName = this.isMergeTable ? 'checkAll' : 'changeSelected';
      const eventName = event.value ? selectAllEventName : 'uncheckAll';

      this.$emit(eventName, event.items);
    },
    unCheckAll () {
      this.$emit('uncheckAll');
    },
    getSorting (item: IHeader): boolean {
      return this.isActiveSort(item) ? !this.options.sortDesc?.[0] : true;
    },
    isAscendingSorting (item: IHeader): boolean {
      return this.isActiveSort(item) ? !this.options.sortDesc?.[0] : false;
    }
  },
});
