import Profile from '@/bundles/Members/models/Profile';

export default class ProfileToMapper {
  mapToModel (data: any): Profile {
    const profile = new Profile();
    profile.id = data.id;
    profile.first_name = data.first_name;
    profile.last_name = data.last_name;
    profile.img = data.img;
    profile.last_login = data.last_login;
    profile.real_estate_career_start_date = data.real_estate_career_start_date;
    profile.date_of_birth = data.date_of_birth;
    profile.sex = data.sex;
    profile.email_signature = data.email_signature;
    profile.former_firm = data.former_firm;
    return profile;
  }
}
