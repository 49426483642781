import { IPermissions } from '@/bundles/App/interfaces/IPermissions';
import { ICompany } from '@/bundles/Companies/types';
import { IContact } from '@/bundles/Contact/interfaces/IContact';
import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';
import { Nullable } from '@/utils/types';
import { ExpirationTypeEnum } from '@/bundles/Comparables/enums/ExpirationTypeEnum';
import { IUseType } from '@/bundles/UseType/types';
import { SaleConditionsEnum } from '@/bundles/Comparables/enums/SaleConditionsEnum';

export enum ComparableCompanyRoles {
  buyer = 'buyer',
  seller = 'seller',
  landlord = 'landlord',
  tenant = 'tenant',
}

export enum ComparableContactRoles {
  buyer = 'buyer',
  seller = 'seller',
  landlord = 'landlord',
  tenant = 'tenant',
  seller_agent = 'seller_agent',
  buyer_agent = 'buyer_agent',
  tenant_agent = 'tenant_agent',
  source = 'source',
  other = 'other',
  landlord_agent = 'landlord_agent'
}

export type ComparableCompanyRolesType = keyof typeof ComparableCompanyRoles;
export type ComparableContactRolesType = keyof typeof ComparableContactRoles;

export type CompaniesData = Record<ComparableCompanyRolesType, Nullable<ICompany>>;
export type ContactsData = Record<ComparableContactRolesType, IContact[]>;

export enum TransactionTypesEnum {
  lease = 'lease',
  sale = 'sale'
}

export interface IComparableSummary {
  comments: {
    private: string;
    public: string;
    escalation: string;
    options: string;
  };
  address: {
    street: string;
    street2: string;
  };
  primary_use: Nullable<PropertyTypeEnum>;
  secondary_use: Nullable<PropertyTypeEnum>;
  use_types: IUseType[];
  floor_count: Nullable<number>;
  floor: Nullable<number>;
  confidential: boolean;
}

export enum TransactionTypeEnum {
  sale = 'sale',
  lease = 'lease'
}

export interface ITransaction {
  transaction_type: TransactionTypeEnum;
  transaction_date: string;
  total_square_feet: number;
  total_acres: number;
  office_space: Nullable<number>;
  bts: boolean;
  land_pol: boolean;
  date_on_market: Nullable<string>;
  sale_conditions: SaleConditionsEnum[];
};

export interface ITransactionSale extends ITransaction {
  investment_sale: boolean;
  cap_rate: Nullable<number>;
  noi: Nullable<number>;
  percent_leased: Nullable<number>;
  total_price: Nullable<number>;
  total_price_per: Nullable<number>;
  asking_price: Nullable<number>;
  asking_price_sf: Nullable<number>;
}

export enum RentScheduleTypeEnum {
  new = 'New',
  renewal = 'Renewal',
  modification = 'Modification',
  expansion = 'Expansion',
  extension = 'Extension',
}

export interface ITransactionLease extends ITransaction {
  average_rent_sf: number;
  effective_rent_sf: number;
  free_rent: number;
  lease_commencement: number;
  lease_expiration: Nullable<string>; // ISO date
  lease_term: string; // TODO sychdo: prepare enum
  lease_term_length: Nullable<number>;
  lease_type: string // TODO sychdo: prepare enum
  schedule_id: Nullable<string>;
  starting_rent_sf: number;
  asking_rate_sf: Nullable<number>;
  sublease: boolean;
  opex_fixed: Nullable<number>;
  opex_per: Nullable<number>;
  total_consideration: number;
  ti_tenant_per: Nullable<number>;
  ti_tenant_fixed: Nullable<number>;
  ti_landlord_per: Nullable<number>;
  ti_landlord_fixed: Nullable<number>;
  ti_landlord_asis: boolean;
  escalation: Nullable<number>;
  lease_expiration_type: Nullable<ExpirationTypeEnum>;
  schedule_type: RentScheduleTypeEnum;
  tmi: Nullable<number>;
}

export interface IComparableCreatePayload {
  propertyKey: string;
  availabilityKey: Nullable<string>;
  occupierKey: Nullable<string>;
  transaction: ITransaction;
  summary: Nullable<IComparableSummary>;
  contacts: any;
  companies: any;
  permissions: IPermissions;
}

// TODO: add proper type
export type IComparable = any;
