export enum RootActions {
  MULTI_ACCOUNT_SIGN_IN = 'multiAccountSignIn',
  FETCH_CURRENT_USER = 'fetchCurrentUser',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_SILENTLY = 'LOG_OUT_SILENTLY',
  LOG_IN_WITH_MAGIC_LINK = 'loginWithMagicLink',
  SET_LOGIN_INFO = 'setLoginInfo',
  FETCH_ACCOUNT_SETTINGS = 'fetchAccountSettings',
  UPDATE_ACCOUNT_SETTINGS = 'updateAccountSettings',
  FETCH_USER_INTEGRATIONS = 'fetchUserIntegrations',
  CANCEL_PENDING_REQUESTS = 'cancelPendingRequests',
}
