import { IBaseMapper } from '@/bundles/App/interfaces/IBaseMapper';
import { ICriticalDateListResponse } from '@/bundles/CriticalDates/interfaces';
import CriticalDateListResponse from '@/bundles/CriticalDates/models/CriticalDateListResponse';
import { cloneDeep } from 'lodash';

export default class CriticalDateListResponseToMapper implements IBaseMapper<ICriticalDateListResponse> {
  mapToModel (data: ICriticalDateListResponse) {
    const tempData = cloneDeep(data);
    tempData.lease_date.option_date = {
      start: null,
      end: null,
    }
    return new CriticalDateListResponse(tempData);
  }
}
