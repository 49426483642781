import CriticalDateService from '@/bundles/CriticalDates/services/CriticalDateService';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import CriticalDateListResponseToMapper from '@/bundles/CriticalDates/mappers/CriticalDateListResponseToMapper';

function criticalDateServiceFactory () {
  return new CriticalDateService({
    errorMapper: new ErrorMapper(),
    transport: httpV2,
    recordMapper: new CriticalDateListResponseToMapper(),
  });
}

export const criticalDateService = criticalDateServiceFactory();
