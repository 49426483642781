import { IUploadFile } from '@/bundles/Upload/interfaces';

export class Upload implements IUploadFile {
  url: string;
  mimetype: string;

  constructor (payload: IUploadFile) {
    this.url = payload.url;
    this.mimetype = payload.mimetype;
  }
}
