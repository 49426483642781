import instance from '../instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { generateParams } from '@/utils/helpers';

const errorMapper = new ErrorMapper();
const resource = '/task';

export default {
  getOne: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAssociated: async function ({ token, params }) {
    try {
      return await instance.get(`${resource}/record-search${generateParams(params)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/archive`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
