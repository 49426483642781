<template>
  <v-footer
    v-if="displayFooter"
    height="auto"
    class="white--text text-xs-center"
    :style="appColor"
  >
    <v-layout row wrap justify-center>
      <!--<v-btn
        v-for="link in links"
        :key="link"
        color="white"
        flat
      >
        {{ link }}
      </v-btn>-->
      <v-flex
        xs12
        py-3
        text-xs-center
        white--text
      >
        &copy;2018 — <strong>Slick Cactus</strong>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: ['Home', 'About Us', 'Team', 'Services', 'Blog', 'Contact Us'],
    displayFooter: false,
  }),
  computed: {
    appColor () {
      return 'background: ' + this.$store.state.color
    },
  },
}
</script>
