














import Vue from 'vue';
import { getPropertyTypeColor } from '@/bundles/Property/helpers/colors/getPropertyTypeColor';
import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';

export default Vue.extend({
  name: 'PropertyTypeChip',

  props: {
    propertyType: {
      type: String,
      required: true
    },
  },

  methods: {
    getPropertyTypeColor,
    isDarkChip (propertyType: PropertyTypeEnum) {
      return propertyType !== PropertyTypeEnum.retail;
    }
  }
});
