



























import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';
import mixins from 'vue-typed-mixins';

export default mixins(DialogMixin).extend({
  name: 'ConfirmationDialog',

  props: {
    title: {
      type: String,
      default: 'Please Confirm Your Action',
    },
    submitBtnText: {
      type: String,
      default: 'Confirm',
    },
    hideActions: Boolean,
    loading: Boolean
  }
})
