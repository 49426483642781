enum CropperFuncNamesEnum {
  crop = 'crop',
  rotate = 'rotate'
}

const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

const getPDFPreviewUrl = (url: string) => {
  return `${url}#toolbar=0&navpanes=0&scrollbar=0`;
}

export { CropperFuncNamesEnum, formatBytes, getPDFPreviewUrl };
