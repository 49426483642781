import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();

export default {
  setAllPermissions: async function (permissions, token) {
    try {
      return await instance.put('/member', permissions, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendFeedback: async function ({ payload, token }) {
    try {
      return await instance.post('/request', payload, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRequests: async function ({ token, params = [] }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`/request${paramsString}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  vote: async function ({ id, token }) {
    try {
      return await instance.patch(`/request/${id}/vote`, {}, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
