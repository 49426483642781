
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { formatPhone } from '@/utils/filters';
import { IBroker } from '@/bundles/Brokers/interfaces/IBroker';
import { getAccountRouteHandler } from '@/bundles/App/helpers';

const getBrokerTemplate = (h: CreateElement, ctx: RenderContext, broker: IBroker, activeAccountId: number) => {
  const template: VNode[] = [
    h('router-link',
      {
        attrs: { target: '_blank', class: 'broker-name' },
        props: { to: getAccountRouteHandler({ name: 'contact', params: { id: broker._key }, activeAccountId }) }
      },
      `${broker.name}`
    )
  ];

  if (broker?.phone?.direct) {
    template.push(h('div', [
      h('strong', 'Direct: '),
      h('a', { attrs: { href: `tel:${broker.phone.direct}` } }, [
        h('span', formatPhone(broker.phone.direct)),
      ]),
    ]));
  }

  if (broker?.phone?.mobile) {
    template.push(h('div', [
      h('strong', 'Mobile: '),
      h('a', { attrs: { href: `tel:${broker.phone.mobile}` } }, [
        h('span', formatPhone(broker.phone.mobile)),
      ]),
    ]));
  }

  template.push(h('div', broker.email));

  return template;
}

export default Vue.extend({
  name: 'AvailabilityBrokersInfo',

  functional: true,

  props: {
    brokers: {
      type: Array as PropType<IBroker[]>,
      required: true
    },
    activeAccountId: {
      type: Number,
      required: true
    },
    withTooltipForAllBrokers: Boolean
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const { brokers, activeAccountId, withTooltipForAllBrokers } = ctx.props;

    const cols: VNode[] = [];

    if (!brokers.length) {
      return h('v-row', cols);
    }

    if (!withTooltipForAllBrokers) {
      brokers.forEach(broker => {
        const template = getBrokerTemplate(h, ctx, broker, activeAccountId);

        cols.push(h('v-col', { attrs: { cols: 12 } }, template));
      });
    } else {
      const [firstBroker, ...restBrokers] = brokers;
      const template = getBrokerTemplate(h, ctx, firstBroker, activeAccountId);

      if (restBrokers.length) {
        template.push(h('v-menu', {
          attrs: {
            openOnHover: true,
            left: true,
            top: true,
            transition: 'scale-transition',
            allowOverflow: true,
            offsetX: true,
            closeOnContentClick: false,
          },
          scopedSlots: {
            activator: ({ on, attrs }) => {
              return h('v-btn', {
                props: {
                  small: true,
                },
                on,
                attrs,
              }, [
                'More',
                h('v-icon', { props: { small: true } }, 'mdi-information-outline'),
              ]);
            }
          },
        },
        [
          h('v-card', [
            ...restBrokers.map(broker => {
              const template = getBrokerTemplate(h, ctx, broker, activeAccountId);
              return h('v-col', { attrs: { cols: 12 } }, template);
            })
          ])
        ]
        ));
      }

      cols.push(h('v-col', { attrs: { cols: 12 } }, template));
    }

    return h('v-row', cols);
  }
})
