













import { PropType } from 'vue';
import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';
import mixins from 'vue-typed-mixins';
import { taskService } from '@/bundles/Tasks/factories/taskServiceFactory';
import { TaskCreateUpdateDTO } from '@/bundles/Tasks/dto/TaskCreateUpdateDTO';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';

import BaseTaskForm from '@/bundles/Tasks/components/BaseTaskForm.vue';

interface ICreateTaskForm {
  saveLoading: boolean;
}

export default mixins(DialogMixin).extend({
  name: 'CreateTaskForm',

  components: { BaseTaskForm },

  props: {
    collectionType: {
      type: String,
      default: null
    },
    associatedRecords: {
      type: Array as PropType<any[]>, // todo: type this
      default: () => []
    }
  },

  data: (): ICreateTaskForm => ({
    saveLoading: false,
  }),

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    async submitHandler (task: TaskCreateUpdateDTO) {
      try {
        this.saveLoading = true;

        const newTask = await taskService.createTask(task);

        this.$emit('submit', newTask);
        this.showDialog = false;
        this.$eventBus.$emit('refresh-activity', newTask._id);
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saveLoading = false;
      }
    }
  }
});
