import instance from '@/services/instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/subscription';

export default {
  list: async function (token) {
    try {
      return await instance.get(resource, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
