import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const initialState = {
  squareUnits: 'miles',
  distanceUnits: 'miles',
  routeUnits: 'miles',
  polygonArea: 0,
  distanceLinestring: {
    geometry: {
      coordinates: [
        [0, 0],
        [0, 0],
      ],
      type: 'LineString',
    },
    type: 'Feature',
  },
  layers: [],
  layersNew: {},
  setsMap: {
    center: [-96.82245899999998, 32.96001],
    zoom: 8,
    style: 'light',
  },
  map: {
    center: [-96.82245899999998, 32.96001],
    zoom: 8,
    style: 'light',
  },
  initialLayers: [
    {
      id: 'point',
      source: 'single-point',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#007cbf',
      },
    },
    {
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#6CC04A',
        'line-width': 2.5,
      },
      filter: ['in', '$type', 'LineString'],
    },
    {
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#6CC04A',
      },
      filter: ['in', '$type', 'Point'],
    },
  ],
  filters: {
    polygon: {
      enabled: false,
      value: null,
    },
  },
  tempFeatures: [],
  tempFeaturesType: '',
  mapView: 'points',
  mapShow: false,
  standardLayers: [],
  pitch: 0,
  defaultMapStyles: [
    {
      text: 'Streets',
      value: 'streets',
      url: 'mapbox/streets-v11?optimize=true',
      icon: 'mdi-map-marker-path',
      system: true,
    },
    {
      text: 'Dark',
      value: 'dark',
      url: 'mapbox/dark-v11?optimize=true',
      icon: 'mdi-map',
      system: true,
    },
    {
      text: 'Light',
      value: 'light',
      url: 'mapbox/light-v11?optimize=true',
      icon: 'mdi-map-outline',
      system: true,
    },
    {
      text: 'Satellite/Streets',
      value: 'satellite-streets',
      url: 'mapbox/satellite-streets-v11?optimize=true',
      icon: 'mdi-image-filter-hdr',
      system: true,
    },
    {
      text: 'Blueprint',
      value: 'blueprint',
      url: 'kwfricke/cl34m47zc002d14t9j8mmxzkl',
      icon: 'mdi-border-none',
      system: true,
    },
  ]
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
