import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import MemberToMapper from '@/bundles/Members/mappers/MemberToMapper';
import Member from '@/bundles/Members/models/Member';
import { PaginationResponse } from '@/types/PagintaionResponse';
import { generateParams } from '@/utils/helpers';
import CreateUserDto from '@/bundles/Members/dto/create-user';
import { IBufferResponse, IExportPayload } from '@/bundles/App/interfaces/IExport';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { OfficeLocation } from '@/bundles/OfficeLocation/models/OfficeLocation';
import { Nullable } from '@/utils/types';

export default class MemberService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly memberMapper: MemberToMapper;

  constructor ({
    transport,
    errorMapper,
    memberMapper,
  }: {
    transport: HttpTransport;
    errorMapper: ErrorMapper;
    memberMapper: MemberToMapper;
  }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.memberMapper = memberMapper;
  }

  async list (params: any[]): Promise<PaginationResponse<Member>> {
    try {
      const paramsString = generateParams(params);
      const { data, total } = await this.transport.get(`/user${ paramsString }`);
      return {
        data: data.map((rawData: any) => this.memberMapper.mapToModel(rawData)),
        total,
      };
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async create (payload: CreateUserDto): Promise<Member> {
    try {
      const response = await this.transport.post('/user', payload);
      return this.memberMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getOne (id: number): Promise<Member> {
    try {
      const response = await this.transport.get(`/user/${ id }`);
      return this.memberMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getMe (): Promise<Member> {
    try {
      const response = await this.transport.get(`/user/info/me`);
      return this.memberMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async changeUserPassword (id: number, payload: { password: string }): Promise<void> {
    try {
      return await this.transport.patch(`/user/${ id }/password`, payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async changeUserRoles (id: number, payload: { roles: number[] }): Promise<Member> {
    try {
      const response = await this.transport.patch(`/user/${ id }/roles`, payload);
      return this.memberMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async archive ({ ids = [] }: { ids: number[] }): Promise<any> {
    try {
      return this.transport.patch('/user/archive', { ids });
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getTeamMembersForUser (id: number, params: Record<string, string|boolean> = {}): Promise<Member[]> {
    try {
      const response = await this.transport.get(`/user/${ id }/team`, params);
      return response.map((record: any) => this.memberMapper.mapToModel(record));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async exportToExcel (payload: IExportPayload): Promise<IBufferResponse> {
    try {
      return await this.transport.post(`/user/export-members/`, payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getMemberPrimaryLocation (id: number): Promise<Nullable<OfficeLocation>> {
    try {
      const response = await this.transport.get(`/user/${ id }/primary-location`);

      if (isValuePresent(response)) {
        return new OfficeLocation(response);
      }

      return null;
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async restore (id: number): Promise<void> {
    try {
      await this.transport.patch(`/user/${ id }/restore`, null);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
