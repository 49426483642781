var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"common-data-table"}},[_c('v-data-table',{staticClass:"sc-data-table",attrs:{"headers":_vm.headers,"items":_vm.tasks,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"items-per-page":_vm.itemsPerPage,"page":_vm.pagination.page,"loading":_vm.isTableLoading,"hide-default-header":"","hide-default-footer":"","mobile-breakpoint":0,"item-key":"_id"},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',[_c('th',{staticClass:"px-4"},[_c('v-checkbox',{attrs:{"primary":"","hide-details":""},on:{"change":_vm.toggleHandler}})],1),_c('th'),_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
            'column sortable',
            _vm.pagination.descending ? 'desc' : 'asc',
            header.value === _vm.pagination.sortBy ? 'active' : '' ],staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.changeSort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),(header.value === _vm.pagination.sortBy)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")]):_vm._e()],1)})],2)]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('TaskTableContentItem',{attrs:{"item":item},on:{"edit:task":function($event){return _vm.editTaskHandler(item)},"select:task":_vm.selectItemHandler}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Per page: ")]),_c('v-select',{staticClass:"pagination-select",attrs:{"items":_vm.pagination.itemsPerPageOptions,"hide-details":"","disabled":_vm.isTableLoading,"dense":""},on:{"change":_vm.perPageChangeHandler},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-pagination',{attrs:{"length":_vm.pagesLength,"disabled":_vm.isTableLoading,"total-visible":_vm.$vuetify.breakpoint.xs ? 3 : 5},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)]},proxy:true}])}),_c('EditTaskForm',{attrs:{"associated-records":_vm.associatedRecords,"task-entry":_vm.taskToEdit,"collection-type":_vm.collectionType},on:{"submit":function($event){return _vm.$emit('update:task', $event)},"fetch:tasks":function($event){return _vm.$emit('fetch:tasks')},"close":function($event){_vm.taskToEdit = null}},model:{value:(_vm.editTaskDialog),callback:function ($$v) {_vm.editTaskDialog=$$v},expression:"editTaskDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }