
import Vue, { CreateElement, RenderContext, VNode } from 'vue';

export default Vue.extend({
  name: 'AppIcon',

  functional: true,

  props: {
    color: {
      type: String,
      default: undefined,
    },
    small: Boolean,
    large: Boolean,
  },

  render (h: CreateElement, ctx: RenderContext): VNode | VNode[] {
    const colorClass = ctx.props.color ? `${ctx.props.color}--text` : '';
    const iconFontSize = ctx.props.small ? '16px' : ctx.props.large ? '32px' : '24px';

    return h('i', {
      class: `v-icon notranslate mdi ${ctx.slots().default[0].text} theme--light ${colorClass}`,
      style: { fontSize: iconFontSize },
    });
  }
});
