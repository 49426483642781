import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/geo';

export default {
  getLocationDetails: async function ({ params, token }) {
    try {
      let paramsString = generateParams(params);
      return instance.get(`${resource}/locationDetails${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMarkets: async function ({ token }) {
    try {
      return instance.get(`${resource}/markets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveMarkets: async function ({ payload, token }) {
    try {
      return instance.put(`${resource}/markets`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveMarketMappings: async function ({ payload, token }) {
    try {
      return instance.put(`${resource}/market/mappings`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getLayersTree: async function ({ token }) {
    try {
      return await instance.get(`${resource}/layersTree`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getLayersTreeNew: async function ({ params, token }) {
    let paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/layersTreeNew${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addLayer: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/layer`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addSection: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/section`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createLayer: async function ({ token, file, payload }) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('name', payload.layerName);
    formData.append('layerType', payload.layerType);
    try {
      return await instance.post(`${resource}/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  importLayer: async function ({ token, file, payload }) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('name', payload.name);
    formData.append('parent', payload.parent);
    formData.append('section_id', payload.section_id);
    formData.append('section_name', payload.section_name);

    try {
      return await instance.post(`${resource}/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveCustomPolygon: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/polygon`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  list: async function ({ params, token }) {
    try {
      let paramsString = generateParams(params);

      return await instance.get(`${resource}/layers${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getSubLayers: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/layer/subLayers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPolygonList: async function ({ token }) {
    try {
      return await instance.get(`${resource}/polygon/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getCustomPolygon: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/polygon/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateCustomPolygon: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/polygon/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getLayer: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/layer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLayer: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/layer/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archiveLayer: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/layer/${id}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPointInPolygon: async function ({ params, token }) {
    try {
      return await instance.get(
        `${resource}/pointInPolygon?lng=' + ${params.longitude} + '&lat=' + ${params.latitude} + '&layer=Get_JSON&source' + ${params.source}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  refreshGeoCoordinates: async function ({ token, id, collection }) {
    try {
      return await instance.put(
        `${resource}/updateCoordinatesByAddress/${collection}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAddress: async function ({ params, token }) {
    try {
      let paramsString = generateParams(params);

      return await instance.get(`${resource}/address${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPlace: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/place/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAddressByKeyword: async function ({ key, token }) {
    try {
      return await instance.get(`${resource}/search?keyword=${key}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getParcel: async function ({ token, params }) {
    try {
      const paramsString = generateParams(params);

      return await instance.get(`${resource}/parcel${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getCountiesByState: async function ({ params, token }) {
    try {
      let paramsString = generateParams(params);

      return await instance.get(`${resource}/counties${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
