import { IOccupier } from '@/bundles/Occupiers/types';
import { Nullable } from '@/utils/types';

export const getOccupierLeaseTermLength = (occupier: IOccupier): Nullable<string> => {
  if (!occupier?.schedule?.periods?.length) {
    return null;
  }

  const calculatedTerm = {
    days: 0,
    months: 0
  };

  occupier.schedule.periods.reduce((acc, item) => {
    acc[item.period_type] += item.periods;

    return acc;
  }, calculatedTerm);

  return `${calculatedTerm.months ? `${calculatedTerm.months} Months` : ''} ${calculatedTerm.days ? `${calculatedTerm.days} Days` : ''}`;
};
