import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { tableOptionsStateBuilder } from './helpers';

const initialState = tableOptionsStateBuilder();

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
