import * as constants from './deals-types'

export default {
  [constants.PERSIST_ENTERED_INFO] (state, payload) {
    state.tempInfo = payload.info
    state.tempLink = payload.link
  },
  [constants.SET_DEALS_INVOICE] (state, payload) {
    state.dealInvoice = payload
  },
}
