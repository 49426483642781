import { IBaseModel } from '@/bundles/App/interfaces/IBaseModel';

interface IRecordMapperConstructor<T extends IBaseModel> {
  new (data: T): T;
}

export class RecordMapper<T extends IBaseModel> {
  Instantiator: IRecordMapperConstructor<T>

  constructor (Instantiator: IRecordMapperConstructor<T>) {
    this.Instantiator = Instantiator;
  }

  mapToModel (data: T): T {
    return new this.Instantiator(data);
  }
}
