const ReportPreview = () => import(/* webpackChunkName: "report" */ '@/bundles/Reports/views/ReportPreview.vue');

export default [
  {
    path: 'report/:id',
    name: 'ReportPreview',
    component: ReportPreview,
    props: true,
    meta: {
      title: 'Report',
    },
  },
];
