import { IBaseService } from '@/bundles/App/interfaces/IBaseService';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import CriticalDateListResponseToMapper from '@/bundles/CriticalDates/mappers/CriticalDateListResponseToMapper';
import { PaginationResponse } from '@/types/PagintaionResponse';
import CriticalDateListResponse from '@/bundles/CriticalDates/models/CriticalDateListResponse';
import CriticalLeaseDateCreateDTO from '@/bundles/CriticalDates/models/CriticalLeaseDateCreateDTO';
import CriticalLeaseDateUpdateDTO from '@/bundles/CriticalDates/models/CriticalLeaseDateUpdateDTO';

export default class CriticalDateService implements IBaseService<CriticalDateListResponseToMapper> {
  errorMapper: ErrorMapper;
  recordMapper: CriticalDateListResponseToMapper;
  transport: HttpTransport;

  constructor (payload: IBaseService<CriticalDateListResponseToMapper>) {
    this.errorMapper = payload.errorMapper;
    this.recordMapper = payload.recordMapper;
    this.transport = payload.transport;
  }

  async list (params: Record<string, string|number|boolean> = {}): Promise<PaginationResponse<CriticalDateListResponse>> {
    try {
      const { data, total } = await this.transport.get('/critical-date', params);
      return {
        total,
        data: data.map((item) => this.recordMapper.mapToModel(item)),
      };
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async createCriticalLeaseDate (payload: CriticalLeaseDateCreateDTO): Promise<any> { // todo lunt1ks: add type
    try {
      return await this.transport.post('/critical-date', payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateCriticalLeaseDate (payload: CriticalLeaseDateUpdateDTO, id: number): Promise<any> { // todo lunt1ks: add type
    try {
      return await this.transport.patch(`/critical-date/${id}`, payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
