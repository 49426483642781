import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';

export enum VerificationStatus {
  approved,
  rejected,
  pending
}

export class AvailabilityVerification {
  _key!: string;

  _id!: string;

  // TODO: prepare correct model
  listing!: any;

  status!: VerificationStatus;

  times_sent!: number;

  last_sent_date!: number;

  // TODO: prepare correct type
  contacts!: any[];

  settings!: string;

  message_payload!: Record<string, any>;

  created!: ICreatedUpdatedBy;

  updated!: ICreatedUpdatedBy;
}
