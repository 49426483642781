<template>
  <div class="tracker-list">
    <template v-if="items.length && !showDetails">
      <v-list v-if="openRequests.length" subheader>
        <v-subheader>OPEN REQUESTS ({{ openRequests.length }})</v-subheader>
        <v-list-item v-for="item in openRequests" :key="item._id">
          <v-list-item-avatar>
            <v-icon v-if="item.type === 'feature'" :color="`green`">mdi-star</v-icon>
            <v-icon v-else :color="`green`">mdi-bug</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="cursor-pointer"
              @click="openDetailedView(item)"
            >
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Created: {{ item.created.date | formatJSDateTime }} by
              {{ item.created.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="tracker-list__actions">
            <v-btn
              icon
              :large="$vuetify.breakpoint.smAndUp"
              :loading="isVoteLoading(item._key)"
              @click="$emit('vote', item._key)"
            >
              <v-icon>
                {{ isVoted(item.votes) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
              </v-icon>
            </v-btn>
            <div class="votes" @click="$emit('vote', item._key)">
              <div class="votes__value">{{ item.votes.length }}</div>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="closedRequests.length && openRequests.length" />
      <v-list v-if="closedRequests.length" two-line subheader>
        <v-subheader>
          COMPLETED REQUESTS ({{ closedRequests.length }})
        </v-subheader>
        <v-list-item v-for="item in closedRequests" :key="item._id">
          <v-list-item-content>
            <v-list-item-title
              class="cursor-pointer"
              @click="openDetailedView(item)"
            >
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.closed">
              Closed: {{ item.closed.date | formatJSDateTime }} by
              {{ item.closed.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon color="green">mdi-check-outline</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </template>
    <template v-else-if="items.length && showDetails">
      <v-card>
        <v-card-title>
          <v-chip
            small
            class="mb-2"
            :color="tempItem.status === 'closed' ? 'grey lighten-1' : 'green'"
            :dark="tempItem.status !== 'closed'"
          >
            {{ tempItem.status }}
          </v-chip>
          <v-spacer />
          <v-btn icon @click="showDetails = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="title mb-5">{{ tempItem.name }}</div>
              <v-divider class="mb-5" />
              <div>
                <div class="overline">Summary</div>
                <span>{{ tempItem.description }}</span>
              </div>
              <div v-if="tempItem.status.toLowerCase() === 'closed'">
                <strong>Closed by:</strong> {{ tempItem.closed.name }},
                {{ tempItem.closed.date | formatDateTime }} ({{ closedDiff(tempItem) }})
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="overline">Reported By</div>
              {{ tempItem.created.name }},
              {{ tempItem.created.date | formatDateTime }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="overline">Screenshot(s)</div>
              <span v-if="tempItem.screenshots">
                <span
                  v-for="screenshot in tempItem.screenshots"
                  :key="screenshot.url"
                >
                  <v-card class="ma-2">
                    <v-img max-height="300px" :src="screenshot.url" />
                  </v-card>
                </span>
              </span>
              <span v-else class="na">n/a</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="overline">ID</div>
              {{ tempItem._key }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <div v-else class="tracker-list__empty">
      No bugs or features have been submitted for this page
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { BugTrackerMixin } from '@/bundles/Requests/mixins/BugTrackerMixin';

export default {
  name: 'TrackerWrapperList',

  mixins: [BugTrackerMixin],

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    showDetails: false,
    tempItem: null,
  }),

  computed: {

    openRequests () {
      return this.items.filter((item) => item.status !== 'closed')
    },
    closedRequests () {
      return this.items.filter((item) => item.status === 'closed')
    },
  },

  methods: {
    openDetailedView (item) {
      this.showDetails = true
      this.tempItem = item
    },
    closedDiff (item) {
      if (item.status === 'closed') {
        const start = moment(item.created.date)
        const end = moment(item.closed.date)
        const diff = end.diff(start, 'hours')
        if (diff > 24) {
          const days = parseInt(diff / 24)
          const hours = diff - days * 24
          return (
            days +
            (days > 1 ? ' days, ' : ' day, ') +
            hours +
            (hours > 1 ? ' hours' : ' hour')
          )
        }
        return diff + ' hours'
      }
      return null
    },
  },
}
</script>

<style scoped></style>
