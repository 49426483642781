export default {
  state: {
    curPhoto: '',
  },
  mutations: {
    UPDATE_CUR_PHOTO (state, newValue) {
      state.curPhoto = newValue
    },
  },
  actions: {
    updateCurPhoto ({ state, commit }, value) {
      commit('UPDATE_CUR_PHOTO', value)
    },
  },
}
