export enum JobFunction {
  finance = 'finance',
  salesperson = 'salesperson',
  coordinator = 'coordinator',
  research = 'research',
  manager = 'manager',
  marketing = 'marketing',
  it = 'it',
  intern = 'intern',
  data_entry = 'data_entry',
  data_analyst = 'data_analyst',
  executive = 'executive',
  admin = 'admin',
  shareholder = 'shareholder',
  president = 'president'
}

export enum EmployeeStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum PaymentDetailsEnum {
  percentage = 'percentage',
  amount = 'amount'
}

export const PaymentDetailsLabels = {
  [PaymentDetailsEnum.percentage]: 'Percentage',
  [PaymentDetailsEnum.amount]: 'Dollar Amount',
}

export enum EmployeeType {
  employee = 'employee',
  contractor = 'contractor',
  temporary = 'temporary',
}

export enum AmountType {
  off_top = 'off_top',
  of_remaining = 'of_remaining',
}

export const AmountTypeLabels = {
  [AmountType.off_top]: 'Off Top',
  [AmountType.of_remaining]: 'Of Remaining',
}

export const JobFunctionLabels = {
  [JobFunction.salesperson]: 'Salesperson',
  [JobFunction.coordinator]: 'Transaction Coordinator',
  [JobFunction.research]: 'Research',
  [JobFunction.manager]: 'Operations Manager',
  [JobFunction.marketing]: 'Marketing',
  [JobFunction.it]: 'IT',
  [JobFunction.intern]: 'Intern',
  [JobFunction.finance]: 'Accounting / Finance',
  [JobFunction.data_entry]: 'Data Entry',
  [JobFunction.data_analyst]: 'Analyst',
  [JobFunction.executive]: 'Executive',
  [JobFunction.admin]: 'Admin',
  [JobFunction.president]: 'President',
  [JobFunction.shareholder]: 'Shareholder',
}

export enum OpexDisplayEnum {
  opex = 'opex',
  tmi = 'tmi'
};

export const OpexDisplayLabels = {
  [OpexDisplayEnum.opex]: 'Property Expenses',
  [OpexDisplayEnum.tmi]: 'NNN/TMI'
};
