











































































import { IHeader } from '@/bundles/BaseTable/interfaces';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';

export default mixins(AccountLayoutMixin).extend({
  name: 'BrokerRankingCardDetails',

  components: {
    LandSizeLabel,
    BuildingSizeLabel
  },

  props: {
    itemEntry: {
      type: Object, // TODO: add type
      required: true
    },
    reportTypeEntry: {
      type: String,
      required: true,
    },
    brokerGrossShowCommission: Boolean
  },

  computed: {
    headers: function (): IHeader[] {
      const headers = [
        { text: 'Deal Name', value: 'name' },
        { text: 'Consideration', value: 'consideration', align: 'end' },
      ];

      if (this.brokerGrossShowCommission) {
        headers.push(
          { text: 'Gross', value: 'brokerGross', align: 'end' },
          { text: 'Net', value: 'brokerNet', align: 'end' },
        );
      }

      headers.push(
        { text: 'Bldg Size', value: 'buildingSize', align: 'end' },
        { text: 'Land Size', value: 'landSize', align: 'end' }
      );

      return headers;
    },
  },
});
