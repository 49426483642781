









































































import Vue from 'vue';
import { ITextValue } from '@/types/common';
import CommonDatePicker from '@/components/common-components/pickers/CommonDatePicker.vue';

interface IData {
  timePeriodItems?: ITextValue<string>[];
}

export default Vue.extend({
  name: 'OpenBalancesDateFilter',

  components: { CommonDatePicker },

  props: {
    filterLabel: {
      type: String,
      default: '',
    },
    timePeriod: {
      type: String,
      default: '',
    },
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    dateToggle: {
      type: String,
      default: 'paid',
    },
  },

  data: (): IData => ({}),

  created () {
    this.timePeriodItems = [
      { text: 'This Calendar Month', value: 'month' },
      { text: 'This Calendar Year', value: 'year' },
      { text: 'Date Range', value: 'date_range' },
    ];
  },

  methods: {
    applyHandler () {
      (this.$refs.menu as any).save();
      this.$emit('click:apply');
    }
  }
})
