import { BusinessLineEnum, BusinessLineLabels, SourceEnum } from '@/bundles/Pipelines/types';
import { Nullable } from '@/utils/types';
export * from './formHelpers';

const pipelinesHeadersDataBase = [
  {
    'text': 'Logo',
    'value': '',
    'sortable': false
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Company',
      'value': 'company_name',
      'sortable': true,
      'icon': 'string'
    }, {
      'text': 'Deal Name',
      'value': 'deal_name',
      'sortable': true,
      'icon': 'string'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Property Type',
      'value': 'property_type',
      'sortable': true,
      'icon': 'string'
    }, {
      'text': 'Category',
      'value': 'category',
      'sortable': true,
      'icon': 'string',
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Size',
      'value': 'size',
      'sortable': true,
      'icon': 'number'
    }, {
      'text': 'Land Size',
      'value': 'land_size',
      'sortable': true,
      'icon': 'number'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Consideration',
      'value': 'consideration',
      'sortable': true,
      'icon': 'number'
    }, {
      'text': 'Commission %',
      'value': 'commission',
      'sortable': true,
      'icon': 'number'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Probability %',
      'value': 'probability',
      'sortable': true,
      'icon': 'number'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Close Date',
      'value': 'close_date',
      'sortable': true,
      'icon': 'date'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': '% of Deal',
      'value': 'broker_percentage',
      'sortable': true,
      'icon': 'number'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'In House Gross',
      'value': 'in_house_gross',
      'sortable': true,
      'icon': 'number'
    }, {
      'text': 'Broker Gross',
      'value': 'broker_gross',
      'sortable': true,
      'icon': 'number'
    }, {
      'text': 'Broker Gross Prob',
      'value': 'broker_gross_probability',
      'sortable': true,
      'icon': 'number'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Pipeline Stage',
      'value': 'stage',
      'sortable': true,
      'icon': 'string'
    }]
  },
  {
    'value': 'multi',
    'sortable': false,
    'multi': [{
      'text': 'Updated',
      'value': 'updated.date',
      'sortable': true,
      'icon': 'date'
    }, {
      'text': 'Created',
      'value': 'created.date',
      'sortable': true,
      'icon': 'date'
    }, {
      'text': 'Months Open',
      'value': 'daysOpen',
      'sortable': true,
      'icon': 'date'
    }]
  }
];

export const pipelinesHeadersData = [
  {
    'text': '',
    'sortable': false,
    'width': '1px',
    'value': 'data-table-select'
  },
  ...pipelinesHeadersDataBase
];

export const pipelinesCardHeadersData = [
  { text: '#', value: 'number', sortable: false },
  ...pipelinesHeadersDataBase
];

export const getSourceLabel = (source: SourceEnum) => {
  switch (source) {
    case SourceEnum.cold_call:
      return 'Cold Call';
    case SourceEnum.sign_call:
      return 'Sign Call';
    case SourceEnum.inbound_broker_referral:
      return 'Inbound Broker Referral';
    case SourceEnum.outbound_client_referral:
      return 'Outbound Client Referral';
    case SourceEnum.inbound_web:
      return 'Inbound Web';
    case SourceEnum.past_client:
      return 'Repeat Client';
    case SourceEnum.direct_marketing:
      return 'Direct Marketing';
    default:
      return 'Other';
  }
};

export const getBusinessLineLabel = (businessLine: BusinessLineEnum): Nullable<string> => {
  return BusinessLineLabels[businessLine] || null;
}
