




































































































































import Vue from 'vue';

import AvailabilityStatusChip from '@/bundles/Availabilities/components/AvailabilityStatusChip.vue';
import AvailabilityTransactionStatusChip from '@/bundles/Availabilities/components/AvailabilityTransactionStatusChip.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import PropertyTypeChip from '@/components/properties/PropertyTypeChip.vue';

export default Vue.extend({
  name: 'AvailabilityChipsWrapper',

  components: {
    CommonChip,
    AvailabilityStatusChip,
    PropertyTypeChip,
    AvailabilityTransactionStatusChip
  },

  props: {
    inHouse: Boolean,
    agencyType: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    vacancyType: {
      type: String,
      default: ''
    },
    buildingClass: {
      type: String,
      default: ''
    },
    transactionStatus: {
      type: String,
      default: ''
    },
    contractPending: Boolean,
    isPortfolio: Boolean,
    isOneLine: Boolean,
    mixedUse: Boolean,
    isPublishedToWeb: Boolean,
    allowWrap: Boolean,
    showTransactionStatus: Boolean,
  }
});
