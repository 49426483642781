<template>
  <v-card class="tracker-wrapper" flat>
    <v-row no-gutters>
      <v-col cols="12" md="8" class="mx-auto">
        <v-card-title>
          <span>Need help or have a request?</span>
          <v-spacer />
          <v-btn icon @click.stop="closeEventHandler">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-0">
          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
          >
            If you have a suggest, feature request or have found a bug, please let us
            know. We'll track them and alert you when we have addressed your
            request.
          </v-alert>
          <div class="tracker-wrapper-content">
            <template v-if="isListMode">
              <div class="tracker-wrapper-content__select">
                <v-spacer />
                <v-btn
                  v-if="items.length"
                  :text="!isListMode"
                  :color="isListMode ? `primary` : ``"
                  @click.native="changeViewModeEventHandler"
                >
                  <v-icon v-if="!isListMode" small class="mr-1">mdi-arrow-left</v-icon>
                  {{ isListMode ? buttonLabel : 'Back to list' }}
                </v-btn>
              </div>
              <v-divider />
            </template>
            <v-skeleton-loader :loading="loadingItems" type="article">
              <div
                class="tracker-wrapper-content__body"
                :class="{
                  'tracker-wrapper-content_body--list-mode': isListMode,
                  'tracker-wrapper-content_body--form-mode': !isListMode,
                }"
              >
                <TrackerWrapperList
                  class="tracker-wrapper-common-item"
                  :class="{ active: isListMode }"
                  :items="items"
                  :vote-loading-keys="voteLoadingKeys"
                  @vote="voteEventHandler"
                />
                <div v-if="!items.length" class="text-center mt-3">
                  <v-btn
                    :text="!isListMode"
                    :color="isListMode ? `primary` : ``"
                    @click.native="changeViewModeEventHandler"
                  >
                    {{ isListMode ? buttonLabel : 'Back to list' }}
                  </v-btn>
                </div>
              </div>
            </v-skeleton-loader>
            <TrackerWrapperForm
              class="tracker-wrapper-common-item"
              :class="{ active: !isListMode }"
              :save-loading="saveLoading"
              @change:view="changeViewModeEventHandler"
              @send="sendEventHandler"
            />
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';

import TrackerWrapperForm from './TrackerWrapperForm';
import TrackerWrapperList from './TrackerWrapperList';

const SettingsFactory = ServiceFactory.get('settings');

export default {
  name: 'TrackerWrapper',

  components: {
    TrackerWrapperList,
    TrackerWrapperForm,
  },

  data: () => ({
    viewMode: 'list',
    items: [],
    loadingItems: false,
    saveLoading: false,
    voteLoadingKeys: [],
  }),

  computed: {
    buttonLabel () {
      return 'New Request';
    },
    ...mapState({
      userId: (state) => state.userId,
      token: (state) => state.idToken,
      activeAccountId: (state) => state.activeAccount?.id
    }),
    member: function () {
      return `Member/${this.userId}`;
    },
    isListMode: function () {
      return this.viewMode === 'list';
    },
    formattedPage () {
      return this.$route.path.replace(`/${this.activeAccountId}`, '');
    }
  },

  created: function () {
    this.$eventBus.$on('open-bug-report', this.changeViewModeEventHandler);
    this.fetchList();
  },

  beforeDestroy () {
    this.$eventBus.$off('open-bug-report', this.changeViewModeEventHandler)
  },

  methods: {
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    /**
     * Handler for close event
     * */
    closeEventHandler: function () {
      this.$eventBus.$emit('closeFeedbackForm');
    },
    /**
     * Handler for change view mode event
     * */
    changeViewModeEventHandler: function () {
      this.viewMode = this.viewMode === 'list' ? 'form' : 'list';
    },
    /**
     * Fetch bug list from API by component
     * */
    fetchList: async function () {
      try {
        this.loadingItems = true;

        const params = [{ name: 'section', value: this.formattedPage }];
        const { data } = await SettingsFactory.getRequests({ token: this.token, params });

        this.items = [...data.result];
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.loadingItems = false;
      }
    },
    /**
     * Handler for send event
     * @param {Object} payload
     * */
    sendEventHandler: async function (payload) {
      const tempRequest = Object.assign(
        {
          type: this.type,
          page: this.formattedPage,
          member: this.member,
          session_url: this.$logRocket.sessionURL || '',
        },
        payload
      );
      try {
        this.saveLoading = true;

        const { data: item } = await SettingsFactory.sendFeedback({
          token: this.token,
          payload: tempRequest,
        });

        this.viewMode = 'list';

        this.addSuccessNotification('Thank you! <br>Your request has been successfully submitted and will be reviewed by our team shortly.');
        await this.voteEventHandler(item._key);
        await this.fetchList();
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.saveLoading = false;
      }
    },
    /**
     * Handler for vote event
     * */
    voteEventHandler: async function (id) {
      try {
        this.voteLoadingKeys.push(id);
        const { data } = await SettingsFactory.vote({ id, token: this.token });
        const tempRecord = data.record;

        this.items = this.items.map((item) => {
          if (item._key === tempRecord._key) {
            return tempRecord;
          }

          return item;
        });
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.voteLoadingKeys = this.voteLoadingKeys.filter((key) => key !== id);
      }
    },
  },
};
</script>
