import { IStoreFilterItem } from '@/bundles/Filters/interfaces/IStoreFilterItem';

export const FILTERS_KEYS = ['dashboard', 'occupier', 'company', 'availability', 'property',
  'comparable', 'deal', 'contact', 'requirement', 'project', 'pipeline', 'savedSet',
  'member', 'team', 'request', 'eblast', 'pressrelease', 'map', 'marketing',
  'eblastList', 'profileAdmin', 'marketingTemplates', 'marketingCampaign', 'customRows'
];

export interface IFilters {
  [key: string]: IStoreFilterItem[];
}

interface IFilterObjectShape {
  filters: IFilters;
  keyword: string;
  filterName: string;
  filterId: string;
  lastSelectedType: string;
  savedSetId: string;
}

export const FILTER_OBJECT_SHAPE: IFilterObjectShape = {
  filters: {},
  keyword: '',
  filterName: '',
  filterId: '',
  lastSelectedType: '',
  savedSetId: '', // todo: check if filterId can be used
};

export const getDefaultFilterObjectShape = () => {
  return Object.assign({}, FILTER_OBJECT_SHAPE);
}
