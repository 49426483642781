// TODO: move service to proper bundle structure when multi-account branch will be merged
import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/report';

export default {
  /**
   * Create report
   * @param {string} token
   * @param {any} payload
   * @return {Promise<any>}
   * */
  create: async function (token, payload) {
    try {
      return await instance.post(resource, payload, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * Get list of reports
   * @param {string} token
   * @param {any[]} params
   * @return {any}
   * */
  list: async function (token, params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(
        `${ resource }${ paramsString }`,
        {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * get the result of the report
   * @param {string} token
   * @param {string} key
   * @return {any}
   */
  getOne: async function (token, key) {
    try {
      return await instance.get(
        `${ resource }/${ key }`,
        {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * Get link to pdf
   * @param {string} token
   * @param {string} key
   * @returns {string}
   * */
  getPdfLink: async function (token, key) {
    try {
      return await instance.post(`${ resource }/${ key }`, {}, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
