import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { ISubscription } from '@/bundles/Subscriptions/interfaces/ISubsription';

export interface ISubscriptionsStore {
  subscriptions: ISubscription[];
}

const initialState: ISubscriptionsStore = {
  subscriptions: []
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
