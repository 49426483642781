import { defaultDashboardWidgets } from '@/bundles/Settings/helpers/defaultLayout';
import { OpexDisplayEnum } from '@/bundles/Members/enums';

export const getInitialState = () => ({
  settings_key: 0,
  permission: {
    documents: {
      share: false,
      share_per_document: false,
    },
    company: {
      share: false,
      share_notes: false,
    },
    contact: {
      share: false,
      share_notes: false,
    },
    contacts: {
      share: false,
      share_notes: false,
    },
    properties: {
      share: false,
      share_notes: false,
    },
    availabilities: {
      share: false,
      share_notes: false,
    },
    occupiers: {
      share: false,
      share_notes: false,
      share_schedule: false,
    },
    feedback: {
      display: true,
    },
  },
  rowsPerPage: 25,
  list: {
    sameForAll: false,
    perPage: 25,
    pages: {
      company: {
        perPage: 25,
      },
      contact: {
        perPage: 25,
      },
      occupier: {
        perPage: 25,
      },
      requirement: {
        perPage: 25,
      },
      property: {
        perPage: 25,
      },
      availability: {
        perPage: 25,
      },
      comparable: {
        perPage: 25,
      },
      project: {
        perPage: 25,
      },
      savedSet: {
        perPage: 25,
      },
      pipeline: {
        perPage: 25,
      },
      deal: {
        perPage: 25,
      },
      request: {
        perPage: 25,
      }
    },
  },
  device_tokens: [],
  layoutSettings: [
    {
      page: 'dashboard',
      widgets: defaultDashboardWidgets,
      isFetched: false,
    }
  ],
  userNotificationsSettings: {
    email: false,
  },
  opexDisplay: OpexDisplayEnum.opex,
});
