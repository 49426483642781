import cronTime from 'cron-time-generator';
import { TaskDaysEnum, TaskRecurrenceTypes, TaskRepeatEnum } from '@/bundles/Tasks/enums/TaskRepeatEnum';
import { ITaskCustomRecurrence } from '@/bundles/Tasks/interfaces/ITaskCustomRecurrence';

interface ITime {
  hours: number;
  minutes: number;
  day?: number;
}

const repeatOptionsDictionary = {
  [TaskRepeatEnum.daily]: ({ hours, minutes }) => cronTime.everyDayAt(hours, minutes),
  [TaskRepeatEnum.everyWeekOn]: ({ hours, minutes }) => cronTime.everyMondayAt(hours, minutes),
}

const dailyRecurrenceHandler = (recurrenceOptions: ITaskCustomRecurrence, { hours, minutes }: ITime): string => {
  return cronTime.every(recurrenceOptions.times).days(hours, minutes);
}

const weeklyRecurrenceHandler = (recurrenceOptions: ITaskCustomRecurrence, { hours, minutes }: ITime): string => {
  return cronTime.onSpecificDaysAt(recurrenceOptions.specificDays, hours, minutes);
}
const monthlyRecurrenceHandler = (recurrenceOptions: ITaskCustomRecurrence, { hours, minutes, day }: ITime): string => {
  if (day === undefined) {
    return '';
  }

  return `${hours} ${minutes} ${day} */${recurrenceOptions.times} *`;
}

export const customRepeatGenerator = (recurrenceOptions: ITaskCustomRecurrence, { hours, minutes, day }: ITime): string => {
  if (recurrenceOptions.type === TaskRecurrenceTypes.day) {
    return dailyRecurrenceHandler(recurrenceOptions, { hours, minutes });
  }

  if (recurrenceOptions.type === TaskRecurrenceTypes.week) {
    return weeklyRecurrenceHandler(recurrenceOptions, { hours, minutes });
  }

  return monthlyRecurrenceHandler(recurrenceOptions, { hours, minutes, day });
}

export const getCronExpression = ({ repeatOption, time, recurrenceOptions, day }: {
  repeatOption: TaskRepeatEnum | string;
  time: string;
  recurrenceOptions?: ITaskCustomRecurrence,
  day: number;
}) => {
  const [hours, minutes] = time.split(':');

  const defaultRepeats = [TaskRepeatEnum.daily, TaskRepeatEnum.everyWeekOn];

  if (defaultRepeats.some(repeat => repeat === repeatOption)) {
    return repeatOptionsDictionary[repeatOption]({
      hours: +hours,
      minutes: +minutes,
    });
  }

  if (!recurrenceOptions) {
    return '';
  }

  return customRepeatGenerator(recurrenceOptions, {
    hours: +hours,
    minutes: +minutes,
    day,
  });
}

export const generateRecurrenceCustomText = (recurrenceOptions, day: number): string => {
  if (recurrenceOptions.type === TaskRecurrenceTypes.day) {
    return `Every ${recurrenceOptions.times} days`;
  }

  if (recurrenceOptions.type === TaskRecurrenceTypes.week) {
    const daysStr = recurrenceOptions.specificDays.map(day => {
      return TaskDaysEnum[day].charAt(0).toUpperCase() + TaskDaysEnum[day].slice(1);
    }).join(', ');

    return `Every week at: ${daysStr}`;
  }

  return `Every ${recurrenceOptions.times} month on day ${day}`;
}
