import { v4 as uuid } from 'uuid';
import { NotificationMutations } from '@/store/types/mutation-types';
import { IAppNotification } from '@/utils/interfaces';

export default {
  [NotificationMutations.ADD_NOTIFICATION] (state: any, notification: IAppNotification) {
    if (!Object.keys(notification).length) return;

    if (typeof notification.status === 'number' && notification.status < 0) { // hide custom errors, for ex cancelled requests
      return;
    }

    let isSuccess = false;

    if (typeof notification.status === 'number') {
      isSuccess = (notification.status >= 200 && notification.status < 300) || notification.status === 0 || notification.status === 409;
    }

    if (!isSuccess && process.env.VUE_APP_SHOW_ERROR_NOTIFICATIONS !== 'true') {
      return;
    }

    const newNotification = {
      id: uuid(),
      status: notification.status,
      message: notification.message,
      title: notification.title,
      url: notification.url
    };

    state.notifications = {
      ...state.notifications,
      [newNotification.id]: newNotification
    };
  },
  [NotificationMutations.DELETE_NOTIFICATION] (state: any, id: string) {
    delete state.notifications[id];
    state.notifications = {
      ...state.notifications
    };
  },
};
