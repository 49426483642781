import instance from '../instance'
import { generateParams } from '@/utils/helpers'
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper'

const errorMapper = new ErrorMapper()
const resource = '/dataLists'
const templates = '/templates'

export default {
  list: async function ({ params = [], token }) {
    try {
      const paramsString = generateParams(params)
      return await instance.get(`${resource}/list${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, category }) {
    try {
      return await instance.get(`${resource}/${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  templatesList: async function ({ token, params = [] }) {
    try {
      const paramsString = generateParams(params)
      return await instance.get(`${templates}/list${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createTemplate: async function ({ token, payload }) {
    try {
      return await instance.post(`${templates}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateTemplate: async function ({ token, payload, id }) {
    try {
      return await instance.put(`${templates}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
}
