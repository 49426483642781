import { eventBus } from '@/eventbus/eventbus';
import { SET_PREVIOUS_URL } from '@/store/action-types';
import { intersection } from 'lodash';
import { NavigationGuardNext, Route } from 'vue-router';
import { parseJwt } from '@/bundles/App/helpers/parseJWT';

const handlingAccountIdRoutes = (to, store) => {
  if (isNaN(to.params.accountId)) {
    const accountId = store?.state?.activeAccount?.id;

    return `/${accountId}${to.fullPath}`;
  }

  return '';
};

export const redirectHandler = (to, next, store, isRedirectTo404 = false) => {
  const route = handlingAccountIdRoutes(to, store);

  if (route) {
    next(route);
    return;
  }

  isRedirectTo404 ? next({ name: '404' }) : next();
};

export const requiresTokenAbsenceGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  if (store?.state?.idToken) {
    next({ name: 'dashboard', params: { accountId: store?.state?.user?.active_account?.id } });
    return false;
  }

  return true;
};

export const requiresTokenOrTemporaryTokenGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  if (!store?.state?.idToken && !store?.state?.temporaryAuthToken) {
    next('/signin');
    return false;
  }

  return true;
};

export const requiresTokenGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  if (!store?.state?.idToken) {
    const url = window.location.href.replace(
      `${window.location.protocol}//${window.location.host}/#`,
      ''
    );

    store.commit(SET_PREVIOUS_URL, url || '/dashboard');

    if (from.name && from.name !== 'signin') {
      eventBus.$emit('showSignIn', { preventRedirect: true })
    } else {
      next('/signin')
    }

    return false;
  }

  store.commit(SET_PREVIOUS_URL, null);
  return true;
};

export const requiresRedirectGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  redirectHandler(to, next, store);
  return false;
};

export const requiresAdminTokenGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  if (!store.state.adminToken || new Date().getTime() > store.state.adminTokenExpiresTime || to.query.showLogin) {
    next(`${to.path}?showLogin=true`);
    return false;
  }

  return true;
};

export const requiresSuperAdminRoleGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  const roles = ['super admin'];
  const userRoles = parseJwt(store?.state?.idToken)?.scope || [];

  if (intersection(roles, userRoles).length) {
    return true;
  }

  next('/access');
  return false;
};

export const requiresFinancialAdminRoleGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  const roles = ['super admin', 'financial admin'];
  const userRoles = parseJwt(store?.state?.idToken)?.scope || [];

  if (intersection(roles, userRoles).length) {
    return true;
  }

  next('/access');
  return false;
};

export const requiresFinancialAdminOrAdminRoleGuard = (to: Route, from: Route, next: NavigationGuardNext, store: any) => {
  const roles = ['super admin', 'financial admin', 'admin'];
  const userRoles = parseJwt(store?.state?.idToken)?.scope || [];

  if (intersection(roles, userRoles).length) {
    return true;
  }

  next('/access');
  return false;
};
