






























































import Vue, { PropType } from 'vue';
import { Nullable } from '@/utils/types';
import { IUploadFile, IUploadOptions } from '@/bundles/Upload/interfaces';
import { CropperFuncNamesEnum } from '@/bundles/Upload/helpers';

import UploadSidebar from '@/bundles/Upload/components/UploadSidebar.vue';
import DragAndDropArea from '@/bundles/Upload/components/DragAndDropArea.vue';
import UploadCropper from '@/bundles/Upload/components/UploadCropper.vue';

interface IUpload {
  uploadedFiles: File[];
  imageSrcs: string[];
  fileReader: Nullable<FileReader>;
  activeFunc: Nullable<CropperFuncNamesEnum>;
  isImg: boolean;
}

export default Vue.extend({
  name: 'Upload',

  components: {
    UploadCropper,
    DragAndDropArea,
    UploadSidebar
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    options: {
      type: Object as PropType<IUploadOptions>,
      default: () => ({ accept: 'image/*' })
    },
    sourceType: {
      type: String,
      default: null
    },
    sourceId: {
      type: [String, Number],
      default: null
    },
    skipUpload: Boolean,
    imageSrcToEdit: {
      type: String,
      default: null
    },
    isMultiplySizesUpload: Boolean,
    attachmentType: {
      type: String,
      default: null,
    }
  },

  data: (): IUpload => ({
    uploadedFiles: [],
    imageSrcs: [],
    fileReader: null,
    activeFunc: null,
    isImg: false,
  }),

  computed: {
    showDialog: {
      get (): boolean {
        return this.value;
      },
      set (value: boolean) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    showDialog: {
      handler (value: boolean) {
        if (!value) {
          this.resetData();
          return;
        }

        if (this.imageSrcToEdit) {
          this.isImg = true;
          this.imageSrcs = [this.imageSrcToEdit];
          this.turnFuncHandler(CropperFuncNamesEnum.crop);
        }
      },
      immediate: true
    },
  },

  methods: {
    resetData () {
      this.imageSrcs = [];
      this.uploadedFiles = [];
      this.activeFunc = null;
    },
    removeFile (index: number) {
      if (this.uploadedFiles.length === 1) {
        this.resetData();
        return;
      }

      this.imageSrcs.splice(index, 1);
      this.uploadedFiles.splice(index, 1);
    },
    onUploadHandler ({ urls, files }: { urls: string[]; files: File[] }) {
      if (this.skipUpload) {
        this.$emit('fileUploaded', { originalFiles: files });
        this.showDialog = false;
        return;
      }

      this.isImg = files.length > 1 ? false : new RegExp('image/*', 'i').test(files[0].type);
      this.imageSrcs = urls;
      this.uploadedFiles = files;

      if (this.isImg) {
        this.turnFuncHandler(CropperFuncNamesEnum.crop);
      }
    },
    turnFuncHandler (name: CropperFuncNamesEnum) {
      this.activeFunc = this.activeFunc === name ? null : name;
    },
    fileUploadedHandler ({ files, originalFiles }: { files: IUploadFile[], originalFiles: File[] }) {
      this.$emit('fileUploaded', { files, originalFiles });
      this.showDialog = false;
    },
  }
});
