import { IPermissions } from '@/bundles/App/interfaces/IPermissions';
import { PrivacyType } from '@/bundles/App/types';
import { ITextValue } from '@/types/common';

export * from './accountRoute';

export const getDefaultPermissions = (): IPermissions => ({
  team_ids: [],
  user_ids: [],
  privacy: PrivacyType.MY_OFFICE,
});

export const generateArrayOfItemsFromObject = (obj: Record<string, any>): Array<ITextValue<any>> => {
  return Object.entries(obj).reduce((acc, [value, text]) => {
    acc.push({ value, text });
    return acc;
  }, [] as Array<ITextValue<any>>)
}

export const portfolioSources = ['availabilities', 'properties', 'comparables'];

export function capitalizeWords (str: string): string {
  if (!str) {
    return '';
  }

  const words = str.split(' ');

  const camelWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  return camelWords.join(' ');
}
