import Vue from 'vue';

export default Vue.extend({
  computed: {
    isAdmin: function (): boolean {
      // @ts-ignore
      return (this.$store as any).state.userRoles.includes('admin') || this.isSuperAdmin;
    },
    isSuperAdmin: function (): boolean {
      // @ts-ignore
      return (this.$store as any).state.userRoles.includes('super admin');
    },
    isMarketingAdmin: function (): boolean {
      // @ts-ignore
      return (this.$store as any).state.userRoles.includes('marketing admin') || this.isSuperAdmin;
    },
    isFinancialAdmin: function (): boolean {
      // @ts-ignore
      return (this.$store as any).state.userRoles.includes('financial admin');
    },
    isSystemAdmin: function (): boolean {
      // @ts-ignore
      return (this.$store as any).state.userRoles.includes('system admin');
    }
  },
});
