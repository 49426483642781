
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { AvailabilityVerification, VerificationStatus } from '@/bundles/Availabilities/models/AvailabilityVerification';
import { formatJSDateTime } from '@/utils/filters';
import AppIcon from '@/bundles/App/components/functional/AppIcon.vue';

export default Vue.extend({
  name: 'AvailabilityVerificationLabel',

  functional: true,

  props: {
    verification: {
      type: Object as PropType<AvailabilityVerification>,
      required: true
    }
  },

  render (h: CreateElement, ctx: RenderContext<{ verification: AvailabilityVerification }>): VNode | VNode[] {
    const verification = ctx.props.verification;

    let statusText = verification.status === VerificationStatus.pending ? 'Sent' : '';
    let statusIcon = verification.status === VerificationStatus.approved ? 'mdi-check-circle-outline' : 'mdi-cancel';

    const children: VNode[] = [];

    if (statusText) {
      children.push(h('span', { class: 'mr-2' }, statusText));
    }
    children.push(h('span', { class: 'mr-2' }, formatJSDateTime(verification.updated.date)));
    if (verification.status !== VerificationStatus.pending) {
      children.push(h('span', { class: 'mr-2' }, verification.updated.name));
      children.push(h(AppIcon, {
        props: {
          color: verification.status === VerificationStatus.approved ? 'success' : 'error',
          small: true,
        }
      }, statusIcon));
    }

    return h('div', children);
  }
});
