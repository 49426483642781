import { IHeader } from '@/bundles/BaseTable/interfaces';
import { getMarketLabel } from '@/bundles/Properties/helpers';
import i18n from '@/bundles/Localization';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';

export const getMarketHeaders = accountId => {
  const result = [
    {
      text: i18n.t(LocalizationEnum.comparable_primary_use),
      value: 'primary_use',
      sortable: true,
      icon: 'string'
    },
    {
      text: getMarketLabel(accountId),
      value: 'filterMarketOne',
      sortable: true,
      icon: 'string'
    }
  ];

  if (accountId !== 6) {
    result.push({
      text: 'Submarket',
      value: 'filterMarketTwo',
      sortable: true,
      icon: 'string'
    });
  }

  return result;
};

const getComparablesHeadersDataBase = (accountId: number): IHeader[] => {
  return [
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Property Name',
          value: 'name',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Address',
          value: 'address.street',
          sortable: true,
          icon: 'string'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Trans Type',
          value: 'type',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Property Type',
          value: 'property_type',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Units/Keys',
          value: 'property_units',
          sortable: true,
          icon: 'string'
        },
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: getMarketHeaders(accountId)
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Comp Size',
          value: 'square_feet',
          sortable: true,
          icon: 'number'
        },
        {
          text: 'Building Size',
          value: 'building_size',
          sortable: true,
          icon: 'number'
        },
        {
          text: 'Land Size',
          value: 'land_size',
          sortable: true,
          icon: 'number'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'sale price per sf',
          value: 'sale_price,per_sf',
          sortable: false,
          icon: 'string',
          multi: [
            {
              text: `Sale Price`,
              value: 'sale_price_total',
              sortable: true,
              icon: 'number'
            },
            {
              text: '(Per SF)',
              value: 'sale_price_sf',
              sortable: true,
              icon: 'number'
            },
          ]
        },
        {
          text: 'Cap Rate',
          value: 'transaction.cap_rate',
          sortable: true,
          icon: 'number'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Lease Rate Starting (% Ann Esc)',
          value: 'starting_rent_sf',
          sortable: true,
          icon: 'number'
        },
        {
          text: 'Lease Rate Effective',
          value: 'effective_rent_sf',
          sortable: false,
          icon: 'number'
        },
        {
          text: 'Lease Comm (Term)',
          value: 'lease_commencement',
          sortable: true,
          icon: 'date'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'TI Landlord / SF',
          value: 'ti_landlord',
          sortable: true,
          icon: 'number'
        },
        {
          text: 'TI Tenant / SF',
          value: 'ti_tenant',
          sortable: true,
          icon: 'number'
        },
        {
          text: 'Transaction Date',
          value: 'transaction_date',
          sortable: false,
          icon: 'string',
          multi: [
            {
              text: `Trans Date`,
              value: 'transaction_date',
              sortable: true,
              icon: 'number'
            },
            {
              text: '(MOM)',
              value: 'transaction_mom',
              sortable: true,
              icon: 'number'
            },
          ]
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Buyer/Tenant Company',
          value: 'buyer_tenant_company',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Buyer/Tenant Contact',
          value: 'buyer_tenant_contact',
          sortable: true,
          icon: 'string'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Seller/Landlord Company',
          value: 'seller_landlord_company',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Seller/Landlord Contact',
          value: 'seller_landlord_contact',
          sortable: true,
          icon: 'string'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Seller/Landlord Agent',
          value: 'seller_landlord_agent',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Buyer/Tenant Agent',
          value: 'buyer_tenant_agent',
          sortable: true,
          icon: 'string'
        }
      ]
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Updated',
          value: 'updated.date',
          sortable: true,
          icon: 'date'
        },
        {
          text: 'Created',
          value: 'created.date',
          sortable: true,
          icon: 'date'
        },
        {
          text: 'Source',
          value: '',
          sortable: false,
          icon: ''
        }
      ]
    }
  ];
};

export const getComparablesHeadersData = (accountId: number): IHeader[] => {
  return [
    {
      text: '',
      sortable: false,
      width: '1px',
      value: 'data-table-select'
    },
    {
      text: '',
      value: 'image',
      sortable: false
    },
    ...getComparablesHeadersDataBase(accountId)
  ];
};

export const getComparablesCardHeadersData = (accountId: number): IHeader[] => {
  return [
    { text: '#', value: 'number', sortable: false },
    ...getComparablesHeadersDataBase(accountId)
  ];
};
