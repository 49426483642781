












































































































































































































































































import { PropType } from 'vue';
import { TaskCreateUpdateDTO } from '@/bundles/Tasks/dto/TaskCreateUpdateDTO';
import { ITaskCustomRecurrence } from '@/bundles/Tasks/interfaces/ITaskCustomRecurrence';
import { TaskRepeatEnum, TaskRepeatLabels } from '@/bundles/Tasks/enums/TaskRepeatEnum';
import { ITextValue, VForm } from '@/types/common';
import { generateArrayOfItemsFromObject } from '@/bundles/App/helpers';
import { Nullable } from '@/utils/types';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { mapMutations, mapState } from 'vuex';
import { TaskTypesEnum, TaskTypesLabels } from '@/bundles/Tasks/enums/TaskTypesEnum';
import { DateTime } from 'luxon';
import { NotificationMutations } from '@/store/types/mutation-types';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { getTypeLogo } from '@/bundles/Tasks/helpers/helpers';
import { generateRecurrenceCustomText, getCronExpression } from '@/bundles/Tasks/helpers/cronExpression';
import mixins from 'vue-typed-mixins';
import { VueEditorMixin } from '@/bundles/App/mixins/editor/VueEditorMixin';

import TaskCustomRecurrenceForm from '@/bundles/Tasks/components/TaskCustomRecurrenceForm.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import AssociatedForm from '@/bundles/Tasks/components/associated/AssociatedForm.vue';
import CommonDatePicker from '@/components/common-components/pickers/CommonDatePicker.vue';
import AssociatedItem from '@/bundles/Tasks/components/associated/AssociatedItem.vue';
import CollaboratorsSelect from '@/bundles/Tasks/components/CollaboratorsSelect.vue';
import QuillEditor from '@/bundles/Account/components/QuillEditor.vue';

interface ITaskForm {
  task: TaskCreateUpdateDTO;
  rules: {
    required: (v: any) => boolean|string,
    assignedTo: (v: any) => boolean|string,
  };
  assignedToMe: boolean;
  members: any[]; // todo: add type
  membersLoading: boolean;
  associatedDialog: boolean;
  customRecurrenceDialog: boolean;
  recurrenceOptions: ITaskCustomRecurrence | undefined;
  repeatOption: TaskRepeatEnum | string;
  repeatOptions: Array<ITextValue<string>>;
  relatedRecords: any[];
}

export default mixins(VueEditorMixin).extend({
  name: 'BaseTaskForm',

  components: {
    QuillEditor,
    CollaboratorsSelect,
    AssociatedItem,
    CommonDatePicker,
    AssociatedForm,
    CommonChip,
    TaskCustomRecurrenceForm
  },

  props: {
    taskEntry: {
      type: Object as PropType<Nullable<ITask>>,
      default: null
    },
    collectionType: {
      type: String,
      default: null
    },
    associatedRecords: {
      type: Array,
      default: () => []
    },
    saveLoading: Boolean,
    title: {
      type: String,
      default: 'Create Task'
    },
  },

  data: (): ITaskForm => ({
    task: new TaskCreateUpdateDTO(),
    rules: {
      required: (value) => !!value || 'This field is required',
      assignedTo: (value) => !!value || 'You must assign this task',
    },
    assignedToMe: true,
    members: [],
    membersLoading: false,
    associatedDialog: false,
    customRecurrenceDialog: false,
    recurrenceOptions: undefined,
    repeatOption: TaskRepeatEnum.no_repeat,
    repeatOptions: generateArrayOfItemsFromObject(TaskRepeatLabels),
    relatedRecords: []
  }),

  computed: {
    ...mapState({
      token: (state: any) => state.idToken,
      userId: (state: any) => state.userId
    }),
    taskTypesEnum (): typeof TaskTypesEnum {
      return TaskTypesEnum;
    },
    taskTypesLabels (): typeof TaskTypesLabels {
      return TaskTypesLabels;
    },
    times (): ITextValue<string>[] {
      const hours: ITextValue<string>[] = [];

      for (let hour = 0; hour < 24; hour++) {
        hours.push(this.getTimeItem({ hour }));
        hours.push(this.getTimeItem({ hour, minute: 15 }));
        hours.push(this.getTimeItem({ hour, minute: 30 }));
        hours.push(this.getTimeItem({ hour, minute: 45 }));
      }

      return hours;
    },
    scheduleParsed: {
      get (): Nullable<any> {
        if (this.repeatOption === TaskRepeatEnum.no_repeat) {
          return null;
        }

        const defaultRepeats = [TaskRepeatEnum.everyWeekOn, TaskRepeatEnum.daily];

        if (defaultRepeats.some(repeat => repeat === this.repeatOption)) {
          return { repeatOption: this.repeatOption };
        }

        return {
          repeatOption: this.repeatOption,
          ...this.recurrenceOptions
        };
      },
      set (value: Nullable<any>) {
        if (!value) {
          return;
        }

        const defaultRepeats = [TaskRepeatEnum.everyWeekOn, TaskRepeatEnum.daily];

        if (!defaultRepeats.some(repeat => repeat === value.repeatOption)) {
          const { repeatOption, ...recurrenceOptions } = value;

          this.repeatOptions.push({ text: repeatOption.replaceAll('_', ' '), value: repeatOption });
          this.recurrenceOptions = { ...recurrenceOptions };
        }

        this.repeatOption = value.repeatOption;
      }
    }
  },

  watch: {
    assignedToMe: {
      handler (value: boolean) {
        if (value) {
          this.task.assignee = +this.userId;
        }
      },
      immediate: true,
    },
    associatedRecords: {
      handler (value: any[]) {
        this.relatedRecords = value.map((record: any) => ({
          ...record,
          type: this.collectionType || record.type
        }));
      },
      immediate: true,
    },
    repeatOption: {
      handler (value) {
        this.repeatOptionChangeHandler(value);
        this.customRecurrenceDialog = value === TaskRepeatEnum.custom;
      },
      immediate: true,
    },
    'task.due_time' () {
      this.repeatOptionChangeHandler(this.repeatOption);
    },
    'task.due_date' () {
      this.updateSchedule();
    },
  },

  created () {
    this.fetchMembers();

    if (this.taskEntry) {
      this.editTaskHandler(this.taskEntry);
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    assignToMe () {
      this.task.assignee = +this.userId;
      this.assignedToMe = true;
    },
    async fetchMembers () {
      try {
        this.membersLoading = true;

        const params = [
          { name: 'status', value: 'active' },
          { name: 'take', value: 0, } // zero used to return all members
        ];
        const { data: members } = await memberService.list(params);
        this.members = members.map((member) => ({
          full_name: `${member.profile.first_name} ${member.profile.last_name}`,
          ...member,
        }));
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.membersLoading = false;
      }
    },
    addAssociatedHandler (items: any[]) {
      items.forEach(item => {
        const index = this.relatedRecords.findIndex(record => record._key === item._key);

        if (index === -1) {
          this.relatedRecords.push(item);
        } else {
          this.relatedRecords[index] = item;
        }
      });
    },
    remove (id: string) {
      this.relatedRecords = this.relatedRecords.filter((item: any) => {
        return item._id !== id;
      });
    },
    generateAssociatedLogo (item): string {
      return getTypeLogo(item.type);
    },
    getCronExpression (): string {
      const day = DateTime.fromJSDate(new Date(this.task.due_date as string)).day;

      return getCronExpression({
        repeatOption: this.repeatOption,
        time: this.task.due_time as string,
        recurrenceOptions: this.recurrenceOptions,
        day,
      });
    },
    updateIsRecurrent (value: string) {
      this.task.is_recurrent = value !== TaskRepeatEnum.no_repeat;
    },
    updateSchedule () {
      if (this.task.is_recurrent) {
        this.task = Object.assign(this.task, {
          schedule: this.getCronExpression(),
          schedule_parsed: this.scheduleParsed,
        });
      } else {
        delete this.task.schedule;
        delete this.task.schedule_parsed;
      }
    },
    repeatOptionChangeHandler (value: string) {
      this.updateIsRecurrent(value);
      this.updateSchedule();
    },
    closeCustomRecurrenceHandler () {
      this.customRecurrenceDialog = false;
      this.repeatOption = TaskRepeatEnum.no_repeat;
    },
    saveCustomRecurrenceHandler (recurrenceOptions: ITaskCustomRecurrence) {
      this.recurrenceOptions = { ...recurrenceOptions };

      const day = DateTime.fromJSDate(new Date(this.task.due_date as string)).day;
      const recurrenceCustomText = generateRecurrenceCustomText(recurrenceOptions, day);
      const recurrenceCustomValue = recurrenceCustomText.replaceAll(' ', '_');

      this.repeatOptions = generateArrayOfItemsFromObject(TaskRepeatLabels);
      this.repeatOptions.push({ text: recurrenceCustomText, value: recurrenceCustomValue });
      this.repeatOption = recurrenceCustomValue;
      this.updateSchedule();
    },
    submitHandler () {
      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      const relatedRecords = this.relatedRecords.map((item: any) => ({
        _id: item._id,
        type: item.type,
      }));

      this.$emit('submit', new TaskCreateUpdateDTO({ ...this.task, related_records: relatedRecords }));
    },
    editTaskHandler (value: ITask) {
      this.task = new TaskCreateUpdateDTO({ ...value, related_records: [] });

      this.assignedToMe = value.assignee === +this.userId;
      this.scheduleParsed = this.task.schedule_parsed;
      this.relatedRecords = value.collections;
    },
    getTimeItem ({ hour, minute }: { hour: number, minute?: number }): ITextValue<string> {
      return {
        text: DateTime.fromObject({ hour, minute }).toLocaleString(DateTime.TIME_SIMPLE),
        value: DateTime.fromObject({ hour, minute }).toLocaleString(DateTime.TIME_24_SIMPLE)
      };
    }
  }
});
