import instance from '../instance'
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper'

const errorMapper = new ErrorMapper()
const resource = '/api'

export default {
  getTemplate: async function ({ id }) {
    try {
      return await instance.get(`${resource}/templates/${id}`)
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveFiles: async function ({ payload }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
}
