import { Nullable } from '@/utils/types';
import { formatCurrency } from '@/utils/filters';
import { IPropertyExpense } from '@/bundles/Property/interfaces/IPropertyExpense';

export const getPropertyExpenses = (expenses: Nullable<IPropertyExpense>): Nullable<string> => {
  if (!expenses) {
    return null;
  }

  const yearWithStatus = expenses.year || expenses.status ? `(${expenses.year} ${expenses.status})`.trim() : '';

  return `${formatCurrency(expenses.total_expenses)} ${yearWithStatus}`;
};
