
import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { formatCurrency } from '@/utils/filters';

import Label from '@/bundles/Common/components/Label.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'ComparableSalesPriceLabel',

  functional: true,

  props: {
    propertyType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'Sales Price'
    },
    totalAcres: {
      type: Number,
      default: null
    },
    totalPrice: {
      type: Number,
      default: null
    },
    totalPricePer: {
      type: Number,
      default: null
    },
    isUndisclosedIfNull: {
      type: Boolean,
      default: true
    },
    hideLabel: Boolean
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const { label, totalAcres, totalPrice, totalPricePer, propertyType, isUndisclosedIfNull, hideLabel } = ctx.props;
    const children: VNode[] = [];

    if (!hideLabel) {
      children.push(h('strong', `${label}: `));
    }

    const isTotalPricePresent = isValuePresent(totalPrice);
    const isTotalPricePerPresent = isValuePresent(totalPricePer);

    if (!isTotalPricePresent && !isTotalPricePerPresent && isUndisclosedIfNull) {
      children.push(h(CommonChip, [h('span', 'Undisclosed Price')]));
      return h('div', ctx.data, children);
    }

    if (!isTotalPricePresent && !isTotalPricePerPresent && !isUndisclosedIfNull) {
      children.push(h('span', { class: 'na' }, 'N/A'));
      return h('div', ctx.data, children);
    }

    children.push(h(Label, {
      props: { value: formatCurrency(totalPrice) }
    }));

    const salesPricePSF = propertyType.toLowerCase() === 'land'
      ? totalPrice / totalAcres
      : totalPricePer;

    children.push(h('span', [
      h('span', ' ('),
      h(Label, {
        props: { value: formatCurrency(salesPricePSF) }
      }),
      h('span', ' PSF)')
    ]));

    return h('div', ctx.data, children);
  }
});
