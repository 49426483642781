import { Nullable } from '@/utils/types';
import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';
import { IRequestNotification } from '@/bundles/Panels/interfaces';
import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';

export default class Request extends BaseArangoModel implements IRequestNotification {
  title: string;
  created: ICreatedUpdatedBy;
  name: string;
  description?: string;
  page: number;
  screenshot: string;
  type: string;
  status: string;
  updated: ICreatedUpdatedBy;
  closed?: string;
  member?: any; // TODO: Add type
  key: string;
  color: string;
  icon: string;
  pressRelease?: any; // TODO: Add type
  photo_url?: Nullable<string>;
  body?: string;
  message?: Nullable<string>;

  constructor (payload: IRequestNotification) {
    super(payload);
    this.title = payload.name;
    this.created = payload.created;
    this.name = payload.name;
    this.description = payload.description;
    this.page = payload.page || 0;
    this.screenshot = payload.screenshot || '';
    this.type = payload.type;
    this.status = payload.status;
    this.updated = payload.updated;
    this.closed = payload.closed;
    this.member = payload.member;
    this.key = payload.key;
    this.color = payload.color;
    this.icon = payload.icon;
    this.pressRelease = payload.pressRelease;
    this.photo_url = payload.photo_url;
    this.body = payload.body;
    this.message = payload.message;
  }
}
