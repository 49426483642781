import io, { Socket } from 'socket.io-client';
import { Nullable } from '@/utils/types';

let socketInstance: Nullable<Socket> = null;

export const initSocket = (token: Nullable<string>) => {
  if (socketInstance) {
    socketInstance.disconnect();
  }

  if (!token) {
    return;
  }

  socketInstance = io(process.env.VUE_APP_NOTIFIER_SOCKET_URL as string, {
    auth: { token },
    transports: ['websocket']
  });
}

export { socketInstance };
