export enum UserWidgetsEnum {
  critical_dates = 'critical_dates',
  tasks = 'tasks',
  open_balances = 'open_balances',
  broker_ranking = 'broker_ranking',
  new_availabilities = 'new_availabilities',
  recent_comparables = 'recent_comparables',
  open_invoices = 'open_invoices'
}

export const UserWidgetsLabels = {
  [UserWidgetsEnum.critical_dates]: 'Critical Dates',
  [UserWidgetsEnum.tasks]: 'Tasks',
  [UserWidgetsEnum.open_balances]: 'Open Balances',
  [UserWidgetsEnum.broker_ranking]: 'Broker Ranking',
  [UserWidgetsEnum.new_availabilities]: 'New Availabilities',
  [UserWidgetsEnum.recent_comparables]: 'Recent Comps',
  [UserWidgetsEnum.open_invoices]: 'Open Invoices',
}
