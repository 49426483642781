import { TaskTypesEnum } from '@/bundles/Tasks/enums/TaskTypesEnum';
import { ITaskRelatedRecord } from '@/bundles/Tasks/interfaces/ITask';
import { Nullable } from '@/utils/types';
import { ITaskCreateUpdate } from '@/bundles/Tasks/interfaces/ITaskCreateUpdate';

export class TaskCreateUpdateDTO {
  title: string;
  type: TaskTypesEnum;
  assignee: number;
  due_date: string; // date string
  due_time: string; // (hh:mm)
  description: string;
  is_recurrent: boolean;
  marketing: boolean;
  schedule?: string; // cron expression
  schedule_parsed?: any; // add interface
  related_records: ITaskRelatedRecord[];
  collaborators: number[];

  constructor (entity: Nullable<ITaskCreateUpdate> = null) {
    this.title = entity?.title || '';
    this.type = entity?.type || TaskTypesEnum.call;
    this.assignee = entity?.assignee || 0;
    this.due_date = entity?.due_date || new Date().toISOString().substr(0, 10);
    this.due_time = entity?.due_time || '09:00';
    this.description = entity?.description || '';
    this.is_recurrent = entity?.is_recurrent || false;
    this.marketing = entity?.marketing || false;

    if (entity?.schedule) {
      this.schedule = entity.schedule;
      this.schedule_parsed = entity.schedule_parsed;
    }

    this.related_records = entity?.related_records || [];
    this.collaborators = entity?.collaborators || [];
  }
}
