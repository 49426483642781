import Vue from 'vue';
import { mapState } from 'vuex';
import { getAccountRouteHandler, IGetAccountRouteHandler } from '@/bundles/App/helpers/accountRoute';

const AccountLayoutMixin = Vue.extend({
  computed: {
    ...mapState({
      activeAccountId: (state: any) => state.activeAccount?.id
    })
  },

  methods: {
    getAccountRoute ({ name, params = {}, query = {} }: {
      name: string;
      params?: Record<string, any>;
      query?: Record<string, any>;
    }): IGetAccountRouteHandler {
      return getAccountRouteHandler({
        name,
        params,
        query,
        activeAccountId: this.activeAccountId
      });
    }
  }
});

export { AccountLayoutMixin };
