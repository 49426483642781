import Vue from 'vue';
import { StoreModulesNamesEnum } from '@/store/StoreModulesNamesEnum';
import { mapMutations, mapState } from 'vuex';
import { IMapStyle } from '@/bundles/Map/interfaces';
import { defaultMapStyle } from '@/bundles/Map/helpers/defaultMapStyle';
import { RootMutations } from '@/store/types/mutation-types';

export const MapStylesMixin = Vue.extend({
  name: 'MapStylesMixin',

  computed: {
    ...mapState({
      defaultStyle: (state: any) => state.spatial.style,
    }),
    ...mapState({
      customMapStylesAll: (state: any) => (state.accountSettings?.map?.styles || []) as IMapStyle[],
      selectedStyle: (state: any) => state.accountSettings?.map?.defaultMapStyle,
    }),
    ...mapState(StoreModulesNamesEnum.Spatial, {
      defaultMapStyles: (state: any) => state.defaultMapStyles as IMapStyle[]
    }),
    customMapStyles (): IMapStyle[] {
      return this.customMapStylesAll.filter(item => item.active === true);
    }
  },

  methods: {
    ...mapMutations({
      setMapStyle: RootMutations.SET_MAP_STYLE,
      setMapCardsStyle: RootMutations.SET_MAP_CARDS_STYLE,
      setMapApproveStyle: RootMutations.SET_MAP_APPROVE_STYLE,
    }),
    getMapStyleUrlById (id: string) {
      return `mapbox://styles/${this.getStyleUrl(id)}`;
    },
    getStyleUrl (id: string) {
      const customFound = this.customMapStyles.find(item => item.value === id);

      if (customFound) {
        return customFound.url;
      }

      let style = this.defaultMapStyles.find(item => item.value === id);

      if (!style) {
        const defaultStyleFound = this.defaultMapStyles.find(item => item.value === this.selectedStyle);
        style = defaultStyleFound || defaultMapStyle;

        this.setDefaultMapStyles(style.value);
      }

      return style.url;
    },
    getMapStyleById (id: string) {
      const customFound = this.customMapStyles.find(item => item.value === id);

      if (customFound) {
        return {
          style: customFound,
          type: 'custom'
        }
      }

      return {
        style: this.defaultMapStyles.find(item => item.value === id) || defaultMapStyle,
        type: 'default'
      }
    },
    setDefaultMapStyles (value: string) {
      this.setMapStyle(value);
      this.setMapCardsStyle(value);
      this.setMapApproveStyle(value);
    }
  }
});
