import * as constants from './admin-types'

export default {
  [constants.SET_LISTS] (state, lists) {
    lists.forEach((list) => {
      state[list['category']] = {
        _key: list['_key'],
        category: list['category'],
        items: list['items'],
      }
    })
  },
  [constants.UPDATE_LIST] (state, { category, payload }) {
    state[category] = Object.assign({}, state[category], {
      items: payload['items'],
    })
  },
}
