import { ITextValue } from '@/types/common';

export enum PrivacyType {
  ME = 'me',
  MY_TEAM = 'myteam',
  MY_OFFICE = 'myoffice',
  TEAM_OR_USER = 'team_or_user',
}

export enum UserRole {
  super_admin = 'super admin',
  financial_admin = 'financial admin',
}

export const getTextValuePrivacyTypes = (): ITextValue<PrivacyType>[] => {
  return Object.values(PrivacyType).map((item) => {
    let text = '';

    switch (item) {
      case PrivacyType.MY_TEAM:
        text = 'All My Teams';
        break;
      case PrivacyType.MY_OFFICE:
        text = 'My Office';
        break;
      case PrivacyType.ME:
        text = 'Private to Me';
        break;
      case PrivacyType.TEAM_OR_USER:
        text = 'Private to Team/User';
        break;
      default:
        text = 'Public';
    }

    return { text, value: item };
  });
};
