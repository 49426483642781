import { ITextValue } from '@/types/common';

export enum CustomLayerTypesEnum {
  'packages/parcel'= 'packages/parcel',
  industrial = 'industrial',
  office = 'office',
  retail = 'retail',
  'multi-family' = 'multi-family',
  'farm and ranch' = 'farm and ranch',
  residential = 'residential',
  transportation = 'transportation',
}

export const customLayerTypesLabels = {
  [CustomLayerTypesEnum['packages/parcel']]: 'Packages/Parcel',
  [CustomLayerTypesEnum.industrial]: 'Industrial',
  [CustomLayerTypesEnum.office]: 'Office',
  [CustomLayerTypesEnum.retail]: 'Retail',
  [CustomLayerTypesEnum['multi-family']]: 'Multi-Family',
  [CustomLayerTypesEnum['farm and ranch']]: 'Farm and Ranch',
  [CustomLayerTypesEnum.residential]: 'Residential',
  [CustomLayerTypesEnum.transportation]: 'Transportation',
}

export const formTypes = [
  CustomLayerTypesEnum.industrial,
  CustomLayerTypesEnum.office,
  CustomLayerTypesEnum.retail,
  CustomLayerTypesEnum['multi-family'],
  CustomLayerTypesEnum['farm and ranch']
];

export const importLayerTypes = [
  CustomLayerTypesEnum.industrial,
  CustomLayerTypesEnum.office,
  CustomLayerTypesEnum.retail,
  CustomLayerTypesEnum['multi-family'],
  CustomLayerTypesEnum['farm and ranch'],
  CustomLayerTypesEnum.residential,
]

export const getCustomLayerFormTypes = (types: CustomLayerTypesEnum[]): ITextValue<string>[] => {
  return types.map((key) => {
    return {
      text: customLayerTypesLabels[key],
      value: key,
    }
  });
}
