import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { httpV1 } from '@/bundles/Http/factory/httpFactory';
import UploadService from '@/bundles/Upload/services/UploadService';
import { UploadToMapper } from '@/bundles/Upload/mappers/UploadToMapper';

function uploadServiceFactory () {
  return new UploadService({
    transport: httpV1,
    errorMapper: new ErrorMapper(),
    recordMapper: new UploadToMapper(),
  });
}

export const uploadService = uploadServiceFactory();
