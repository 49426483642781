
































































































































































import mixins from 'vue-typed-mixins';
import { isEqual } from 'lodash';
import { Nullable } from '@/utils/types';
import { addDates } from '@/utils/filters';
import { ServiceFactory } from '@/services/ServiceFactory';
import { IHeader, ITableOptions } from '@/bundles/BaseTable/interfaces';
import { getTableOptions } from '@/bundles/BaseTable/helpers/consts';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';
import { getComparablesHeadersData } from '@/bundles/Comparables/helpers';
import { mapState, mapGetters } from 'vuex';
import { RecordsCardMixin } from '@/bundles/Common/mixins/RecordsCardMixin';
import { AdminStoreDataListEnum } from '@/store/modules/admin/types/AdminStoreDataListEnum';

import BaseTable from '@/bundles/BaseTable/components/BaseTable.vue';
import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import ComparablesListTableContent from '@/bundles/Comparables/components/ComparablesListTableContent.vue';

const tableOptions = getTableOptions({
  itemsPerPage: 10,
  sortBy: ['created.date'],
  sortDesc: [true]
});

const ComparableService = ServiceFactory.get('comparable');

interface IDashboardComparableCardData {
  widgetType: UserWidgetsEnum;
  tableOptions: ITableOptions;
  inHouse: boolean;
  menu: false;
  menu2: false;
  dateStart: string;
  dateEnd: string,
  propertyType: Nullable<PropertyTypeEnum>;
  isFirstLoad: boolean;
  filtersMenu: boolean;
}

export default mixins(RecordsCardMixin).extend({
  name: 'DashboardComparablesCard',

  components: {
    ComparablesListTableContent,
    BaseTable,
    DashboardCard
  },

  props: {
    expanded: Boolean,
    sourceId: { // should be here to override prop from the mixin
      type: String,
      default: ''
    },
    sourceType: { // should be here to override prop from the mixin
      type: String,
      default: ''
    }
  },

  data: (): IDashboardComparableCardData => ({
    widgetType: UserWidgetsEnum.recent_comparables,
    tableOptions,
    inHouse: true,
    menu: false,
    menu2: false,
    dateStart: addDates(new Date(Date.now()).toISOString(), { days: -14, }),
    dateEnd: new Date().toISOString().substr(0, 10),
    propertyType: null,
    isFirstLoad: true,
    filtersMenu: false,
  }),

  computed: {
    ...mapState({
      activeAccountId: (state: any): number => state.activeAccount.id,
    }),
    ...mapGetters('Admin', ['getListByCategory']),
    propertyTypes (): PropertyTypeEnum[] {
      return this.getListByCategory(AdminStoreDataListEnum.property_types).items;
    },
    headers: function (): IHeader[] {
      return getComparablesHeadersData(this.activeAccountId);
    },
  },
  watch: {
    tableOptions: {
      handler: function () {
        this.fetchComparables();
      },
      deep: true,
      immediate: true,
    },
    inHouse () {
       this.fetchComparables();
    },
    dateStart: {
      handler: function () {
        this.menu = false;
        this.fetchComparables();
      }
    },
    dateEnd: {
      handler: function () {
        this.menu2 = false;
        this.fetchComparables();
      }
    }
  },
  methods: {
    async fetchComparables () {
      try {
        this.filterLoading = true;
        const params = [
          { name: 'pageOffset', value: this.pageOffset },
          { name: 'comparable_in_house', value: this.inHouse ? 'only' : 'all' },
          { name: 'pageLimit', value: this.tableOptions.itemsPerPage },
          { name: 'sort', value: this.tableOptions.sortBy },
          { name: 'descending', value: this.tableOptions.sortDesc },
          { name: 'comparable_transactionDate', value: `${this.dateStart},${this.dateEnd}` },
        ];
        if (this.propertyType) {
          params.push({ name: 'property_type', value: this.propertyType });
        }
        if (isEqual(this.params, params)) {
          return;
        }

        if (!this.isFirstLoad) {
          await this.$vuetify.goTo('#dashboard-comparables-card');
        }
        this.params = [ ...params ];

        const { data } = await ComparableService.list({ token: this.token, params });

        this.items = data.result;
        this.total = data.total;
      } catch (error: any) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
        this.filterLoading = false;
        this.isFirstLoad = false;
      }
    }
  },
})
