
import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { formatSpaceSizeLabel } from '@/bundles/MeasurementUnits/helpers';
import store from '@/store/store';

export default Vue.extend({
  name: 'BuildingSizeLabel',

  functional: true,

  props: {
    value: {
      type: [String, Number],
      default: 0
    }
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const value = ctx.props.value;
    const className = `${ctx.data.staticClass || ''} ${ctx.data.class || ''}`.trim();

    if (!value) {
      return h('span', { class: `${className} na`.trim() }, 'N/A');
    }

    const defaultBuildingSizeUnit = store.state.MeasurementUnits.buildingSize;
    const selectedBuildingSizeUnit = store.state.accountSettings?.properties?.building_size_type?.value;

    const label = formatSpaceSizeLabel(value, {
      from: defaultBuildingSizeUnit,
      to: selectedBuildingSizeUnit
    });

    return h('span', { ...ctx.data, class: className || undefined }, label);
  }
});
