import { ICriticalDateListResponse, ILeaseDate } from '@/bundles/CriticalDates/interfaces';
import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';
import { CriticalDateType } from '@/bundles/CriticalDates/types';

export default class CriticalDateListResponse implements ICriticalDateListResponse {
  _id: string;
  _key: string;
  created: ICreatedUpdatedBy;
  date: string;
  lease_date: Partial<ILeaseDate>;
  type: CriticalDateType;
  updated: ICreatedUpdatedBy;

  constructor (payload: ICriticalDateListResponse) {
    this._id = payload._id;
    this._key = payload._key;
    this.created = payload.created;
    this.date = payload.date;
    this.lease_date = payload.lease_date;
    this.type = payload.type;
    this.updated = payload.updated;
  }
}
