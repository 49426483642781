import Vue from 'vue';
import { ISortingData, ITableOptions } from '@/bundles/BaseTable/interfaces';
import { Nullable } from '@/utils/types';
import { mapGetters, mapMutations } from 'vuex';
import { SET_SELECTED, SET_TABLE_OPTIONS } from '@/store/modules/tableOptions/types';

interface IPaginationMixin {
  tableOptionsType: Nullable<string>;
}

const PaginationMixinBase = Vue.extend({
  data: (): IPaginationMixin => ({
    tableOptionsType: null // needs to be overwritten in components
  }),

  computed: {
    ...mapGetters('TableOptions', {
      getTableOptionsByType: 'getTableOptionsByType',
      getSelectedByType: 'getSelectedByType'
    }),
    tableOptions: function (): ITableOptions {
      return this.getTableOptionsByType(this.tableOptionsType);
    },
    pageOffset: function (): number {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
    }
  },

  methods: {
    ...mapMutations('TableOptions', {
      setTableOptions: SET_TABLE_OPTIONS,
      setSelected: SET_SELECTED
    }),
    setFilterSort (type: string, sorting: ISortingData = {}) {
      this.setTableOptions({
        type,
        tableOptions: {
          ...this.tableOptions,
          ...sorting
        }
      });
    },
    resetPagination: function (type: string) {
      if (this.tableOptions.page === 1) {
        this.fetchWrapper();
      } else {
        this.setTableOptions({
          type,
          tableOptions: {
            ...this.tableOptions,
            page: 1
          }
        });
      }
    },
    fetchWrapper: function () {} // it's just a stub what will be rewritten inside views what use that mixin
  },
});

export { PaginationMixinBase };
