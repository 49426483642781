import { IUserLayout } from '@/bundles/Settings/interfaces/IUserLayout';
import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';
import { IUserLayoutWidget } from '@/bundles/Settings/interfaces/IUserLayoutWidget';

export class UserLayout extends BaseArangoModel implements IUserLayout {
  member_id: number;
  page: string;
  widgets: Array<IUserLayoutWidget>;

  constructor (entity: IUserLayout) {
    super(entity);
    this.member_id = entity.member_id;
    this.page = entity.page;
    this.widgets = entity.widgets;
  }
}
