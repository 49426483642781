import * as constants from './pipelines-types'
import instance from '../../../services/instance'

export default {
  [constants.ADD_CONTRACT_TO_PIPELINE] (
    { commit, rootState },
    { contract, key }
  ) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/pipeline/${key}`, contract, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_PIPELINES] ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      instance
        .get('/pipeline/list', {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          let { pipelines } = result.data
          commit(constants.SET_PIPELINES, pipelines)
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_PIPELINES_FULL_LIST] ({ commit, rootState }, params = null) {
    let url = params ? `/pipeline/list?${params}` : '/pipeline/list'
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((res) => {
          let { result } = res.data
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
