import instance from '../instance';
import { generateObjectParams, generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/comparable';

export default {
  list: async function ({
    params = [],
    token,
    onDownloadEventHandler = null,
    controller = null
  }) {
    try {
      const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/contact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ id, token, contact }) {
    try {
      return await instance.delete(`${resource}/${id}/contact/${contact}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocation: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addCompany: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeCompany: async function ({ id, token, companyId }) {
    try {
      return await instance.delete(`${resource}/${id}/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecord: async function ({ token, id, params }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/record${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getShared: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  loadRequirements: async function ({ id }) {
    try {
      return await instance.get(`${resource}/${id}/requirements`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createComparable: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/create`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/contacts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecordComparables: async function ({ token, id, source, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${source}/${id}/comparables${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getSharedContacts: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared/contacts`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/${id}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  patch: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPipeline: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/pipeline`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getWorking: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/working`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updatePeriod: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/updatePeriod`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/contact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateCompany: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateSchedule: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/updateSchedule`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateComments: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/updateComments`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * get the result of the report
   */
  getReport: async function ({ token, params = [] }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/report${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
