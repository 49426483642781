import { INotification } from '@/bundles/Notification/interfaces/INotification';
import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import NotificationMapper from '@/bundles/Notification/mappers/NotificationMapper';
import { Notification } from '@/bundles/Notification/models/Notification';

export default class NotificationService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly recordMapper: NotificationMapper;

  constructor (transport: HttpTransport, errorMapper: ErrorMapper, recordMapper: NotificationMapper) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.recordMapper = recordMapper;
  }

  async getMyNotifications (): Promise<Notification[]> {
    try {
      const notifications = await this.transport.get(`/messaging/my-notifications`);

      return notifications.map((notification: INotification) => this.recordMapper.mapToModel(notification));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateNotificationStatus (notificationId: number): Promise<Notification> {
    try {
      const notification = await this.transport.patch(`/messaging/${notificationId}/status-notification`, {});

      return this.recordMapper.mapToModel(notification);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async clearAllNotifications (): Promise<void> {
    try {
      return await this.transport.post(`/messaging/clear-notification`, {});
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
