import { AdminStoreDataListEnum } from '@/store/modules/admin/types/AdminStoreDataListEnum';
import { ContactRequirementRoles } from '@/bundles/Contact/enums/contactRequirementRoles';

export const getInitialAdminStoreState = () => ({
  [AdminStoreDataListEnum.attachment_types_availability]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_company]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_contact]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_deal]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_member]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_occupier]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_pipeline]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_property]: { items: [] },
  [AdminStoreDataListEnum.availability_roles]: { items: [] },
  [AdminStoreDataListEnum.company_roles]: { items: [] },
  [AdminStoreDataListEnum.comparable_lease_roles]: { items: [] },
  [AdminStoreDataListEnum.comparable_sale_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_lease_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_sale_roles]: { items: [] },
  [AdminStoreDataListEnum.industry_types]: { items: [] },
  [AdminStoreDataListEnum.member_roles]: { items: [] },
  [AdminStoreDataListEnum.occupier_roles]: { items: [] },
  [AdminStoreDataListEnum.pipeline_roles]: { items: [] },
  [AdminStoreDataListEnum.property_types]: { items: [] },
  [AdminStoreDataListEnum.property_roles]: { items: [] },
  [AdminStoreDataListEnum.requirement_roles]: { items: ContactRequirementRoles },
});

export const getAdminStoreStateMock = () => ({
  [AdminStoreDataListEnum.attachment_types_availability]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_company]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_contact]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_deal]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_member]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_occupier]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_pipeline]: { items: [] },
  [AdminStoreDataListEnum.attachment_types_property]: { items: [] },
  [AdminStoreDataListEnum.availability_roles]: { items: [] },
  [AdminStoreDataListEnum.company_roles]: { items: [] },
  [AdminStoreDataListEnum.comparable_lease_roles]: { items: [] },
  [AdminStoreDataListEnum.comparable_sale_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_lease_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_roles]: { items: [] },
  [AdminStoreDataListEnum.deal_sale_roles]: { items: [] },
  [AdminStoreDataListEnum.industry_types]: { items: [] },
  [AdminStoreDataListEnum.member_roles]: { items: [] },
  [AdminStoreDataListEnum.occupier_roles]: { items: [] },
  [AdminStoreDataListEnum.pipeline_roles]: { items: [] },
  [AdminStoreDataListEnum.property_types]: { items: [] },
  [AdminStoreDataListEnum.property_roles]: { items: [] },
  [AdminStoreDataListEnum.requirement_roles]: { items: ContactRequirementRoles },
});
