import actions from './actions';
import mutations from './mutations';
import Member from '@/bundles/Members/models/Member';

export interface IFiltersDataState {
  markets: Array<{ name: string }>;
  isLoaded: boolean;
  members: Array<Member>;
  isMembersLoaded: boolean;
}

const state = (): IFiltersDataState => ({
  markets: [],
  isLoaded: false,
  members: [],
  isMembersLoaded: false,
});

export default {
  namespaced: true,
  state,
  getters: {},
  actions,
  mutations,
}
