import Request from '@/bundles/Requests/models/Request';
import { IRequestNotification } from '@/bundles/Panels/interfaces';
import { IBaseMapper } from '@/bundles/App/interfaces/IBaseMapper';

export default class PRRequestToMapper implements IBaseMapper<IRequestNotification> {
  mapToModel (data: IRequestNotification): Request {
    const tempData = data;

    tempData.title = 'Press Release Request';
    tempData.type = 'prRequest';
    tempData.name = tempData.pressRelease.emailTitle;
    tempData.photo_url = tempData.pressRelease.emailPhoto;
    tempData.body = tempData.pressRelease.emailBody;
    tempData.color = '#E8843E';
    tempData.icon = 'mdi-bullhorn';

    return new Request(tempData);
  }
}
