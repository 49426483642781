import { IBasePostgresModel } from '@/bundles/App/interfaces/IBasePostgresModel';
import BaseModel from '@/bundles/App/models/BaseModel';

export default class BasePostgresModel extends BaseModel implements IBasePostgresModel {
  id: number;

  constructor (payload: IBasePostgresModel) {
    super(payload);
    this.id = payload.id;
  }
}
