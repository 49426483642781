<template>
  <v-card flat outlined class="mt-5">
    <v-card-title>
      Add a record to this task<v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="type"
        :items="types"
        label="Record Type"
        @change="fetchHandler"
      />
      <v-autocomplete
        v-if="showSearchInput"
        v-model="selectedRecords"
        :items="items"
        label="Choose associated items"
        item-text="name"
        return-object
        :search-input.sync="searchInput"
        :loading="loading"
        hide-details
        hide-no-data
        hide-selected
        multiple
        autocomplete="off"
        @change="searchInput = ''"
        @input="selectedRecordWatchHandler"
      >
        <template #item="{ item }">
          <AssociatedItem :item="item" />
        </template>
        <template #selection="{ item }">
          <CommonChip :small="false" :large="item.type === 'contact' && !!item.company">
            <AssociatedItem :item="item" />
          </CommonChip>
        </template>
      </v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';
import { getAssociatedTypes } from '@/bundles/Tasks/helpers/associatedTypes';
import { NotificationMutations } from '@/store/types/mutation-types';
import { debounce } from 'lodash';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import AssociatedItem from '@/bundles/Tasks/components/associated/AssociatedItem.vue';

const TaskService = ServiceFactory.get('task');

export default {
  name: 'AssociatedForm',

  components: { AssociatedItem, CommonChip },

  props: {
    collection: {
      type: Array,
      default: () => [],
    },
    allowAll: Boolean,
  },

  data () {
    return {
      items: [],
      type: 'All Types',
      selectedRecords: [],
      loading: false,
      searchInput: '',
    };
  },

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    }),
    types () {
      return getAssociatedTypes(this.allowAll);
    },
    showSearchInput () {
      return (
        (this.allowAll && this.type === 'All Types') ||
        (!this.allowAll && this.type !== '' && this.type !== 'All Types')
      );
    }
  },

  watch: {
    searchInput (value) {
      if (value?.length >= 2) {
        this.debouncedFetch(this);
      }
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION
    }),
    fetchHandler () {
      this.selectedRecords = [];
      this.fetch();
    },
    debouncedFetch: debounce(function (vm) {
      vm.fetchHandler();
    }, 700),
    fetch: async function () {
      try {
        this.loading = true;
        const params = [
          { name: 'keyword', value: this.searchInput },
          { name: 'type', value: this.type },
          { name: 'take', value: 20 },
        ];

        const { data } = await TaskService.getAssociated({
          params,
          token: this.token
        });

        this.items = data.result;
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      } finally {
        this.loading = false;
      }
    },
    getDuplicateRecord () {
      const lastSelectedRecord = this.selectedRecords[this.selectedRecords.length - 1];

      return this.collection.find((record) => record._key === lastSelectedRecord._key);
    },
    selectedRecordWatchHandler (records) {
      if (!records.length) {
        return;
      }

      const duplicatedRecord = this.getDuplicateRecord();

      if (duplicatedRecord) {
        this.addNotification({
          status: 0,
          title: 'Error',
          message: `${duplicatedRecord.name} is already added`
        });

        this.selectedRecords = this.selectedRecords.filter((record) => record._key !== duplicatedRecord._key);
        return;
      }

      this.$emit('addAssociated', records);
    }
  },
};
</script>
