import * as constants from './availabilities-types'
import instance from '../../../services/instance'

export default {
  [constants.GET_AVAILABILITIES] ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      instance
        .get('/availability/list/?returnType=client', {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          let { result: listings } = result.data
          commit(constants.SET_AVAILABILITIES, listings)
          resolve({ msg: 'Success' })
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.ADD_AVAILABILITIE_TO_REQUIREMENT] (
    { commit, rootState },
    availabilities
  ) {
    return new Promise((resolve, reject) => {
      let { requirementID } = availabilities
      let arrayOfId = availabilities.map((availability) => availability._key)
      instance
        .put(
          `/requirement/${requirementID}/addAvailability`,
          { availabilityKeys: arrayOfId },
          {
            headers: {
              Authorization: 'Bearer ' + rootState.idToken,
            },
          }
        )
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.DELETE_AVAILABILITIE_FROM_REQUIREMENT] (
    { commit, rootState },
    edge
  ) {
    return new Promise((resolve, reject) => {
      instance
        .delete(`/requirement/availability/${edge}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_AVAILABILITIES_FULL_LIST] ({ rootState }, params = null) {
    let url = params
      ? `/availability/list/?returnType=client&${params}`
      : '/availability/list/?returnType=client'
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result.data.result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_AVAILABILITY] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/availability/${id}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data[0])
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_AVAILABILITY_CONTACTS] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/availability/contacts/${id}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let { contacts } = response.data
          resolve(contacts)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.CREATE_ORDER] ({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      instance
        .post('/workOrder', payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_COORDINATES] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/geo/updateCoordinatesByAddress/${id}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let { coordinates } = response.data
          resolve(coordinates)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_AVAILABILITIE] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/availability/${key}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
