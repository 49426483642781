import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import { BaseService } from '@/bundles/App/models/BaseService';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { generateParams } from '@/utils/helpers';
import { TaskCreateUpdateDTO } from '@/bundles/Tasks/dto/TaskCreateUpdateDTO';
import { IPaginationResponse } from '@/bundles/App/interfaces/IPaginationResponse';

export default class TaskService extends BaseService<ITask, RecordMapper<ITask>> {
  async createTask (task: TaskCreateUpdateDTO): Promise<ITask> {
    try {
      const response = await this.transport.post(`/task`, task);

      return this.recordMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateTasksStatus ({ ids, status }: { ids: number[]; status: string }): Promise<ITask[]> {
    try {
      const response = await this.transport.patch(`/task/toggle-status`, { ids, status });

      return response.map(task => this.recordMapper.mapToModel(task));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async archive (payload: { keys: string[] }): Promise<void> {
    try {
      return this.transport.patch('/task/archive', payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateTask ({ task, id }: { task: TaskCreateUpdateDTO; id: string }): Promise<ITask> {
    try {
      const response = await this.transport.put(`/task/${id}`, {
        ...task,
        edit_all: false,
      });

      return this.recordMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getMyTasks (params: Array<{ name: string; value: any }>): Promise<IPaginationResponse<ITask>> {
    try {
      const response = await this.transport.get(`/task/my${generateParams(params)}`);

      return {
        data: response.data.map(task => this.recordMapper.mapToModel(task)),
        total: response.total,
      };
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
