import { OpexDisplayEnum, OpexDisplayLabels } from '@/bundles/Members/enums';

const membersHeadersData = [
  { text: 'Photo', value: 'photo', sortable: false, class: 'photo' },
  { text: '#', value: '', sortable: false },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Member',
      value: 'last_name',
      class: 'name',
      icon: 'string',
      sortable: true
    }]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Location',
      value: 'primary_location',
      class: 'name',
      icon: 'string',
      sortable: true
    }]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Job Function',
      value: 'job_function',
      class: 'job_function',
      icon: 'string',
      sortable: true
    }]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Start Date',
      value: 'start_date',
      class: 'name',
      icon: 'string',
      sortable: true
    },
    {
      text: 'Status',
      value: 'status',
      class: 'name',
      icon: 'string',
      sortable: true
    }]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Employee Type',
      value: 'employee_type',
      class: 'name',
      icon: 'string',
      sortable: true
    }, {
      text: 'Employee Category',
      value: 'employee_category',
      class: 'name',
      icon: 'string',
      sortable: true
    }]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{
      text: 'Roles',
      value: 'roles',
      class: 'name',
      icon: 'string',
      sortable: false
    }]
  },
  {
    text: 'multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'License Type',
        value: 'license.type',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'License State',
        value: 'license.state',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'License Status',
        value: 'license.status',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'License Expiration',
        value: 'license.expiration',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Last user activity',
        value: 'last_activity',
        sortable: true,
        icon: 'date'
      },
      {
        text: 'Updated',
        value: 'updated.date',
        sortable: true,
        icon: 'date'
      },
      {
        text: 'Created',
        value: 'created.date',
        sortable: true,
        icon: 'date'
      }
    ]
  }
]

const teamsHeadersData = [
  { text: '#', value: '', sortable: false },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Team Name',
        value: 'team_name',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Member Name',
        value: 'member_list',
        sortable: true,
        icon: 'string',
      },
    ],
  },
];

const childrenHeadersData = [
  {
    text: "Child's Name",
    align: 'left',
    sortable: false,
    value: 'name',
  },
  {
    text: 'Date of Birth',
    alert: 'right',
    sortable: true,
    value: 'date_of_birth',
  },
  {
    text: 'Age',
    alert: 'right',
    sortable: false,
    value: 'age',
  },
  {
    text: 'Actions',
    sortable: false,
    value: 'action',
  },
];

export { membersHeadersData, teamsHeadersData, childrenHeadersData };

export const getOpexDisplayLabel = (opexDisplay: OpexDisplayEnum) => {
  const label = OpexDisplayLabels[opexDisplay];

  return label || 'OPEX';
};
