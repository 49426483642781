import * as constants from './availabilities-types'

export default {
  [constants.SET_AVAILABILITIES] (state, availabilities) {
    state.availabilities = availabilities
  },
  [constants.SET_AVAILABILITY_VIEW] (state, availabilityViewType) {
    state.view = availabilityViewType
  },
}
