import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/sharing';
const syndicate = '/syndicate';

export default {
  share: async function ({ payload, token }) {
    try {
      return await instance.post(resource, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getActivityList: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/list/activity/?collection=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function (hash, params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/byHash/${hash}${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getUserIp: async function () {
    try {
      return await instance.get(`${resource}/user-ip`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecordByHash: async function (hash) {
    try {
      return await instance.get(`${resource}/record/${hash}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  // TODO sychdo: looks like is not used
  syndication: async function ({ payload, token }) {
    try {
      return await instance.post(syndicate, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
