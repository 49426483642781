import axios, { AxiosRequestConfig } from 'axios';
import { Nullable } from '@/utils/types';
import store from '@/store/store';
import { RootActions } from '@/store/types/action-types';
import { authService } from '@/bundles/Auth/factory/authServiceFactory';
import { RootMutations } from '@/store/types/mutation-types';

interface IQueueItem {
  config: AxiosRequestConfig;
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
  error?: any;
}

class AxiosQueue {
  private queue: IQueueItem[] = [];
  private isRefreshing = false;
  private timeout: Nullable<ReturnType<typeof setTimeout>> = null;

  addToQueue (config: AxiosRequestConfig, resolve: (value?: unknown) => void, reject: (value?: unknown) => void) {
    if (config.url?.includes('/auth/token-refresh')) {
      store.dispatch(RootActions.LOG_OUT);
      return;
    }
    this.queue.push(({ config, resolve, reject }));
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      if (!this.isRefreshing) {
        await this.processQueue();
      }
    }, 1000);
  }

  async processQueue () {
    const isTokenRefreshed = await this.refreshToken();

    if (!isTokenRefreshed) {
      this.queue = [];
      return;
    }

    for (const request of this.queue) {
      try {
        const data = await axios(request.config);
        request.resolve(data);
      } catch (error) {
        if (error?.response?.status === 401) {
          await store.dispatch(RootActions.LOG_OUT);
          break;
        }
        request.reject(error);
      }
    }
    this.queue = [];
  }

  async refreshToken () {
    this.isRefreshing = true;
    try {
      const response = await authService.tokenRefresh();
      store.commit(RootMutations.SET_AUTH_INFO, response);
      this.updateToken(response.access_token);

      return true;
    } catch (error) {
      await store.dispatch(RootActions.LOG_OUT);
      return false;
    } finally {
      this.isRefreshing = false;
    }
  }

  updateToken (token: string) {
    this.queue.forEach((request) => {
      request.config.headers!['Authorization'] = `Bearer ${token}`;
    });
  }
}

// it's important to have a singleton for that class
export const axiosQueue = new AxiosQueue();
