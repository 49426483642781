











































































































import { PropType } from 'vue';
import { mapState } from 'vuex';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { INotification } from '@/bundles/Notification/interfaces/INotification';
import { notificationService } from '@/bundles/Notification/factories/notificationServiceFactory';

import NotificationBadge from '@/bundles/Panels/components/NotificationBadge.vue';

interface INotificationMenu {
  notificationMenu: boolean;
  loadingIds: number[];
  clearAllLoading: boolean;
}

export default mixins(AccountLayoutMixin).extend({
  name: 'NotificationMenu',

  components: { NotificationBadge },

  props: {
    notifications: {
      type: Array as PropType<INotification[]>,
      default: () => []
    }
  },

  data: (): INotificationMenu => ({
    notificationMenu: false,
    loadingIds: [],
    clearAllLoading: false
  }),

  computed: {
    ...mapState({
      token: (state: any) => state.idToken
    }),
  },

  watch: {
    notifications (value) {
      if (!value.length) {
        this.notificationMenu = false;
      }
    }
  },

  methods: {
    openLink (notification: INotification) {
      if (notification.metadata) {
        window.open(notification.metadata.link, '_blank');
      }

      this.changeNotificationStatus(notification);
    },
    async clearNotification (notification: INotification) {
      try {
        this.loadingIds.push(notification.id);
        await notificationService.updateNotificationStatus(notification.id);
        this.$emit('clearNotification', notification.id);
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loadingIds = this.loadingIds.filter(id => id !== notification.id);
      }
    },
    async changeNotificationStatus (notification: INotification) {
      try {
        this.loadingIds.push(notification.id);
        await notificationService.updateNotificationStatus(notification.id);

        this.$emit('updateNotification', { ...notification, show: true });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loadingIds = this.loadingIds.filter(id => id !== notification.id);
      }
    },
    checkIsLoading (notificationId: number) {
      return this.loadingIds.some(id => notificationId === id);
    },
    async clearAllNotifications () {
      try {
        this.clearAllLoading = true;
        await notificationService.clearAllNotifications();

        this.$emit('clearAllNotifications');
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.clearAllLoading = false;
      }
    }
  }
})
