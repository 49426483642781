const getFilterByType = (state) => (type) => state[type].filters;
const getKeywordByType = (state) => (type) => state[type].keyword;
const getFilterName = (state) => (type) => state[type].filterName;
const getFilterId = (state) => (type) => state[type]?.filterId || '';
const getLastSelectedType = (state) => (type) => state[type].lastSelectedType;
const getSavedSetId = (state) => (type) => state[type]?.savedSetId || '';

export default {
  getFilterByType,
  getKeywordByType,
  getFilterName,
  getFilterId,
  getLastSelectedType,
  getSavedSetId
}
