export enum TaskTypesEnum {
  call = 'call',
  email = 'email',
  todo = 'todo'
}

export const TaskTypesLabels = {
  [TaskTypesEnum.call]: 'Phone Call',
  [TaskTypesEnum.email]: 'Email',
  [TaskTypesEnum.todo]: 'To-Do',
}
