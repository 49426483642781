var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardCard',{staticClass:"dashboard-card sc-broker-ranking",attrs:{"expanded":_vm.cardExpanded,"loading":_vm.loading,"widget-type":_vm.widgetType,"title":"Agent Ranking","count":_vm.brokerGrossArray.length},on:{"update:expanded":function($event){return _vm.$emit('update:expanded', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"left":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,1050695365),model:{value:(_vm.filtersMenu),callback:function ($$v) {_vm.filtersMenu=$$v},expression:"filtersMenu"}},[_c('v-card-title',{staticClass:"py-1"},[_c('span',[_vm._v("Agent Ranking Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.filtersMenu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card',{staticClass:"pa-2 d-flex gap-2 justify-center flex-column",attrs:{"flat":""}},[_c('v-switch',{staticClass:"ml-auto mt-0",attrs:{"label":"Show $","hide-details":"","small":"","inset":""},model:{value:(_vm.brokerGrossShowCommission),callback:function ($$v) {_vm.brokerGrossShowCommission=$$v},expression:"brokerGrossShowCommission"}}),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}},[_c('v-btn',{staticClass:"grossButton flex-grow-1 py-4",attrs:{"small":"","value":"paid"}},[_vm._v("Received")]),_c('v-btn',{staticClass:"grossButton flex-grow-1 py-4",attrs:{"small":"","value":"gross"}},[_vm._v("Booked")])],1),(_vm.defaultStartDate)?_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.date2))+" - "+_vm._s(_vm._f("formatDate")(_vm.date)))]):[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" From: "+_vm._s(_vm._f("formatDate")(_vm.date2))+" ")],1)]}}],null,false,187408142),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v("To: "+_vm._s(_vm._f("formatDate")(_vm.date))+" ")],1)]}}],null,false,247201425),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.isAdmin)?_c('v-select',{attrs:{"outlined":"","label":"Select Agent","dense":"","hide-details":"","items":_vm.members,"loading":_vm.isMembersLoading,"item-text":"full_name","item-value":"id","clearable":""},on:{"change":_vm.changeUser}}):_vm._e()],_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.filtersMenu = false}}},[_vm._v("Apply")])],2)],1):_c('div',{staticClass:"broker-ranking-card__buttons-wrapper"},[_c('div',{staticClass:"broker-ranking-card__buttons-group-item"},[_c('v-switch',{staticClass:"mr-5",attrs:{"label":"Show $","hide-details":"","small":""},model:{value:(_vm.brokerGrossShowCommission),callback:function ($$v) {_vm.brokerGrossShowCommission=$$v},expression:"brokerGrossShowCommission"}}),_c('v-btn-toggle',{staticClass:"mr-2",attrs:{"mandatory":""},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}},[_c('v-btn',{staticClass:"grossButton py-4",attrs:{"small":"","value":"paid"}},[_vm._v("Received")]),_c('v-btn',{staticClass:"grossButton py-4",attrs:{"small":"","value":"gross"}},[_vm._v("Booked")])],1)],1),(_vm.defaultStartDate)?_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.date2))+" - "+_vm._s(_vm._f("formatDate")(_vm.date)))]):_c('div',{staticClass:"broker-ranking-card__buttons-group-item"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" From: "+_vm._s(_vm._f("formatDate")(_vm.date2))+" ")],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v("To: "+_vm._s(_vm._f("formatDate")(_vm.date))+" ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.isAdmin)?_c('v-select',{staticClass:"ml-1",staticStyle:{"max-width":"200px"},attrs:{"outlined":"","label":"Select Agent","dense":"","hide-details":"","items":_vm.members,"loading":_vm.isMembersLoading,"item-text":"full_name","item-value":"id","clearable":""},on:{"change":_vm.changeUser}}):_vm._e()],1)])]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":.7,"absolute":"","color":"white"}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]),_c('v-data-table',{staticClass:"elevation-1 sc-broker-ranking-report",attrs:{"headers":_vm.brokerGrossHeaders,"items":_vm.brokerGrossArray,"single-expand":false,"expanded":_vm.expanded,"items-per-page":-1,"item-key":"name","sort-by":_vm.brokerGrossShowCommission ? 'brokerGross' : 'consideration',"sort-desc":true,"mobile-breakpoint":0,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"sc-broker-ranking-broker",class:("" + (isExpanded ? 'expanded' : '')),on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',{staticStyle:{"width":"80px"}},[_c('v-chip',{attrs:{"small":"","label":"","dark":""}},[_vm._v(_vm._s(item.ranking))])],1),_c('td',{staticStyle:{"width":"auto"}},[_vm._v(_vm._s(item.name))]),(_vm.hasLocations)?_c('td',{staticStyle:{"width":"180px"}},[(item.primary_location)?_c('div',[_vm._v(_vm._s(item.primary_location.name))]):_c('Label')],1):_vm._e(),(_vm.hasAnnualGoal)?_c('td',{staticStyle:{"width":"120px"}},[_c('Label',{attrs:{"value":_vm.getAnnualGoal(item.annual_goal)}})],1):_vm._e(),_c('td',{staticStyle:{"width":"90px"}},[_vm._v(_vm._s(item.dealIds.length))]),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm._f("formatCurrency")(item.consideration)))]),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.brokerGross)))])]):_vm._e(),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[(_vm.reportType !== 'gross')?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.brokerNet)))]):_vm._e()]):_vm._e(),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('Label',{attrs:{"value":_vm.getBrokerNet(item.brokerGross, item.brokerNet)}})],1):_vm._e(),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('BuildingSizeLabel',{attrs:{"value":item.buildingSize}})],1),_c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('LandSizeLabel',{attrs:{"value":item.landSize}})],1),_c('td',{staticStyle:{"width":"24px"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportToCsv(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-export")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{staticClass:"broker-ranking-detail"},[_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('BrokerRankingCardDetails',{attrs:{"item-entry":item,"broker-gross-show-commission":_vm.brokerGrossShowCommission,"report-type-entry":_vm.reportType},on:{"deal:show":_vm.showDeal}})],1)],1)])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"sc-data-footer"},[_c('td',{attrs:{"colspan":"2"}}),(_vm.hasLocations)?_c('td'):_vm._e(),(_vm.hasAnnualGoal)?_c('td'):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.totalCount)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.totalConsideration))+" ")]),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.totalBrokerGross))+" ")]):_vm._e(),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right"},[(_vm.reportType!=='gross')?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totalBrokerNet)))]):_vm._e()]):_vm._e(),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right"},[(_vm.reportType !== 'gross')?_c('span',[_vm._v(_vm._s(_vm.totalBrokerNet2))]):_vm._e()]):_vm._e(),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('BuildingSizeLabel',{attrs:{"value":_vm.totalBuildingSize}})],1),_c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('LandSizeLabel',{attrs:{"value":_vm.totalLandSize}})],1),_c('td',{staticClass:"broker-ranking-card__buttons-wrapper"},[_c('v-menu',{staticStyle:{"width":"300px"},attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-export")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"loading":_vm.isExportAllBrokersLoading},on:{"click":_vm.exportAgentRankingData}},[_c('v-list-item-content',[_vm._v(" Agent Ranking ")])],1),_c('v-list-item',{attrs:{"loading":_vm.isExportAllBrokersLoading},on:{"click":_vm.exportToCsvAllBrokers}},[_c('v-list-item-content',{staticClass:"no-wrap"},[_vm._v(" Detailed Agent Ranking ")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.printAgentRankingData}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-plus")])],1)]}}])},[_c('span',[_vm._v("Agent Ranking PDF")])])],1)])]},proxy:true}])})],1),_c('v-snackbar',{staticClass:"pa-0",attrs:{"right":"","timeout":-1,"light":""},model:{value:(_vm.dealPreview),callback:function ($$v) {_vm.dealPreview=$$v},expression:"dealPreview"}},[_c('v-card',{staticStyle:{"position":"relative"}},[_c('v-btn',{staticStyle:{"z-index":"5"},attrs:{"absolute":"","right":"","icon":"","top":""},on:{"click":_vm.closePreview}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('DealCard',{attrs:{"item-id":_vm.dealPreviewId,"show-sumary":true,"allow-edit":false,"allow-link":true}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }