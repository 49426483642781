import instance from '../instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/account';

export default {
  getAccountShared: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAccountById: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAccount: async function ({ token }) {
    try {
      return await instance.get(`${resource}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveAccount: async function ({ payload, token }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateAccount: async function ({ payload, token, id }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendRestoreLink: async function ({ payload, token }) {
    try {
      return await instance.post(`${resource}/reset`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  restorePassword: async function ({ payload, token, id }) {
    try {
      return await instance.put(`${resource}/reset/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportReport: async function ({ token, dateStart, dateEnd }) {
    try {
      return await instance.get(
        `${resource}/export/?deal_close_start_date=${dateStart}&deal_close_end_date=${dateEnd}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
