import Profile from '@/bundles/Members/models/Profile';
import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';
import { IOfficeLocation } from '@/bundles/OfficeLocation/interfaces/IOfficeLocation';
import { Nullable } from '@/utils/types';

export default class Member {
  id!: number;
  email!: string;
  is_active!: boolean;
  profile!: Profile;
  created!: string;
  updated!: string;
  created_by!: ICreatedUpdatedBy|null;
  updated_by!: ICreatedUpdatedBy|null;
  legacy_member_id!: string|null;
  roles!: any[];
  member_company_file?: any;
  licenses?: any[];
  compensations?: any[];
  name?: string;
  primary_location?: IOfficeLocation;
  last_activity?: Nullable<Date>;
  team?: {
    id: number;
    name: string;
  }
}
