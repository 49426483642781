import instance from '@/services/instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();

export default {
  postDealById: async function ({ token, payload, id }: {
    token: string;
    payload: Record<string, any>;
    id: string;
  }): Promise<any> {
    try {
      return await instance.post(`/dealius/deals/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
