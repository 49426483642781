<template>
  <div class="splash-container">
    <div class="top-item item">
      <v-progress-linear
        :indeterminate="true"
        color="green"
      />
    </div>
    <div class="main-item item">
      <img
        :src="require('@/../static/img/sc-logo.png')"
        alt=""
        class="splash-loader"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
}
</script>
