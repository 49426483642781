import instance from '@/services/instance';

const resource = '/pub';

export default {
  get (hash) {
    return instance.get(`${resource}/${hash}`, {});
  },
  editTemplateContent (id, json, token) {
    return instance.patch(`${resource}/${id}/template`, {
      json
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
