import { generateParams } from '@/utils/helpers';
import instance from '@/services/instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import RequestToMapper from '@/bundles/Requests/mappers/RequestToMapper';

const errorMapper = new ErrorMapper();
const resource = '/request';

export default {
  list: async function ({ params = [], token, onDownloadEventHandler = null }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}${paramsString}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOne: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  vote: async function ({ id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/vote`, {}, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  restore: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/restore`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  close: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getNotifications: async function ({ token }) {
    try {
      const { data } = await instance.get(`${resource}/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return (data || []).map((item) => new RequestToMapper().mapToModel(item));
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  ignoreNotification: async function ({ id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/ignore`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendComment: async function ({ payload, id, token }) {
    try {
      return await instance.post(`${resource}/${id}/comment`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendAdminComment: async function ({ payload, id, token }) {
    try {
      return await instance.post(`${resource}/${id}/admin-comment`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateComment: async function ({ payload, id, token }) {
    try {
      return await instance.patch(`${resource}/comment/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  refreshAsanaConnection: async function (id, token) {
    try {
      return await instance.post(`${resource}/reconnect/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addAttachments: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/attachments/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeAttachment: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/remove-attachment/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
