import { IBaseService } from '@/bundles/App/interfaces/IBaseService';
import { MarketToMapper } from '@/bundles/Markets/mappers/MarketToMapper';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import { Market } from '@/bundles/Markets/models/Market';
import { IMarketLayer } from '@/bundles/Markets/interfaces/IMarketLayer';
import { generateParams } from '@/utils/helpers';
import { INameValue } from '@/types/common';

export default class MarketService implements IBaseService<MarketToMapper> {
  errorMapper: ErrorMapper;
  recordMapper: MarketToMapper;
  transport: HttpTransport;

  constructor (payload: IBaseService<MarketToMapper>) {
    this.errorMapper = payload.errorMapper;
    this.recordMapper = payload.recordMapper;
    this.transport = payload.transport;
  }

  async getMarkets (): Promise<Market[]> {
    try {
      const response = await this.transport.get('/markets/account-markets');
      return response.map((item) => this.recordMapper.mapToModel(item));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getLayersForMarket (key: string, params: Array<INameValue<string | number>> = []): Promise<IMarketLayer[]> {
    try {
      const paramsString = generateParams(params);
      return await this.transport.get(`/markets/${ key }/market-layers${ paramsString }`);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
