import * as popupContentBuilder from '@/bundles/Map/helpers/popupContentBuilder';
import { placeholderPropertyImg } from '@/settings';
import { ITextValue } from '@/types/common';
import { formatCurrency, formatDate, formatNumber } from '@/utils/filters';
import { TransactionTypesEnum } from '@/bundles/Comparables/types';
import { getComparableLeaseTermLength } from '.';
import { getComparableCompaniesInfo } from '@/bundles/Comparables/helpers/getComparableCompaniesInfo';

const sourceType = 'comparable';

export const generateMapFeatures = (comparables, formatters) => {
  return comparables.filter(comparable => comparable.coordinates.lat && comparable.coordinates.long)
    .map(comparable => {
      let officeSize = formatters.buildingSize ? formatters.buildingSize(comparable.transaction.office_space) : null;

      if (comparable.transaction.office_space_bts) officeSize = 'BTS';
      return {
        id: comparable._key || comparable.id,
        number: comparable.number,
        // TODO sychdo: remove when cluster popups are updated
        place_name: `${
          comparable.address?.postal || comparable.home_address?.home_postal
        }, ${comparable.address?.street || comparable.home_address?.home_street}, ${
          comparable.address?.city || comparable.home_address?.home_city
        }, ${comparable.address?.state || comparable.home_address?.home_state}, ${
          comparable.address?.country || ''
        }`,
        address: comparable.address,
        text: comparable.name,
        center: [comparable.coordinates.long, comparable.coordinates.lat],
        place_type: sourceType,
        place_photo: comparable.photo || comparable.img || placeholderPropertyImg,
        type: 'Feature',
        image: comparable.photo?.url || placeholderPropertyImg,
        properties: {
          number: comparable.number,
          place_name: comparable.name,
          image: comparable.photo?.url || placeholderPropertyImg,
          address: `${
            comparable.address?.postal || comparable.home_address?.home_postal
          }, ${comparable.address?.street || comparable.home_address?.home_street}, ${
            comparable.address?.city || comparable.home_address?.home_city
          }, ${comparable.address?.state || comparable.home_address?.home_state}, ${
            comparable.address?.country || ''
          }`,
          transaction: {
            ...comparable.transaction,
            transaction_date: formatDate(comparable.transaction.transaction_date),
            total_square_feet: formatters.buildingSize ? formatters.buildingSize(comparable.transaction.total_square_feet) : comparable.transaction.total_square_feet,
            total_acres: formatters.landSize ? formatters.landSize(comparable.transaction.total_acres) : comparable.transaction.total_acres,
            office_size: officeSize,
            lease_commencement: formatDate(comparable.transaction.lease_commencement),
            lease_expiration: formatDate(comparable.transaction.lease_expiration),
            starting_rent_sf: `${formatCurrency(comparable.transaction.starting_rent_sf)} / SF`,
            average_rent_sf: `${formatCurrency(comparable.transaction.average_rent_sf)} / SF`,
            effective_rent_sf: `${formatCurrency(comparable.transaction.effective_rent_sf)} / SF`,
            free_rent: comparable.transaction.free_rent ? `${comparable.transaction.free_rent} ${comparable.transaction.lease_term || ''}` : null,
            lease_term_length: getComparableLeaseTermLength(comparable),
            sale_price_total: `${formatCurrency(comparable.transaction.sale_price_total)}`,
            sale_price_sf: comparable.property_type === 'land' ? `${formatCurrency(comparable.transaction.sale_price_total / comparable.transaction.total_acres)}` : `${formatCurrency(comparable.transaction.sale_price_sf)}`,
            cap_rate: `${comparable.transaction.cap_rate}%`,
            percent_leased: `${comparable.transaction.percent_leased}%`,
          },
          property_type: comparable.property_type,
          // TODO sychdo: remove when cluster popups are updated
          transaction_type: comparable.transaction_type,
          transaction_date: comparable.transaction_date,
          total_square_feet: comparable.transaction.total_square_feet,
          total_acres: comparable.transaction.total_acres,
          companies: comparable.companies,
          contacts: comparable.contacts,
        },
        geometry: {
          type: 'Point',
          interpolated: true,
          coordinates: [comparable.coordinates.long, comparable.coordinates.lat],
        },
      }
    })
};

const getPopupBodyData = (comparable: any) => {
  const transaction = comparable.properties.transaction;

  let officeSize = transaction.office_space && transaction.office_space > 0 ? transaction.office_space : null;

  if (transaction.office_space_bts) officeSize = 'BTS';

  const popupBodyData: ITextValue<string | number>[] = [
    { text: 'Transaction Date', value: transaction.transaction_date },
    { text: 'Building Size', value: transaction.total_square_feet },
    { text: 'Land Size', value: transaction.total_acres }
  ];

  if (comparable.properties.property_type === 'Industrial') {
    popupBodyData.push({ text: 'Office Size', value: `${formatNumber(officeSize)} SF` });
  }

  if (transaction.transaction_type === 'lease') {
    popupBodyData.push({ text: 'Commencement Date', value: transaction.lease_commencement });
    popupBodyData.push({ text: 'Expiration Date', value: transaction.lease_expiration });
    popupBodyData.push({ text: 'Starting Rent', value: transaction.starting_rent_sf });
    popupBodyData.push({ text: 'Average Rent', value: transaction.average_rent_sf });
    popupBodyData.push({ text: 'Effective Rent', value: transaction.effective_rent_sf });

    if (transaction.free_rent) {
      popupBodyData.push({ text: 'Free Rent', value: transaction.free_rent });
    }
    if (transaction.lease_term_length) {
      popupBodyData.push({ text: 'Lease Term', value: transaction.lease_term_length });
    }
  } else {
    popupBodyData.push({ text: 'Total Price', value: transaction.sale_price_total });
    popupBodyData.push({ text: 'Total Price Per SF', value: transaction.sale_price_sf });

    if (transaction.investment_sale) {
      popupBodyData.push({ text: 'Investment Sale', value: transaction.investment_sale });
      popupBodyData.push({ text: 'Cap Rate', value: transaction.cap_rate });
      popupBodyData.push({ text: '% Leased', value: comparable.percent_leased });
    }
  }

  const buyerTenantSellerLandlord = getComparableCompaniesInfo(comparable.properties);

  popupBodyData.push({ text: buyerTenantSellerLandlord.buyerTenantLabel, value: buyerTenantSellerLandlord.buyerTenantValue });
  popupBodyData.push({ text: buyerTenantSellerLandlord.sellerLandlordLabel, value: buyerTenantSellerLandlord.sellerLandlordValue });

  return popupBodyData;
};

export const getComparablePopupContent = (comparable) => {
  const container = popupContentBuilder.getContainer();
  const imageSection = popupContentBuilder.getImageSection(comparable.image, 'Comparable Image');
  const idSection = popupContentBuilder.getIdSection(sourceType, comparable.id);
  const titleSection = popupContentBuilder.getTitleSection(sourceType, comparable.id, comparable.text);
  const addressSection = popupContentBuilder.getAddressSection(comparable.address);
  const bodySection = popupContentBuilder.getBodySection(getPopupBodyData(comparable));
  const labelText = comparable.properties.transaction_type === TransactionTypesEnum.lease ? 'leased' : 'sold';
  const buildingDetailsSection = popupContentBuilder.getBuildingDetailsSection({ labelText, addressSection, imageSection });

  container.append(buildingDetailsSection, idSection, titleSection, bodySection);

  return container;
};
