import MemberService from '@/bundles/Members/services/MemberService';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import MemberToMapper from '@/bundles/Members/mappers/MemberToMapper';

function memberServiceFactory () {
  return new MemberService({
    transport: httpV2,
    errorMapper: new ErrorMapper(),
    memberMapper: new MemberToMapper(),
  });
}

export const memberService = memberServiceFactory();
