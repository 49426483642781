import mixins from 'vue-typed-mixins';
import { Nullable } from '@/utils/types';
import { PaginationMixinBase } from './PaginationMixinBase';

const PaginationMixin = mixins(PaginationMixinBase).extend({
  computed: {
    // array of selected item keys
    selected: {
      get (): string[] {
        return this.getSelectedByType(this.tableOptionsType);
      },
      set (value: string[]): void {
        this.setSelected({
          type: this.tableOptionsType,
          selected: [...value]
        });
      }
    }
  },
});

export { PaginationMixin };
