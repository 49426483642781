import store from '../store/store'
import { eventBus } from '@/eventbus/eventbus'
import { REQUEST_CANCELLED_MESSAGE } from '@/bundles/Http/mappers/ErrorMapper';

export default function setup (config) {
  if (!config) {
    throw new Error(REQUEST_CANCELLED_MESSAGE);
  }

  if (
    config.request.responseURL.includes('slickcactus.com') ||
    config.request.responseURL.includes('localhost')
  ) {
    const curVersion = store.state.appVersion
    const newVersion = config.headers['x-server-version']
    if (!newVersion) return config;
    if (curVersion !== newVersion) {
      eventBus.$emit('updateAppVersionNoModal', newVersion)
    }
  }
  return config
}
