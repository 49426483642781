
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { Nullable } from '@/utils/types';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { getBuildingDimensionFormatted } from '@/bundles/MeasurementUnits/helpers/format/getBuildingDimensionFormatted';

export default Vue.extend({
  name: 'BuildingDimensionLabel',

  functional: true,

  props: {
    value: {
      type: [String, Number] as PropType<Nullable<string | number>>,
      default: 0
    }
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const value = ctx.props.value;

    if (!isValuePresent(value) || value === 0) {
      return h('span', { class: 'na' }, 'N/A');
    }

    const label = getBuildingDimensionFormatted(value);

    if (!label) {
      return h('span', { class: 'na' }, 'N/A');
    }

    return h('span', label);
  }
});
