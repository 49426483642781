import * as constants from './contact-types'
import instance from '../../../services/instance'

export default {
  [constants.GET_CONTACTS_FULL_LIST] ({ commit, rootState }, params = null) {
    let url = params ? `/contact/list?${params}` : '/contact/list'
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((res) => {
          let { result, total } = res.data
          resolve({ items: result, total: total })
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_CONTACT] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/contact/${key}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
