import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import { IBaseModel } from '@/bundles/App/interfaces/IBaseModel';

export abstract class BaseService<TEntity extends IBaseModel, TMapper extends RecordMapper<TEntity>> {
  protected readonly transport: HttpTransport;
  protected readonly errorMapper: ErrorMapper;
  protected readonly recordMapper: TMapper;

  constructor (transport: HttpTransport, errorMapper: ErrorMapper, recordMapper: TMapper) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.recordMapper = recordMapper;
  }
}
