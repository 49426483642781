import mixins from 'vue-typed-mixins';
import { RecordsCardMixin } from '@/bundles/Common/mixins/RecordsCardMixin';
import { mapState } from 'vuex';
import { PropType } from 'vue';
import { IProperty } from '@/bundles/Property/interfaces/IProperty';
import { Nullable } from '@/utils/types';
import { formatPercent, formatRawNumber } from '@/utils/filters';

interface IAvailabilitiesCardMixinData {
  showLeaseAndSold: boolean;
}

export const AvailabilitiesCardMixin = mixins(RecordsCardMixin).extend({
  props: {
    property: {
      type: Object as PropType<IProperty>,
      default: () => ({} as IProperty),
    }
  },

  data: (): IAvailabilitiesCardMixinData => ({
    showLeaseAndSold: false,
  }),

  computed: {
    ...mapState({
      activeAccountId: (state: any) => state.activeAccount?.id
    }),
  },
});
