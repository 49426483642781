import {
  BuildingDimensionMeasurementUnits,
  CraneMeasurementUnits,
  DistanceMeasurementUnits,
  SpaceMeasurementUnits
} from '@/bundles/MeasurementUnits/types';

const initialState = {
  landSize: SpaceMeasurementUnits.sf,
  buildingSize: SpaceMeasurementUnits.sf,
  distance: DistanceMeasurementUnits.miles,
  capacity: CraneMeasurementUnits.capacity,
  clearance: CraneMeasurementUnits.clearance,
  height: CraneMeasurementUnits.height,
  buildingDimension: BuildingDimensionMeasurementUnits.inches
};

export default {
  namespaced: true,
  state: initialState,
};
