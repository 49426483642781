import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import NotificationService from '@/bundles/Notification/services/NotificationService';
import NotificationMapper from '@/bundles/Notification/mappers/NotificationMapper';

function notificationServiceFactory () {
  return new NotificationService(
    httpV2,
    new ErrorMapper(),
    new NotificationMapper(),
  );
}

export const notificationService = notificationServiceFactory();
