export default class Profile {
  id!: number;
  first_name!: string;
  last_name!: string;
  img!: string;
  last_login?: number;
  real_estate_career_start_date?: number|string;
  date_of_birth?: number|string;
  sex?: string;
  email_signature?: string;
  former_firm?: string;
}
