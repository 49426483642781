import { ITextValue } from '@/types/common';
import { IAddress } from '@/utils/interfaces';
import store from '@/store/store';
import { Nullable } from '@/utils/types';
import { ImageFitEnum } from '@/bundles/Map/enums/imageFitEnum';

const containerClassList = ['hover-card', 'v-card', 'v-sheet', 'theme--light', 'hover-card--loaded'];
const idSectionClass = 'record-id';
const titleSectionClassList = ['mapboxgl-popup-content__title', 'mt-1'];
const addressSectionClass = 'mapboxgl-popup-content__description';
const imageSectionClass = 'mapboxgl-popup-content__img';
const bodySectionClass = 'mapboxgl-popup-content__body';

export const getContainer = () => {
  const div = document.createElement('div');
  div.classList.add(...containerClassList);

  return div;
};

export const getIdSection = (sourceType: string, id: number | string) => {
  const span = document.createElement('span');
  span.classList.add(idSectionClass);
  span.innerHTML = `${sourceType} ID# ${id}`;

  return span;
};

export const getTitleSection = (sourceType: string, id: number | string, title: string) => {
  const a = document.createElement('a');
  const activeAccount = (store.state.activeAccount as any).id;

  a.classList.add(...titleSectionClassList);
  a.setAttribute('href', `/#/${activeAccount}/${sourceType}/${id}`);
  a.setAttribute('target', `_blank`);
  a.innerText = title;

  return a;
};

export const getImageSection = (src: string, alt: string, objectFit: ImageFitEnum = ImageFitEnum.cover) => {
  const img = document.createElement('img');
  img.setAttribute('src', src);
  img.setAttribute('alt', alt);
  img.classList.add(imageSectionClass, `${imageSectionClass}--${objectFit}`);

  return img;
}

export const getAddressSection = (address: IAddress) => {
  const span = document.createElement('span');
  span.classList.add(addressSectionClass);
  span.innerHTML = `
    ${address.street ? `${address.street}<br>` : ''}
    ${address.city ? `${address.city},` : ''}
    ${address.state || ''} ${address.postal || ''}
  `;

  return span;
};

export const getBodySection = (data: ITextValue<string | number>[]) => {
  const div = document.createElement('div');
  div.classList.add(bodySectionClass);
  data.forEach(item => {
    div.innerHTML += `
      <div class="mt-1">
        <strong>${item.text}:</strong>
        <span>${item.value ? item.value : 'N/A'}</span>
      </div>
    `;
  });

  return div;
};

interface IBuildingDetailsSectionParams {
  addressSection: HTMLElement;
  imageSection: Nullable<HTMLElement>;
  labelText?: string;
}

export const getBuildingDetailsSection = (params: IBuildingDetailsSectionParams) => {
  const { labelText, addressSection, imageSection } = params;

  const wrapper = document.createElement('div');
  wrapper.classList.add('mapboxgl-popup-content__building-details');

  const addressWrapper = document.createElement('div');
  addressWrapper.classList.add(`${addressSectionClass}--wrapper`);
  addressWrapper.append(addressSection);

  if (labelText) {
    const label = document.createElement('div');
    label.classList.add('mapboxgl-popup-content__building-details-label');
    label.innerText = labelText;

    wrapper.append(label);
  }

  if (imageSection) {
    wrapper.append(imageSection, addressWrapper);
  } else {
    wrapper.append(addressWrapper);
  }

  return wrapper;
};
