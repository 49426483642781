








































































































import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';
import {
  TaskDaysEnum,
  TaskRecurrenceTypes
} from '@/bundles/Tasks/enums/TaskRepeatEnum';
import { generateArrayOfItemsFromObject } from '@/bundles/App/helpers';
import { ITextValue } from '@/types/common';
import { ITaskCustomRecurrence } from '@/bundles/Tasks/interfaces/ITaskCustomRecurrence';

import CommonDatePicker from '@/components/common-components/pickers/CommonDatePicker.vue';

export interface ITaskCustomRecurrenceForm {
  repeatOptions: ITaskCustomRecurrence;
}

export default mixins(DialogMixin).extend({
  name: 'TaskCustomRecurrenceForm',

  components: { CommonDatePicker },

  props: {
    recurrenceOptions: {
      type: Object as PropType<ITaskCustomRecurrence | undefined>,
      default: () => undefined
    }
  },

  data: (): ITaskCustomRecurrenceForm => ({
    repeatOptions: {
      times: 1,
      type: TaskRecurrenceTypes.day,
      specificDays: [TaskDaysEnum.monday],
      // ends: 'never', TODO: uncomment when we need this functionality
      // endDate: new Date(),
      // occurrences: 30,
    }
  }),

  computed: {
    taskRecurrenceTypeLabels (): Array<ITextValue<string>> {
      return generateArrayOfItemsFromObject(TaskRecurrenceTypes);
    },
    weekDays (): Array<ITextValue<string>> {
      return generateArrayOfItemsFromObject(TaskDaysEnum);
    },
    taskRecurrenceTypes (): typeof TaskRecurrenceTypes {
      return TaskRecurrenceTypes;
    }
  },

  watch: {
    'repeatOptions.specificDays': {
      handler (value) {
        if (!value.length) {
          this.repeatOptions.specificDays = [TaskDaysEnum.monday];
        }
      },
      deep: true,
    },
    'repeatOptions.type' (value) {
      if (value === TaskRecurrenceTypes.week) {
        this.repeatOptions.times = 1;
      }
    },
    recurrenceOptions: {
      handler (value) {
        if (value) {
          this.repeatOptions = { ...value };
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    getFirstChar (day: string): string {
      return day.charAt(0).toUpperCase();
    },
    saveHandler () {
      this.$emit('save', { ...this.repeatOptions });
      this.showDialog = false;
    },
  }
});
