









import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import mixins from 'vue-typed-mixins';
import { PropType } from 'vue';
import { ICollection } from '@/bundles/Tasks/interfaces/ITask';
import { IGetAccountRouteHandler } from '@/bundles/App/helpers';

export default mixins(AccountLayoutMixin).extend({
  name: 'CollectionTile',

  props: {
    collection: {
      type: Object as PropType<ICollection>,
      required: true,
    }
  },

  computed: {
    typeName (): string {
      if (this.collection.type === 'savedset') {
        return 'Saved Set';
      }

      return this.collection.type;
    },
    page: function (): string {
      if (this.collection.address === 'comparable') {
        return 'comparables';
      } else if (this.collection.address === 'request') {
        return 'administration-requests';
      } else if (this.collection.address === 'deal') {
        return 'deals';
      }

      return this.collection.address;
    },
    getAccountRouteHandler (): IGetAccountRouteHandler {
      const route = { name: this.collection.type === 'listing' ? 'availability' : this.collection.type, params: { id: this.collection._key } };
      const savedSetRoute = { name: this.page, query: { set: this.collection._key } };

      return this.getAccountRoute(this.collection.type === 'savedset' ? savedSetRoute : route);
    }
  },
})
