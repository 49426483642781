import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import UserLayoutService from '@/bundles/Settings/services/UserLayoutService';
import { UserLayout } from '@/bundles/Settings/models/UserLayout';
import { IUserLayout } from '@/bundles/Settings/interfaces/IUserLayout';

function userLayoutServiceFactory () {
  return new UserLayoutService(
    httpV2,
    new ErrorMapper(),
    new RecordMapper<IUserLayout>(UserLayout),
  );
}

export const userLayoutService = userLayoutServiceFactory();
