import * as popupContentBuilder from '@/bundles/Map/helpers/popupContentBuilder';
import { placeholderPropertyImg } from '@/settings';
import { ITextValue } from '@/types/common';
import { getFormattedAPN } from '@/bundles/Property/helpers/apn/getFormattedAPN';

const sourceType = 'property';

export const generateMapFeatures = (properties, formatters) => {
  return properties.filter(property => property.coordinates.lat && property.coordinates.long)
    .map(property => {
      const ceilingHeight = property.building.ceiling_height;
      let officeSize = formatters.buildingSize && property.size?.office?.total ? formatters.buildingSize(property.size.office.total) : property.size?.office?.total;

      if (property.bts === true) officeSize = 'BTS';

      return {
        id: property._key || property.id,
        number: property.number,
        place_name: `${
          property.address?.postal || property.home_address?.home_postal
        }, ${property.address?.street || property.home_address?.home_street}, ${
          property.address?.city || property.home_address?.home_city
        }, ${property.address?.state || property.home_address?.home_state}, ${
          property.address?.country || ''
        }`,
        text: property.name,
        center: [property.coordinates.long, property.coordinates.lat],
        place_type: 'property',
        place_photo: property.photo || property.img || placeholderPropertyImg,
        type: 'Feature',
        geometry: {
          type: 'Point',
          interpolated: true,
          coordinates: [property.coordinates.long, property.coordinates.lat],
        },
        properties: {
          number: property.number,
          place_name: `${
            property.address?.postal || property.home_address?.home_postal
          }, ${property.address?.street || property.home_address?.home_street}, ${
            property.address?.city || property.home_address?.home_city
          }, ${property.address?.state || property.home_address?.home_state}, ${
            property.address?.country || ''
          }`,
          text: property.name,
          image: property.photo?.url || property.img || placeholderPropertyImg,
          square_feet: property.size.building.total,
          address: property.address,
          slab_psi: property.building.slab_psi,
          type: property.type,
          size: property.size,
          construction_status: property.construction_status,
          year_built: property.year_built,
          building: property.building,
          apn_number: getFormattedAPN(property.site.apn_number),
          market: property.market,
          county: property.address.county,
        },
        image: property.photo,
        square_feet: formatters.buildingSize && property.size?.building?.total ? formatters.buildingSize(property.size.building.total) : property.size?.building?.total,
        total_acres: formatters.landSize && property.size?.land?.total ? formatters.landSize(property.size.land.total) : property.size?.land?.total,
        office_size: officeSize,
        clear_height: !ceilingHeight.min && !ceilingHeight.max ? 'N/A' : `${formatters.buildingDimension(ceilingHeight.min) || 'N/A'} - ${formatters.buildingDimension(ceilingHeight.max) || 'N/A'}`,
        address: property.address,
        slab_thickness: property.building?.floor_thickness ? formatters.buildingDimension(property.building.floor_thickness) : 'N/A',
        slab_psi: property.building.slab_psi,
      }
  })
};

const getBodyContent = (property: any) => {
  const content: ITextValue<string>[] = [
    { text: 'Property Type', value: property.properties.type },
    { text: 'Building Size', value: property.square_feet },
    { text: 'Land Size', value: property.total_acres },
  ];

  if (property.properties.type === 'Industrial') {
    content.push({ text: 'Office Size', value: property.office_size });
  }

  content.push({ text: 'Construction Status', value: property.properties.construction_status });
  content.push({ text: 'Year Built', value: property.properties.year_built });
  content.push({ text: 'Clear Height (Min-Max)', value: property.clear_height });

  return content;
}

export const getPropertyPopupContent = (property: any) => {
  const container = popupContentBuilder.getContainer();
  const imageSection = popupContentBuilder.getImageSection(property.properties.image, property.text || 'Property Image');
  const idSection = popupContentBuilder.getIdSection(sourceType, property.id);
  const titleSection = popupContentBuilder.getTitleSection(sourceType, property.id, property.text);
  const addressSection = popupContentBuilder.getAddressSection(property.address);
  const bodySection = popupContentBuilder.getBodySection(getBodyContent(property));
  const buildingDetailsSection = popupContentBuilder.getBuildingDetailsSection({ addressSection, imageSection });

  container.append(buildingDetailsSection, idSection, titleSection, bodySection);

  return container;
};
