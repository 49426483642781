import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { UploadToMapper } from '@/bundles/Upload/mappers/UploadToMapper';
import { Upload } from '@/bundles/Upload/models/Upload';
import { IBaseService } from '@/bundles/App/interfaces/IBaseService';
import { Nullable } from '@/utils/types';
import { generateParams } from '@/utils/helpers';
import { UploadMultiplySizesFile } from '@/bundles/Upload/models/UploadMultiplySizesFile';

export default class UploadService implements IBaseService<UploadToMapper> {
  transport: HttpTransport;
  errorMapper: ErrorMapper;
  recordMapper: UploadToMapper;

  constructor ({ transport, errorMapper, recordMapper }: IBaseService<UploadToMapper>) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.recordMapper = recordMapper;
  }

  async upload ({ record, key, files, params }: {
      record: Nullable<string>;
      key: Nullable<string | number>;
      files: File[];
      params?: Array<{ name: string; value: any }>;
  }): Promise<Upload[]> {
    try {
      const formData = new FormData();
      files.forEach((file, index) => formData.append(`file-${index}`, file, file.name));

      const paramsValues = [
        { name: 'record', value: record },
        { name: 'key', value: key },
        ...(params || [])
      ];
      const paramsArr = paramsValues.filter(param => param.value || param.value === 0);

      const response = await this.transport.post(`digitalOcean/${generateParams(paramsArr)}`, formData);

      return response.map(item => this.recordMapper.mapToModel(item));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async uploadMultiplySizes ({ record, key, files }: { record: Nullable<string>, key: Nullable<string | number>, files: File[] }): Promise<UploadMultiplySizesFile[]> {
    try {
      const formData = new FormData();
      files.forEach((file, index) => formData.append(`file-${index}`, file, file.name));

      const paramsValues = [
        { name: 'record', value: record },
        { name: 'key', value: key },
        { name: 'is_multiple_size', value: true }
      ];
      const paramsArr = paramsValues.filter(param => param.value || param.value === 0);

      const response = await this.transport.post(`digitalOcean/${generateParams(paramsArr)}`, formData);

      return response.map(item => new UploadMultiplySizesFile(item));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
