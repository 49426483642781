










































































import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { notificationService } from '@/bundles/Notification/factories/notificationServiceFactory';
import { INotification } from '@/bundles/Notification/interfaces/INotification';
import { RootActions } from '@/store/types/action-types';
import { SocketEventTypesEnum } from '@/helpers/sockets/event-types';
import { socketInstance } from '@/plugins/socket/socket';

import NotificationMenu from '@/bundles/Panels/components/NotificationMenu.vue';
import ProfileMenu from '@/bundles/Panels/components/ProfileMenu.vue';

interface IProfileMenuWrapper {
  showMenu: boolean;
  notifications: INotification[];
}

export default mixins(AccountLayoutMixin).extend({
  name: 'ProfileMenuWrapper',

  components: { ProfileMenu, NotificationMenu },

  data: (): IProfileMenuWrapper => ({
    showMenu: false,
    notifications: [],
  }),

  created () {
    this.getNotifications();
    socketInstance?.on(SocketEventTypesEnum.REFRESH_NOTIFICATIONS, this.getNotifications);
  },

  beforeDestroy () {
    socketInstance?.off(SocketEventTypesEnum.REFRESH_NOTIFICATIONS, this.getNotifications);
  },

  methods: {
    ...mapActions({
      logout: RootActions.LOG_OUT
    }),
    async getNotifications () {
      try {
        this.notifications = await notificationService.getMyNotifications();
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    logoutHandler: async function () {
      try {
        await this.logout();
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    updateNotificationHandler (notification: INotification) {
      this.notifications = this.notifications.map(tempNotification => {
        if (tempNotification.id === notification.id) {
          return notification;
        }

        return tempNotification;
      });
    },
    clearNotificationHandler (id: number) {
      this.notifications = this.notifications.filter((item) => item.id !== id);
    },
    clearAllNotificationsHandler () {
      this.notifications = [];
    }
  }
})
