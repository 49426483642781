<template>
  <v-form
    ref="tracker-form"
    class="tracker-wrapper-form"
    @submit.prevent="submitEventHandler"
  >
    <v-row>
      <v-col class="d-flex align-center justify-space-between">
        <v-btn-toggle v-model="tempRecord.type" mandatory>
          <v-btn value="feature">
            <v-icon left>mdi-star</v-icon>New Feature
          </v-btn>
          <v-btn value="bug"> <v-icon left>mdi-bug</v-icon> Bug </v-btn>
        </v-btn-toggle>
        <v-btn height="47" @click="$emit('change:view')">
          <v-icon small class="mr-1">mdi-arrow-left</v-icon>
          Back to list
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-text-field
          v-model="tempRecord.name"
          :rules="[(v) => !!v || 'Field required']"
          :label="labelName"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="tempRecord.description"
          :rules="[(v) => !!v || 'Field required']"
          :label="labelDescription"
          counter="600"
        />
      </v-col>
      <v-col cols="12" md="12" class="py-0">
        <Upload
          v-model="uploadScreenshotDialog"
          :options="uploadOptions"
          source-type="bug-tracker"
          @fileUploaded="uploadSuccess"
        >
          <v-btn
            class="mb-2"
            block
            height="60"
            color="primary"
            outlined
            @click="uploadScreenshotDialog = true"
          >
            Upload File(s)
          </v-btn>
        </Upload>
      </v-col>
      <v-col cols="12" md="12" class="py-0">
        <v-row v-if="tempRecord.screenshots.length" class="mt-2">
          <v-col v-for="screenshot in tempRecord.screenshots" :key="screenshot.url" cols="6">
            <v-card class="attachment-card" outlined>
              <embed
                v-if="screenshot.mimetype === fileMimetypesEnum.pdf"
                :src="getPDFPreviewUrl(screenshot.url)"
                width="100%"
                height="300"
                type="application/pdf"
              >
              <v-img
                v-else
                :src="screenshot.url"
                height="300px"
                contain
                class="cursor-pointer"
                @click="previewImage(screenshot.url)"
              />
              <v-btn
                small
                icon
                outlined
                class="attachment-card__close-btn"
                @click="removeAttachment(screenshot)"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" class="d-flex align-center py-0 mb-2">
        <v-spacer />
        <v-btn class="mr-2" @click="$emit('change:view')">
          Cancel
        </v-btn>
        <div class="d-flex justify-end">
          <v-btn color="primary" :loading="saveLoading" type="submit"> Save </v-btn>
        </div>
      </v-col>
    </v-row>
    <PhotoViewer v-model="previewDialog" :image-url="imageUrlToPreview" />
  </v-form>
</template>

<script>
import { FileMimetypesEnum } from '@/bundles/Upload/enums/fileMimetypes';
import { getPDFPreviewUrl } from '@/bundles/Upload/helpers';

import Upload from '@/bundles/Upload/components/Upload';
import PhotoViewer from '@/bundles/App/components/dialogs/PhotoViewer.vue';

export default {
  name: 'TrackerWrapperForm',

  components: {
    PhotoViewer,
    Upload,
  },

  props: {
    saveLoading: Boolean
  },

  data: () => ({
    tempRecord: {
      type: 'feature',
      name: '',
      screenshots: [],
      description: '',
      url: ''
    },
    uploadScreenshotDialog: false,
    uploadOptions: {
      accept: `${FileMimetypesEnum.image}, ${FileMimetypesEnum.pdf}`,
      multiple: true,
    },
    fileMimetypesEnum: FileMimetypesEnum,
    getPDFPreviewUrl,
    imageUrlToPreview: null,
    previewDialog: false,
  }),

  computed: {
    labelName () {
      switch (this.tempRecord.type) {
        case 'feature':
          return 'What new feature would you like to see?'
        default:
          return 'What bug would you like to report?'
      }
    },
    labelDescription () {
      switch (this.tempRecord.type) {
        case 'feature':
          return 'Please explain why this feature would benefit you and your business'
        default:
          return 'Please share any details which could assist us in identifying the issue'
      }
    },
  },

  watch: {
    saveLoading (value) {
      if (!value) {
        this.resetForm();
      }
    },
    previewDialog (value) {
      if (!value) {
        this.imageUrlToPreview = null;
      }
    }
  },

  created () {
    this.$eventBus.$on('fill-bug-report', this.fillBugReport)
  },

  beforeDestroy () {
    this.$eventBus.$off('fill-bug-report', this.fillBugReport)
  },

  methods: {
    /**
     * Handler for upload success event
     * @param {Object} result
     * */
    uploadSuccess: function ({ files }) {
      this.tempRecord.screenshots.push(...files);
      this.uploadScreenshotDialog = false;
    },
    /**
     * Handler for submit form event
     * */
    submitEventHandler: function () {
      const $form = this.$refs['tracker-form']
      if ($form.validate()) {
        const payload = Object.assign({}, this.tempRecord, {
          screenshot: this.tempRecord.screenshots,
          description: `${this.tempRecord.description}${this.tempRecord.url ? `; URL: ${this.tempRecord.url}` : ''}`
        })
        this.$emit('send', payload)
      }
    },
    /**
     * Reset form method
     * */
    resetForm: function () {
      const $form = this.$refs['tracker-form']
      this.tempRecord.screenshots = [];
      this.tempRecord.url = ''
      $form.reset()
      $form.resetValidation()
    },
    fillBugReport: function ({ message, url }) {
      this.tempRecord.type = 'bug';
      this.tempRecord.name = 'Report from notification'
      this.tempRecord.description = message;
      this.tempRecord.url = url;
    },
    removeAttachment: function (screenshot) {
      this.tempRecord.screenshots = this.tempRecord.screenshots.filter(item => item.url !== screenshot.url);
    },
    previewImage: function (url) {
      this.imageUrlToPreview = url;
      this.previewDialog = true;
    },
  },
}
</script>
