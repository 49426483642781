




import Vue, { PropType } from 'vue'
import Quill from 'quill';
import { Nullable } from '@/utils/types';

interface IQuillEditor {
  quill: Nullable<Quill>;
}

let Embed: any = Quill.import('blots/embed');

class Breaker extends Embed {
  static tagName = 'br';
  static blotName = 'breaker';
}

Quill.register(Breaker);
Quill.debug('error');

export default Vue.extend({
  name: 'QuillEditor',

  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    toolbarOptions: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: Boolean
  },

  data: (): IQuillEditor => ({
    quill: null,
  }),

  watch: {
    value (val) {
      if (!this.quill) {
        return;
      }

      if (val !== this.quill.root.innerHTML && !this.quill.hasFocus()) {
        this.setValue(val);
      }
    },
  },

  mounted () {
    this.initQuill();
  },

  beforeDestroy () {
    this.quill?.off('text-change', this.onInput);
  },

  methods: {
    initQuill () {
      this.quill = new Quill(`#${this.id}`, {
        modules: {
          toolbar: this.toolbarOptions,
          keyboard: {
            bindings: {
              linebreak: {
                key: 'Enter',
                shiftKey: true,
                handler: function (range: {index: number}) {
                  (this as any).quill.insertEmbed(range.index, 'breaker', true, Quill.sources.USER);
                  (this as any).quill.setSelection(range.index + 1, Quill.sources.SILENT);
                  return false;
                },
              }
            },
          },
        },
        placeholder: this.placeholder,
        theme: 'snow',
        readOnly: this.readonly
      });

      if (this.value) {
        this.setValue(this.value);
      }

      this.quill.on('text-change', this.onInput);
    },
    onInput () {
      this.$emit('input', this.quill?.getSemanticHTML());
    },
    setValue (value: string) {
      if (this.quill) {
        const delta = this.quill.clipboard.convert({ html: value });
        this.quill.setContents(delta, 'silent');
      }
    },
  }
})
