import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { IUserReturn } from '@/bundles/Auth/interfaces/IUserReturn';
import Account from '@/bundles/Account/models/Account';
import { RESET_ALL_FILTERS } from '@/store/modules/filters/filters-types';
import { RootActions } from '@/store/types/action-types';
import { NotificationMutations, RootMutations } from '@/store/types/mutation-types';
import { TOGGLE_SPLASH_SCREEN } from '@/store/action-types';

const UserAccountsMixin = Vue.extend({
  computed: {
    ...mapState({
      user: (state: any): IUserReturn => state.user || state.temporaryUserInfo,
      activeAccount: (state: any): Account|null => state.activeAccount,
      isMultipleAccountAuth: (state: any): boolean => state.isMultipleAccountAuth,
    }),
    accounts: function (): Account[] {
      return (this.user?.account || []).sort((a, b) => +(a.name > b.name) || -1);
    }
  },

  methods: {
    ...mapMutations('Filters', {
      resetFilters: RESET_ALL_FILTERS,
    }),
    ...mapActions({
      accountLogin: RootActions.MULTI_ACCOUNT_SIGN_IN,
    }),
    ...mapMutations({
      clearTemporary: RootMutations.CLEAR_TEMPORARY_INFO,
      toggleScreen: TOGGLE_SPLASH_SCREEN,
    }),
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION
    }),
  }
});

export { UserAccountsMixin };
