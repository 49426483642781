import Vue from 'vue'
import Vuex from 'vuex'
import instance from '../services/instance'
import router from '../router/router'
import createPersistedState from 'vuex-persistedstate'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import WorkOrder from './modules/work-order'
import Members from './modules/members'
import Member from './modules/members/index.js'
import projectModule from './modules/project'
import Availability from './modules/availability'
import Pipelines from './modules/pipelines'
import Filters from './modules/filters'
import Company from './modules/company'
import Contact from './modules/contact'
import Occupier from './modules/occupier'
import Requirement from './modules/requirement'
import Property from './modules/property'
import Spatial from './modules/spatial'
import Settings from './modules/settings'
import SavedSets from './modules/savedSet'
import Admin from './modules/admin'
import Deals from './modules/deals'
import Views from './modules/views'
import ActivityFilters from './modules/activityFilters'
import Notifications from './modules/notifications'
import MeasurementUnits from './modules/measurementUnits';
import FiltersData from './modules/filtersData/index';
import Subscriptions from './modules/subscriptions/index';
import TableOptions from './modules/tableOptions';
import Task from './modules/task';
import Lists from './modules/lists';
import Comparable from './modules/comparable';

import actions from './actions';
import mutations from './mutations';
import { getters } from './getters';
import { getInitialState } from './getInitialState';
import { StoreModulesNamesEnum } from '@/store/StoreModulesNamesEnum';
import { authService } from '@/bundles/Auth/factory/authServiceFactory';
import { RootActions } from '@/store/types/action-types';
import Cookies from 'js-cookie';
import { RootMutations } from '@/store/types/mutation-types';
import { TOGGLE_SPLASH_SCREEN } from '@/store/action-types';
import { RESET_ALL_FILTERS } from '@/store/modules/filters/filters-types';

const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    WorkOrder,
    Members,
    projectModule,
    Availability,
    Pipelines,
    Member,
    Filters,
    Company,
    Contact,
    Occupier,
    Requirement,
    Property,
    Spatial,
    Settings,
    SavedSets,
    Admin,
    Comparable,
    Deals,
    Views,
    ActivityFilters,
    [StoreModulesNamesEnum.Notifications]: Notifications,
    [StoreModulesNamesEnum.MeasurementUnits]: MeasurementUnits,
    [StoreModulesNamesEnum.FiltersData]: FiltersData,
    Subscriptions,
    TableOptions,
    Task,
    Lists,
  },
  state: getInitialState(),
  plugins: [
    createPersistedState({
      paths: [
        'idToken',
        'userId',
        'contactId',
        'userLogo',
        'userName',
        'roles',
        'userEmail',
        'visitorId'
      ],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, typeof value === 'object' ? JSON.stringify(value) : value, { secure: true, path: '/', sameSite: 'strict', expires: 5 }),
        removeItem: key => Cookies.remove(key, { path: '/', secure: true, sameSite: 'strict' })
      },
    }),
    createPersistedState({
      paths: [
        'color',
        'company',
        'companyIcon',
        'companyLogo',
        'availabilityView',
        'refreshToken',
        'userSettings',
        'appVersion',
        'isMultipleAccountAuth',
        'temporaryAuthToken',
        'temporaryUserInfo',
        'activeAccount',
        'Filters',
        'nav',
        'Views',
        'Subscriptions',
        'TableOptions',
        'Task',
        'Lists',
      ],
    }),
    logrocketPlugin,
  ],
  mutations,
  actions: Object.assign(
    {
      signup ({ commit, dispatch }, authData) {
        instance
          .post('/member', {
            firstName: authData.firstName,
            lastName: authData.lastName,
            email: authData.email,
            password: authData.password,
          })
          .then((res) => {
            commit(RootMutations.AUTH_USER, {
              token: res.data.idToken,
              userId: res.data.localId,
            })
            dispatch('storeUser', authData)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loginAdmin ({ commit }, authData) {
        return new Promise((resolve, reject) => {
          instance
            .post('/oauth/jwttoken', authData)
            .then((res) => {
              if (res.data.access_token.includes('Wrong')) {
                reject(res.data.access_token)
              } else {
                commit(RootMutations.SET_ADMIN_TOKEN, res.data.access_token)
                resolve()
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      async [RootActions.LOG_OUT] ({ commit, state }) {
        try {
          if (state.idToken) {
            await authService.logOut();
          }

          commit(RootMutations.CLEAR_AUTH_DATA);
          commit('Filters/' + RESET_ALL_FILTERS, null, { root: true });
          localStorage.clear();
          sessionStorage.clear();
          await router.push({ name: 'welcome' });
          commit(TOGGLE_SPLASH_SCREEN, false);
        } catch (e) {
          throw e;
        }
      },
      async [RootActions.LOG_OUT_SILENTLY] ({ commit, state }) {
        try {
          if (state.idToken) {
            await authService.logOut();
          }

          commit(RootMutations.CLEAR_AUTH_DATA);
          localStorage.clear();
        } catch (error) {
          console.error(error)
        }
      },
      storeUser ({ state }, userData) {
        if (!state.idToken) {
          return
        }
        instance
          .post('/users.json' + '?auth=' + state.idToken, userData)
          .catch((error) => {
            console.log(error)
          })
      },
      fetchUser ({ state }) {
        if (!state.idToken) {
          return
        }
        instance
          .get('/member/' + state.userId, {
            headers: {
              Authorization: 'Bearer ' + state.idToken,
            },
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getBrokers (state) {
        instance
          .get('brokers', {
            headers: {
              Authorization: 'Bearer ' + state.idToken,
            },
          })
          .then(function (response) {
            return response.data.brokers
          })
          .catch(function (error) {
            console.log(error)
          })
      },
    },
    actions
  ),
  getters
});
