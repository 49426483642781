import * as constants from './members-types'
import instance from '@/services/instance'

export default {
  [constants.INVITE_MEMBER] ({ commit, rootState }, member) {
    return new Promise((resolve, reject) => {
      instance
        .post('/member/invite', member, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_MEMBER_BY_KEY] ({ rootState }, key) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/member/${key}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_MEMBER] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      console.log('key: ' + key)
      console.log('payload')
      console.log(payload)
      instance
        .put(`/member/${key}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let { data } = response
          resolve(data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.ADD_COMPENSATION] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/member/${key}/compensation`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.ARCHIVE_COMPENSATION] ({ rootState }, { key, id }) {
    return new Promise((resolve, reject) => {
      instance
        .put(
          `/member/${key}/compensation`,
          { _key: key, _id: id, active: false },
          {
            headers: {
              Authorization: 'Bearer ' + rootState.idToken,
            },
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_COMPENSATION_TYPE] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/member/${key}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.CREATE_MEMBER] ({ rootState }, member) {
    console.log('Payload: ', member)
    return new Promise((resolve, reject) => {
      instance
        .post('/member', member, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          console.log('Result: ', result)
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
