import mutations from './mutations';
import getters from './getters';
import { getInitialState } from '@/store/modules/lists/helper';

const initialState = getInitialState();

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
}
