import { formatBuildingDimensionToHumanReadable, formatSpaceToHumanReadable } from '@/utils/unitOfMeasureFilters';
import { formatRawNumber } from '@/utils/filters';
import { BuildingDimensionMeasurementUnits } from '@/bundles/MeasurementUnits/types';

export const formatSpaceSizeLabel = (value: number | string, { from, to }: { from: string, to: string }, { cutZero }: {
  cutZero?: boolean;
} = {}) => {
  return formatSpaceToHumanReadable(formatRawNumber(value) as number, {
    from,
    to
  }, { cutZero });
};

export const formatBuildingDimensionLabel = (value: number | string, { from, to }: { from: string, to: string }) => {
  return formatBuildingDimensionToHumanReadable(formatRawNumber(value) as number, {
    from,
    to
  });
};

export const buildingDimensionLabels = {
  [BuildingDimensionMeasurementUnits.inches]: {
    unit1: '\'',
    unit2: '"',
  },
  [BuildingDimensionMeasurementUnits.cm]: {
    unit1: 'm',
    unit2: 'cm',
  }
};
