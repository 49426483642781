import { IHeader } from '@/bundles/BaseTable/interfaces';

const defaultHeaders: IHeader[] = [
  { text: 'Title', align: 'left', value: 'title' },
  { text: 'Due Date', value: 'date_due' },
  { text: 'Date Created', value: 'date_created' },
  { text: 'Assigned By', value: 'assigned_by.name' },
  { text: 'Assigned To', value: 'assigned_to.name' },
  { text: 'Type', value: 'type' },
  { text: 'Status', value: 'status' },
  { text: 'Related To', value: '' },
];

export const getTasksTableHeaders = (): IHeader[] => {
  return defaultHeaders;
}
