import { MultiAuthReturn } from '@/bundles/Auth/models/MultiAuthReturn';

export default class MultiAuthReturnToMapper {
  mapToModel (data: any): MultiAuthReturn {
    const multiAuth = new MultiAuthReturn();
    multiAuth.access_token = data.access_token;
    multiAuth.user = data.user;
    return multiAuth;
  }
}
