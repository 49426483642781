














import Vue, { PropType } from 'vue';
import { Nullable } from '@/utils/types';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

import Label from '@/bundles/Common/components/Label.vue';
import BuildingDimensionLabel from './BuildingDimensionLabel.vue';

export default Vue.extend({
  name: 'BuildingDimensionLabels',

  components: {
    Label,
    BuildingDimensionLabel
  },

  props: {
    title: {
      type: String,
      default: null
    },
    value1: {
      type: [String, Number] as PropType<Nullable<string | number>>,
      default: null
    },
    value2: {
      type: [String, Number] as PropType<Nullable<string | number>>,
      default: null
    },
    separator: {
      type: String,
      default: '-'
    },
    hideIfNoData: Boolean
  },

  methods: {
    isValuePresent
  }
});
