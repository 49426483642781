import { ITextValue } from '@/types/common';

export enum PropertyCenterTypeEnum {
  COMMUNITY_CENTER = 'community_center',
  HIGH_STREET_RETAIL = 'high_street_retail',
  LIFESTYLE = 'lifestyle',
  NEIGHBORHOOD_CENTER = 'neighborhood_center',
  OUTLET_CENTER = 'outlet_center',
  POWER_CENTER = 'power_center',
  REGIONAL_CENTER = 'regional_center',
  STRIP_CONVENIENCE = 'strip_convenience',
  SUPER_REGIONAL_CENTER = 'super_regional_center',
  FESTIVAL_CENTER = 'festival_center',
  GROCERY_ANCHORED = 'grocery_anchored',
  INDOOR_SHOPPING_CENTER = 'indoor_shopping_center'
}

export const PropertyCenterTypeEnumLabels = {
  [PropertyCenterTypeEnum.COMMUNITY_CENTER]: 'Community Center',
  [PropertyCenterTypeEnum.GROCERY_ANCHORED]: 'Grocery Anchored',
  [PropertyCenterTypeEnum.HIGH_STREET_RETAIL]: 'High Street Retail',
  [PropertyCenterTypeEnum.INDOOR_SHOPPING_CENTER]: 'Indoor Shopping Center',
  [PropertyCenterTypeEnum.LIFESTYLE]: 'Lifestyle/Fashion/Specialty Center',
  [PropertyCenterTypeEnum.NEIGHBORHOOD_CENTER]: 'Neighborhood Center',
  [PropertyCenterTypeEnum.OUTLET_CENTER]: 'Outlet Center',
  [PropertyCenterTypeEnum.POWER_CENTER]: 'Power Center',
  [PropertyCenterTypeEnum.REGIONAL_CENTER]: 'Regional Center',
  [PropertyCenterTypeEnum.STRIP_CONVENIENCE]: 'Strip/Convenience',
  [PropertyCenterTypeEnum.SUPER_REGIONAL_CENTER]: 'Super Regional Center',
  [PropertyCenterTypeEnum.FESTIVAL_CENTER]: 'Theme/Festival Center'
}

export const getPropertyCenterTypeTextValues = (): ITextValue<PropertyCenterTypeEnum>[] => Object.keys(PropertyCenterTypeEnumLabels).map((key) => ({
  text: PropertyCenterTypeEnumLabels[key],
  value: key as PropertyCenterTypeEnum
}));
