import { render, staticRenderFns } from "./PropertyTypeChip.vue?vue&type=template&id=6f4aeaf2&functional=true&"
import script from "./PropertyTypeChip.vue?vue&type=script&lang=ts&"
export * from "./PropertyTypeChip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports