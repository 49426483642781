import * as constants from './sets-types'
import instance from '../../../services/instance'

export default {
  [constants.GET_CONTACT_SAVED_SETS] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/contact/${id}/savedSets`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let sets = []
          if (response.data.result.length) {
            sets = response.data.result
          }
          resolve(sets)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_SMART_SET] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .get('/filters/' + id, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_ALL_SMART_SETS] ({ rootState }, type) {
    return new Promise((resolve, reject) => {
      instance
        .get('/filters/list?type=' + type, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data.results)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_MY_SMART_SETS] ({ rootState }, type) {
    return new Promise((resolve, reject) => {
      instance
        .get('/filters/myList?type=' + type, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data.results)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.SAVE_SMART_SET] ({ rootState }, { name, type, fields }) {
    const payload = {
      name: name,
      type: type,
      fields: fields,
    }
    return new Promise((resolve, reject) => {
      instance
        .post('/filters', payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_SMART_SET] ({ rootState }, { id, type, name, fields }) {
    const payload = {
      name: name,
      type: type,
      fields: fields,
    }
    return new Promise((resolve, reject) => {
      instance
        .put('/filters/' + id, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.DELETE_SMART_SET] ({ rootState }, id) {
    return new Promise((resolve, reject) => {
      instance
        .delete('/filters/' + id, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
