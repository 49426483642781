import * as constants from './spatial-types'

export default {
  [constants.SET_SQUARE_UNITS] (state, units) {
    state.squareUnits = units
  },
  [constants.SET_DISTANCE_UNITS] (state, units) {
    state.distanceUnits = units
  },
  [constants.SET_ROUTE_UNITS] (state, units) {
    state.routeUnits = units
  },
  [constants.SET_POLYGON_AREA] (state, area) {
    state.polygonArea = area
  },
  [constants.SET_DISTANCE_LENGTH] (state, length) {
    state.distanceLinestring = length
  },
  [constants.SET_LAYERS] (state, layers) {
    state.layers = layers
  },
  [constants.SET_LAYERS_NEW] (state, layers) {
    state.layersNew = layers
  },
  [constants.UPDATE_LAYERS] (state, { key, layers }) {
    state.layersNew[key] = [...layers];
  },
  [constants.SET_CENTER] (state, { map, center }) {
    state[map].center = center
  },
  [constants.SET_ZOOM] (state, { map, zoom }) {
    state[map].zoom = zoom
  },
  [constants.SET_STYLE] (state, { map, style }) {
    state[map].style = style
  },
  [constants.SET_FILTER] (state, { filter, value }) {
    state.filters[filter] = Object.assign({}, state.filters[filter], value)
  },
  // TODO sychdo: this is not being used, need to be removed
  [constants.SET_FEATURES] (state, features) {
    state.tempFeatures = features
  },
  [constants.CLEAR_FEATURES] (state) {
    state.tempFeatures = []
  },
  [constants.SET_FEATURES_TYPE] (state, type) {
    state.tempFeaturesType = type
  },
  [constants.SET_MAP_VIEW] (state, view) {
    state.mapView = view
  },
  [constants.SET_MAP_SHOW] (state, view) {
    state.mapShow = view
  },
  [constants.SET_STANDARD_LAYERS] (state, data) {
    state.standardLayers = data;
  },
  [constants.SET_PITCH] (state, data) {
    state.pitch = data;
  }
}
