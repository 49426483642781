import { AuthReturn } from '@/bundles/Auth/models/AuthReturn';

export default class AuthReturnToMapper {
  mapToModel (data: any): AuthReturn {
    const authReturn = new AuthReturn();
    authReturn.access_token = data.access_token;
    authReturn.client_id = data.client_id;
    authReturn.scope = data.scope;
    authReturn.expires_in = data.expires_in;
    authReturn.token_type = data.token_type;
    return authReturn;
  }
}
