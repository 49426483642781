import * as constants from './property-types'
import instance from '../../../services/instance'
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();

export default {
  [constants.GET_PROPERTIES_FULL_LIST] ({ commit, rootState }, params = null) {
    let url = params ? `/property/list?${params}` : '/property/list'
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((res) => {
          let { result } = res.data
          resolve(result)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  async [constants.GET_PROPERTY_BY_KEY] ({ rootState }, key) {
    try {
      const { data } = await instance.get(`/property/${key}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
      });

      return data.property;
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  [constants.GET_PROPERTY_PHOTOS] ({ rootState }, key) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/property/photos/${key}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_PROPERTY_CONTACTS] ({ rootState }, key) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/property/contacts/${key}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let { data } = response
          console.log(data)
          resolve(data)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_PROPERTY] ({ rootState }, { key, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/property/${key}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
