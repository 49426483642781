
import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { formatSpaceSizeLabel } from '@/bundles/MeasurementUnits/helpers';
import store from '@/store/store';
import { conversionToSFFactors } from '@/utils/unitOfMeasureFilters';
import { SpaceMeasurementUnits } from '@/bundles/MeasurementUnits/types';
import { formatRawNumber } from '@/utils/filters';

export default Vue.extend({
  name: 'LandSizeLabel',

  functional: true,

  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    showSquareFeet: Boolean,
    cutZero: Boolean
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const value = ctx.props.value;
    const className = `${ctx.data.staticClass || ''} ${ctx.data.class || ''}`.trim();

    if (!value) {
      return h('span', { class: `${className} na`.trim() }, 'N/A');
    }

    const defaultLandSizeUnit = store.state.MeasurementUnits.landSize;
    const selectedLandSizeUnit = store.state.accountSettings?.properties?.land_size_type?.value;

    const label = formatSpaceSizeLabel(value, {
      from: defaultLandSizeUnit,
      to: selectedLandSizeUnit
    }, { cutZero: ctx.props.cutZero });

    if (selectedLandSizeUnit === SpaceMeasurementUnits.sf && formatRawNumber(value)! > 700000) { // more than 700,000 SF
      return h('span', {
        ...ctx.data,
        class: `${className} white-space--normal`.trim(),
      }, `${formatSpaceSizeLabel(value, { // Show both units (Acres first)
        from: defaultLandSizeUnit,
        to: SpaceMeasurementUnits.acres
      }, { cutZero: ctx.props.cutZero })} (${label})`);
    }

    if (!ctx.props.showSquareFeet || selectedLandSizeUnit === SpaceMeasurementUnits.sf) {
      return h('span', { ...ctx.data, class: className || undefined }, label);
    }

    if (formatRawNumber(value)! < conversionToSFFactors[SpaceMeasurementUnits.acres] * 22) { // less than 1 Acre
      return h('span', { ...ctx.data, class: className || undefined }, `${label} (${formatSpaceSizeLabel(value, { // Show both units
        from: defaultLandSizeUnit,
        to: defaultLandSizeUnit
      }, { cutZero: ctx.props.cutZero })})`);
    }

    return h('span', label);
  }
});
