import { AdminStoreDataListEnum } from '@/store/modules/admin/types/AdminStoreDataListEnum';

interface IGetListByCategory {
  items: any[];
}
// | string should be removed, when AdminStoreDataListCategoryEnum will be used in the all places
const getListByCategory = (state: any) => (category: AdminStoreDataListEnum | string): IGetListByCategory => state[category];

export default {
  getListByCategory,
}
