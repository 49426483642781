import store from '@/store/store'
import { eventBus } from '@/eventbus/eventbus'
import { RootActions } from '@/store/types/action-types';
import { axiosQueue } from '@/bundles/Auth/services/AxiosQueue';
import axios from 'axios';
import { REQUEST_CANCELLED_MESSAGE } from '@/bundles/Http/mappers/ErrorMapper';

export const resetTokenAndReattempRequest = async (error) => {
  try {
    const skipRefreshTokenUrls = ['/compensation']
    const permissionsRegexp = /availability|comparable|occupier|project\/[0-9]+\/record/;

    if (axios.isCancel(error)) {
      throw Error(REQUEST_CANCELLED_MESSAGE);
    }
    if (skipRefreshTokenUrls.some(url => error.response?.request?.responseURL?.includes(url)) && error.response?.status === 401) {
      throw error
    }
    if (error.headers?.cached) {
      return error
    }
    if (permissionsRegexp.test(error.response?.request?.responseURL) && error?.response?.status === 403) {
      return await Promise.reject(error);
    }

    const { response: errorResponse } = error
    if (errorResponse.status === 401) {
      return new Promise((resolve, reject) => axiosQueue.addToQueue(errorResponse.config, resolve, reject));
    } else if (errorResponse.status === 403) {
      await store.dispatch(RootActions.LOG_OUT_SILENTLY);
      eventBus.$emit('showSignIn', { preventRedirect: true })
      return await Promise.reject(error)
    } else {
      return await Promise.reject(error)
    }
  } catch (e) {
    // eslint-disable-next-line no-return-await
    return await Promise.reject(e)
  }
}
