export const SET_SQUARE_UNITS = 'SET_SQUARE_UNITS'
export const SET_DISTANCE_UNITS = 'SET_DISTANCE_UNITS'
export const SET_ROUTE_UNITS = 'SET_ROUTE_UNITS'
export const SET_POLYGON_AREA = 'SET_POLYGON_AREA'
export const SET_DISTANCE_LENGTH = 'SET_DISTANCE_LENGTH'
export const GET_LAYERS = 'GET_LAYERS'
export const SET_LAYERS = 'SET_LAYERS'
export const GET_LAYERS_NEW = 'GET_LAYERS_NEW'
export const SET_LAYERS_NEW = 'SET_LAYERS_NEW'
export const SET_CENTER = 'SET_CENTER'
export const SET_ZOOM = 'SET_ZOOM'
export const SET_STYLE = 'SET_STYLE'
export const SET_FILTER = 'SET_FILTER'
export const SET_FEATURES = 'SET_FEATURES'
export const CLEAR_FEATURES = 'CLEAR_FEATURES'
export const SET_FEATURES_TYPE = 'SET_FEATURES_TYPE'
export const SET_MAP_VIEW = 'SET_MAP_VIEW'
export const SET_MAP_SHOW = 'SET_MAP_SHOW'
export const SET_STANDARD_LAYERS = 'SET_STANDARD_LAYERS';
export const SET_PITCH = 'SET_PITCH';
export const UPDATE_LAYERS = 'UPDATE_LAYERS';
