export enum AdminStoreDataListEnum {
  attachment_types_availability = 'attachment_types_availability',
  attachment_types_company = 'attachment_types_company',
  attachment_types_contact = 'attachment_types_contact',
  attachment_types_deal = 'attachment_types_deal',
  attachment_types_member = 'attachment_types_member',
  attachment_types_occupier = 'attachment_types_occupier',
  attachment_types_pipeline = 'attachment_types_pipeline',
  attachment_types_property = 'attachment_types_property',
  availability_roles = 'availability_roles',
  company_roles = 'company_roles',
  comparable_lease_roles = 'comparable_lease_roles',
  comparable_sale_roles = 'comparable_sale_roles',
  deal_lease_roles = 'deal_lease_roles',
  deal_roles = 'deal_roles',
  deal_sale_roles = 'deal_sale_roles',
  industry_types = 'industry_types',
  member_roles = 'member_roles',
  occupier_roles = 'occupier_roles',
  pipeline_roles = 'pipeline_roles',
  property_types = 'property_types',
  property_roles = 'property_roles',
  requirement_roles = 'requirement_roles',
}
