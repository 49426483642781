<template>
  <div>
    <v-img
      v-if="hasPhoto"
      :class="photoClass"
      :src="photoUrl"
      :aspect-ratio="1.7"
      :lazy-src="lazySrc"
    >
      <div v-if="transactionTypeLabel" class="transaction-type-label">{{ transactionTypeLabel }}</div>
      <template #placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          />
        </v-row>
      </template>
      <v-chip
        v-if="number"
        small
        label
        class="sc-nbr nbr-default"
        dark
        style="position: absolute; right: 0; bottom: 0"
      >
        <span class="white--text">{{ number }}</span>
      </v-chip>
    </v-img>
    <v-img
      v-else
      :class="photoClass"
      :src="lazySrc"
      :aspect-ratio="1.7"
    >
      <v-chip
        v-if="number"
        small
        label
        class="sc-nbr nbr-default"
        dark
        style="position: absolute; right: 0; bottom: 0"
      >
        <span class="white--text">{{ number }}</span>
      </v-chip>
    </v-img>
  </div>
</template>

<script>
import { placeholderPropertyImg } from '@/settings';

export default {
  name: 'Photo',
  props: {
    photoUrl: {
      type: String,
      default: () => '',
    },
    photoClass: {
      type: String,
      default: () => '',
    },
    lazySrc: {
      type: String,
      default: () => placeholderPropertyImg,
    },
    number: {
      type: [String, Number],
      default: ''
    },
    transactionTypeLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasPhoto () {
      return (
        this.photoUrl !== '' &&
        this.photoUrl !==
          placeholderPropertyImg
      )
    },
  },
}
</script>
