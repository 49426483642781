import { httpV1 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import MemberOldService from '@/bundles/Members/services/MemberOldService';

function memberOldServiceFactory () {
  return new MemberOldService({
    transport: httpV1,
    errorMapper: new ErrorMapper(),
  });
}

export const memberOldService = memberOldServiceFactory();
