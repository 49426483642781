export function renderCaptcha (container: string) {
  if (!process.env.VUE_APP_RECAPTCHA_KEY) {
    return;
  }
  grecaptcha.ready(function () {
    grecaptcha.render(container, {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
    });
  });
}

export function validateCaptcha (): boolean|string {
  document.querySelector('.captcha-container')?.classList.remove('captcha-container--error');
  if (!process.env.VUE_APP_RECAPTCHA_KEY) {
    return true;
  }
  return grecaptcha.getResponse();
}

export function resetCaptcha () {
  if (!process.env.VUE_APP_RECAPTCHA_KEY) {
    return true;
  }
  grecaptcha.reset();
  return true;
}
