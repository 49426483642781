import { IComparable } from '@/bundles/Comparables/types';
import { formatCurrency } from '@/utils/filters';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

export const getComparableSalesPricePerSF = (comparable: IComparable) => {
  const result = '';

  if (comparable.transaction_type !== 'sale') {
    return result;
  }

  if (!isValuePresent(comparable.transaction.sale_price_total) || !isValuePresent(comparable.sale_price_sf)) {
    return result;
  }

  if (comparable.property.type.toLowerCase() === 'land') {
    return formatCurrency(comparable.transaction.sale_price_total / comparable.transaction.total_acres);
  }

  return formatCurrency(comparable.transaction.sale_price_sf);
}
