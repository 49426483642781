const MarketingEblastList = () => import(/* webpackChunkName: "marketing" */ '@/bundles/Marketing/views/MarketingEblastList.vue');
const MarketingEblastRecord = () => import(/* webpackChunkName: "marketing" */ '@/bundles/Marketing/views/MarketingEblastRecord.vue');

export default [
  {
    path: 'marketing/eblasts',
    name: 'marketing-eblasts',
    component: MarketingEblastList,
    props: true,
    meta: {
      title: 'Marketing Eblasts',
    },
  },
  {
    path: 'marketing/eblast/:id',
    name: 'marketing-eblast-record',
    component: MarketingEblastRecord,
    props: true,
    meta: {
      title: 'Marketing Eblast',
    },
  }
];
