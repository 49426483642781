import * as constants from './admin-types'
import { ServiceFactory } from '@/services/ServiceFactory'

const AdminService = ServiceFactory.get('admin')

export default {
  async [constants.GET_LISTS] ({ commit }, { token }) {
    const { data } = await AdminService.list({ token })
    commit(constants.SET_LISTS, data.result)
  },
  async [constants.UPDATE_LIST] ({ commit }, { token, id, payload }) {
    const { data } = await AdminService.update({ token, id, payload })
    if ('new' in data) {
      commit(constants.UPDATE_LIST, {
        category: data.new.category,
        payload: data.new,
      })
    } else {
      throw new Error('Have troubles with update')
    }
  },
}
