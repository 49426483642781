import mutations from './mutations';

const state = () => ({
  notifications: {}
});

export default {
  namespaced: true,
  state,
  mutations,
};
