export const GET_AVAILABILITIES = 'GET_AVAILABILITIES'
export const SET_AVAILABILITIES = 'SET_AVAILABILITIES'
export const UPDATE_AVAILABILITIE = 'UPDATE_AVAILABILITIE'
export const ADD_AVAILABILITIE_TO_REQUIREMENT =
  'ADD_AVAILABILITIE_TO_REQUIREMENT'
export const DELETE_AVAILABILITIE_FROM_REQUIREMENT =
  'DELETE_AVAILABILITIE_FROM_REQUIREMENT'
export const GET_AVAILABILITIES_FULL_LIST = 'GET_AVAILABILITIES_FULL_LIST'
export const GET_AVAILABILITY_CONTACTS = 'GET_AVAILABILITY_CONTACTS'
export const CREATE_ORDER = 'CREATE_ORDER'
export const UPDATE_COORDINATES = 'UPDATE_COORDINATES'
export const GET_AVAILABILITY = 'GET_AVAILABILITY'
export const SET_AVAILABILITY_VIEW = 'SET_AVAILABILITY_VIEW'
