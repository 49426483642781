import instance from '@/services/instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/project';

export default {
  list: async function ({ params = [], token }) {
    try {
      const paramsString = generateParams(params);

      return await instance.get(`${resource}/list${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ payload, token }) {
    try {
      const payloadActive = Object.assign(payload, { active: true });
      return await instance.post(`${resource}`, payloadActive, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ payload, id, token }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContacts: async function ({ payload, id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/addContacts`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ payload, id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/removeContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  delete: async function ({ id, token }) {
    try {
      return await instance.put(`${resource}/${id}`, { active: false }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * Get project by id
   * @param {Object} params
   * @param {String} id
   * @param {String} token
   * */
  getOne: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecord: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/record`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * archive the projects
   */
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
