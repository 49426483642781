import { placeholderPropertyImg } from '@/settings';
import * as constants from './action-types';
import { RootMutations } from '@/store/types/mutation-types';

export default {
  [RootMutations.SET_TEMPORARY_AUTH_DATA] (state, data) {
    state.isMultipleAccountAuth = true;
    state.temporaryAuthToken = data.access_token;
    state.temporaryUserInfo = data.user;
  },
  [RootMutations.SET_AUTH_INFO] (state, data) {
    state.idToken = data.access_token;
    state.userId = data.client_id;
    state.color = '#98002E';
    state.availabilityView = 'client';
    state.refreshToken = null;
  },
  [RootMutations.CLEAR_TEMPORARY_INFO] (state) {
    state.isMultipleAccountAuth = false;
    state.temporaryAuthToken = null;
    state.temporaryUserInfo = null;
  },
  [RootMutations.SET_CURRENT_USER] (state, payload) {
    state.company = payload.active_account.name;
    state.userName = `${ payload.profile.first_name } ${ payload.profile.last_name }`;
    state.userEmail = payload.email;
    state.user = payload;
    state.user.profile.img = payload.profile.img || placeholderPropertyImg;
    state.activeAccount = payload.active_account;
  },
  [RootMutations.SET_USER_LOGO] (state, url) {
    state.user.profile.img = url;
  },
  [RootMutations.SET_USER_ROLES] (state, roles) {
    state.userRoles = [...roles];
  },
  [constants.SET_PREVIOUS_URL] (state, url) {
    state.prevUrl = url
  },
  [constants.SOCKET_CONNECT] (state) {
    state.socketConnected = true
  },
  [constants.SOCKET_PIPELINE_ADDED] (state, pipeline) {
    let { items } = state.Pagination.pages['pipeline']
    if (!items.find((item) => item._key === pipeline.key)) {
      items = [...items, pipeline]
    }
    state.Pagination.pages['pipeline'].items = items
  },
  [constants.TOGGLE_SPLASH_SCREEN] (state, splash) {
    state.splash = splash
  },
  [constants.LOGIN] (state, payload) {
    state.idToken = payload.token;
    state.userId = payload.userId;
    state.contactId = payload.contactId;
    state.color = '#98002E';
    state.company = payload.account.name;
    state.companyIcon = payload.account.icon;
    state.companyLogo = payload.account.logo;
    state.userName = payload.userName;
    state.userEmail = payload.userEmail;
    state.availabilityView =
      state.availabilityView &&
      ['client', 'workflow', 'admin'].includes(state.availabilityView)
        ? state.availabilityView
        : 'client';
    state.refreshToken = payload.refreshToken;
    state.userSettings = payload.userSettings;
    state.user = {
      accessToken: payload.token,
      contact: payload.contactId,
      name: payload.userName,
      email: payload.userEmail,
      refreshToken: payload.refreshToken,
      settings: payload.userSettings,
      integrations: payload.integrations,
    };
  },
  [constants.SET_TOKENS] (state, { access, refresh }) {
    state.idToken = access
    state.refreshToken = refresh
  },
  [constants.SET_AVAILABILITY_VIEW] (state, view) {
    state.Availability.view = view
  },
  [constants.SET_SETTINGS] (state, settings) {
    state.userSettings = settings
  },
  [constants.TOGGLE_SNACKBAR] (state, snackbarInfo) {
    state.snackbar = Object.assign({}, state.snackbar, snackbarInfo)
  },
  [constants.TOGGLE_APP_UPDATE_MODAL] (state, value) {
    state.isAppUpdateModalOpened = value
  },
  [constants.PREFILL_SELECTED] (state, selected) {
    state.selectedItems = selected
  },
  [constants.CLEAR_SELECTED] (state) {
    state.selectedItems = []
  },
  [constants.SET_INTEGRATIONS] (state, integrations) {
    state.user = Object.assign({}, state.user, { integrations })
  },
  [constants.SET_CARD_MAP_STYLE] (state, style) {
    state.cardMap.style = style
  },
  [RootMutations.SET_NAV_STATE] (state, value) {
    state.nav = value
  },
  [RootMutations.SET_NAV_VIEW] (state, value) {
    state.isMiniNav = value
  },
  [RootMutations.SET_ACCOUNT_SETTING] (state, value) {
    state.accountSettings = { ...value };
  },
  [RootMutations.SET_USER_INTEGRATIONS] (state, value) {
    state.userIntegrations = [...value];
  },
  [RootMutations.AUTH_USER] (state, userData) {
    state.idToken = userData.token
    state.userId = userData.userId
    state.contactId = userData.contactId
    state.color = '#98002E'
    state.company = ''
    state.companyLogo = ''
    state.companyIcon = ''
    state.userName = userData.userName
    state.userEmail = userData.userEmail
  },
  [RootMutations.SET_ADMIN_TOKEN] (state, token) {
    state.adminToken = token
    state.adminTokenExpiresTime = new Date().getTime() + 3600000 // 1h in milliseconds
  },
  [RootMutations.CLEAR_AUTH_DATA] (state) {
    state.idToken = null
    state.adminToken = null
    state.adminTokenExpiresTime = null
    state.refreshToken = null
    state.userName = null
    state.userId = null
    state.contactId = null
    state.color = '#1565c0'
    state.activeAccount = null
    state.user = null
    state.spatialStream = {
      token: null,
      baseUrl: null,
      candy: null,
    }
    state.userIntegrations = [];
    state.isMultipleAccountAuth = false;
  },
  [RootMutations.SET_MAP_CENTER] (state, points) {
    state.spatial.center = {
      longitude: points[0],
      latitude: points[1],
    }
  },
  [RootMutations.SET_MAP_LAYERS] (state, layers) {
    state.spatial.layers = layers
    state.spatial.mapLayers = layers
  },
  [RootMutations.SET_STANDARD_LAYERS] (state, layers) {
    state.spatial.standardLayers = layers
  },
  [RootMutations.SET_INDUSTRIAL_LAYERS] (state, layers) {
    state.spatial.industrialLayers = layers
  },
  [RootMutations.SET_OFFICE_LAYERS] (state, layers) {
    state.spatial.officeLayers = layers
  },
  [RootMutations.SET_RETAIL_LAYERS] (state, layers) {
    state.spatial.retailLayers = layers
  },
  [RootMutations.SET_PACKAGE_LAYERS] (state, layers) {
    state.spatial.packageLayers = layers
  },
  [RootMutations.SET_MULTI_FAMILY_LAYERS] (state, layers) {
    state.spatial.multiFamilyLayers = layers
  },
  [RootMutations.SET_FARM_AND_RANCH_LAYERS] (state, layers) {
    state.spatial.farmAndRanchLayers = layers
  },
  [RootMutations.SET_RESIDENTIAL_LAYERS] (state, layers) {
    state.spatial.residentialLayers = layers
  },
  [RootMutations.SET_TRANSPORTATION_LAYERS] (state, layers) {
    state.spatial.transportationLayers = layers
  },
  [RootMutations.SET_PROPERTY_GROUPING] (state, layers) {
    state.spatial.propertyGroupingLayers = layers
  },
  [RootMutations.SET_MUNICIPAL_LAYERS] (state, layers) {
    state.spatial.municipalLayers = layers;
  },
  [RootMutations.SET_MAP_STYLE] (state, style) {
    state.spatial.style = style
  },
  [RootMutations.SET_MAP_APPROVE_STYLE] (state, style) {
    state.spatial.styleApprove = style
  },
  [RootMutations.SET_MAP_CARDS_STYLE] (state, style) {
    state.spatial.mapCards = style
  },
  [RootMutations.SET_MAP_ZOOM] (state, zoom) {
    state.spatial.zoom = zoom
  },
  [RootMutations.UPDATE_APP_VERSION] (state, newValue) {
    state.appVersion = newValue;
  },
  [RootMutations.SET_VISITOR_ID] (state, value) {
    state.visitorId = value;
  },
  [RootMutations.ADD_CANCEL_TOKEN] (state, value) {
    state.cancelTokens = [...state.cancelTokens, value];
  },
  [RootMutations.CLEAR_CANCEL_TOKENS] (state) {
    state.cancelTokens = [];
  }
}
