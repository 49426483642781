import { IPropertyExpense } from '@/bundles/Property/interfaces/IPropertyExpense';
import { Nullable } from '@/utils/types';
import { formatCurrency } from '@/utils/filters';
import i18n from '@/bundles/Localization';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';

export const getAvailabilityExpensesLabel = (expenses: IPropertyExpense[]): Nullable<string> => {
  const recentExpense: Nullable<IPropertyExpense> = expenses[0] || null;

  if (!recentExpense) return null;

  if (recentExpense.tmi) {
    return `${formatCurrency(recentExpense.tmi)} (${i18n.t(LocalizationEnum.tmi)})`;
  }

  if (recentExpense.opex) {
    return `${formatCurrency(recentExpense.opex)} (OPEX)`;
  }

  if (recentExpense.total_expenses) {
    return `${formatCurrency(recentExpense.total_expenses)} (OPEX)`;
  }

  return null;
}
