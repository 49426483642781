import { sortBy } from 'lodash';
import { ITextValue } from '@/types/common';

export const getIndustryGroupTypes = () => {
  return [
  {
    group: 'Automobiles & Components',
    types: ['Automotive'],
  },
  {
    group: 'Consumer Discretionary',
    types: ['Consumer Discretionary'],
  },
  {
    group: 'Consumer Durables & Apparel',
    types: [
      'Consumer Goods',
      'Household Durables',
      'Leisure Products',
      'Textiles, Apparel & Luxury Goods',
    ],
  },
  {
    group: 'Consumer Services',
    types: [
      'Consumer Services',
      'Diversified Consumer Services',
      'Hotels, Restaurants & Leisure',
    ],
  },
  {
    group: 'Diversified Consumer Services',
    types: [
      'Education Services',
      'Family Services',
      'Specialized Consumer Services',
    ],
  },
  {
    group: 'Media',
    types: ['Media'],
  },
  {
    group: 'Retailing',
    types: ['Distributors', 'Retailing', 'Specialty Retail'],
  },
  {
    group: 'Consumer Staples',
    types: ['Consumer Staples'],
  },
  {
    group: 'Food & Staples Retailing',
    types: ['Food & Staples Retailing'],
  },
  {
    group: 'Food, Beverage & Tobacco',
    types: ['Beverages', 'Food Products', 'Tobacco'],
  },
  {
    group: 'Household & Personal Products',
    types: ['Personal Products'],
  },
  {
    group: 'Energy Equipment & Services',
    types: ['Gas Utilities'],
  },
  {
    group: 'Banks',
    types: ['Banks'],
  },
  {
    group: 'Diversified Financial Services',
    types: ['Diversified Financial Services'],
  },
  {
    group: 'Diversified Financials',
    types: ['Capital Markets', 'Diversified Financial Services'],
  },
  {
    group: 'Insurance',
    types: ['Insurance'],
  },
  {
    group: 'Real Estate',
    types: ['Real Estate'],
  },
  {
    group: 'Health Care Equipment & Services',
    types: [
      'Health Care Equipment & Services',
      'Health Care Providers & Services',
    ],
  },
  {
    group: 'Pharmaceuticals, Biotechnology & Life Sciences',
    types: [
      'Biotechnology',
      'Life Sciences Tools & Services',
      'Pharmaceuticals',
    ],
  },
  {
    group: 'Capital Goods',
    types: [
      'Aerospace & Defense',
      'Capital Goods',
      'Commercial Services & Supplies',
      'Construction & Engineering',
      'Electrical Equipment',
      'Industrial Conglomerates',
      'Machinery',
      'Trading Companies & Distributors',
    ],
  },
  {
    group: 'Commercial & Professional Services',
    types: ['Commercial Services & Supplies', 'Professional Services'],
  },
  {
    group: 'Industrials',
    types: ['Industrials'],
  },
  {
    group: 'Transportation',
    types: [
      'Air Freight & Logistics',
      'Airlines',
      'Marine',
      'Road & Rail',
      'Transportation',
    ],
  },
  {
    group: 'Software & Services',
    types: ['Internet Software & Services', 'IT Services', 'Software'],
  },
  {
    group: 'Technology Hardware & Equipment',
    types: [
      'Communications Equipment',
      'Electronic Equipment, Instruments & Components',
      'Technology Hardware, Storage & Peripherals',
    ],
  },
  {
    group: 'Construction Materials',
    types: ['Building Materials'],
  },
  {
    group: 'Materials',
    types: [
      'Chemicals',
      'Containers & Packaging',
      'Metals & Mining',
      'Paper & Forest Products',
    ],
  },
  {
    group: 'Telecommunication Services',
    types: [
      'Diversified Telecommunication Services',
      'Wireless Telecommunication Services',
    ],
  },
  {
    group: 'Independent Power and Renewable Electricity Producers',
    types: ['Renewable Electricity'],
  },
  {
    group: 'Utilities',
    types: ['Electric Utilities', 'Utilities'],
  },
  {
    group: 'Semiconductors & Semiconductor Equipment',
    types: ['Semiconductors & Semiconductor Equipment'],
  },
]
}
export const getIndustryTypes = () => {
  return [
  'Automobiles & Components',
  'Banks',
  'Capital Goods',
  'Commercial & Professional Services',
  'Construction Materials',
  'Consumer Discretionary',
  'Consumer Durables & Apparel',
  'Consumer Services',
  'Consumer Staples',
  'Diversified Consumer Services',
  'Diversified Financial Services',
  'Diversified Financials',
  'Energy Equipment & Services',
  'Food & Staples Retailing',
  'Food, Beverage & Tobacco',
  'Government',
  'Health Care Equipment & Services',
  'Household & Personal Products',
  'Independent Power and Renewable Electricity Producers',
  'Industrials',
  'Insurance',
  'Materials',
  'Media',
  'Pharmaceuticals, Biotechnology & Life Sciences',
  'Retailing',
  'Real Estate',
  'Semiconductors & Semiconductor Equipment',
  'Software & Services',
  'Technology Hardware & Equipment',
  'Transportation',
  'Telecommunication Services',
  'Utilities',
  'Other'
]
};

interface IIndustryTypesHeader {
  header: string;
}

export type IIndustryTypesGroups = IIndustryTypesHeader | ITextValue<string>;

export const getIndustryTypesByGroup = () => {
  const industryGroupTypes = sortBy(getIndustryGroupTypes(), 'group');
  const result: IIndustryTypesGroups[] = [];
  industryGroupTypes.forEach(getIndustryGroupType => {
    result.push({ header: getIndustryGroupType.group });
    getIndustryGroupType.types.sort().forEach(type => {
      result.push({
        text: type,
        value: type
      });
    });
  });
  return result;
};
