























































import Vue from 'vue';
import { formatDate } from '@/utils/filters';
import { Nullable } from '@/utils/types';

import CommonDatePicker from '@/components/common-components/pickers/CommonDatePicker.vue';

export default Vue.extend({
  name: 'OpenInvoicesDateFilter',

  components: { CommonDatePicker },

  props: {
    dateStart: {
      type: String,
      default: '',
    },
    dateEnd: {
      type: String,
      default: '',
    },
  },

  computed: {
    filterLabel (): string {
      if (this.dateStart && this.dateEnd) {
        return `${formatDate(this.dateStart)} - ${formatDate(this.dateEnd)}`;
      }

      if (this.dateStart) {
        return `From ${formatDate(this.dateStart)}`;
      }

      if (this.dateEnd) {
        return `To ${formatDate(this.dateEnd)}`;
      }

      return '';
    },
  },

  methods: {
    updateStartDate (date: Nullable<string>): void {
      this.$emit('update:dateStart', date);
    },
    updateEndDate (date: Nullable<string>): void {
      this.$emit('update:dateEnd', date);
    },
    clearFilter (): void {
      this.updateStartDate(null);
      this.updateEndDate(null);
    },
    applyFilter () {
      (this.$refs.menu as any).save();
      this.$emit('fetch');
    },
  }
})
