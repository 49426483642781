import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { MemberSettings } from '@/bundles/Members/models/MemberSettings';
import { Nullable } from '@/utils/types';
import { IUpdateNotificationsSettingsDto } from '@/bundles/Settings/dto/IUpdateNotificationsSettingsDto';
import { OpexDisplayEnum } from '../enums';

export default class MemberOldService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;

  constructor ({ transport, errorMapper }: { transport: HttpTransport; errorMapper: ErrorMapper; }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
  }

  async getMemberSettings (id: number): Promise<MemberSettings> {
    try {
      const response = await this.transport.get(`/member-settings/${id}`);

      return new MemberSettings(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateMemberAnnualGoal (id: string, annualGoal: Nullable<number>): Promise<void> {
    try {
      await this.transport.patch(`/member-settings/${id}/annual-goal`, { annual_goal: annualGoal });
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateMemberNotificationsSettings (id: string, payload: IUpdateNotificationsSettingsDto) {
    try {
      return await this.transport.patch(`/member-settings/${id}/notifications`, { notifications: payload });
    } catch (error: any) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateMemberOpexDisplaySettings (id: string, payload: { opex_display: OpexDisplayEnum }) {
    try {
      return await this.transport.patch(`/member-settings/${id}/opex-display`, payload);
    } catch (error: any) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
