export interface IGetAccountRouteHandler {
  name: string;
  params: { accountId: number } & Record<string, any>;
  query: Record<string, any>;
}

const getAccountRouteHandler = ({ name, params = {}, query = {}, activeAccountId }: {
  name: string;
  params?: Record<string, any>;
  query?: Record<string, any>;
  activeAccountId: number;
}): IGetAccountRouteHandler => {
  if (Object.keys(params).length && 'id' in params && (params.id === undefined || null)) {
    params.id = 0;
  }

  const routeParams = {
    accountId: activeAccountId,
    ...params
  };

  return { name, params: { ...routeParams }, query: { ...query } };
}

export { getAccountRouteHandler };
