

































import Vue, { PropType } from 'vue';
import { IHeader } from '@/bundles/BaseTable/interfaces';

export default Vue.extend({
  name: 'BaseTableHeaderMulti',

  props: {
    header: {
      type: Object as PropType<IHeader>,
      required: true
    },
    color: {
      type: String as PropType<'black' | 'grey'>,
      required: true
    },
    isActive: Boolean,
    isSortDescending: Boolean
  },

  computed: {
    sortDirection (): string {
      return this.isSortDescending ? 'descending' : 'ascending';
    },
    iconName (): string {
      switch (this.header.icon) {
        case 'date':
          return `mdi-sort-calendar-${ this.sortDirection }`;
        case 'string':
          return `mdi-sort-alphabetical-${ this.sortDirection }-variant`;
        default:
          return `mdi-sort-numeric-${ this.sortDirection }`;
      }
    }
  },

  methods: {
    sortChangeHandler (value: string, sortDesc: boolean) {
      this.$emit('sortChangeEvent', {
        sortBy: [value],
        sortDesc: [sortDesc]
      });
    },
  }
})
