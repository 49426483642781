import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AccountSettingsToMapper from '@/bundles/Account/mappers/AccountSettingsToMapper';
import AccountSettings from '@/bundles/Account/models/AccountSettings';
import UpdateAccountSettings from '@/bundles/Account/dto/update-account-settings';

export default class AccountSettingsService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly accountEmailSettingsMapper: AccountSettingsToMapper;

  constructor ({
    transport,
    errorMapper,
    accountEmailSettingsMapper,
  }: {
    transport: HttpTransport;
    errorMapper: ErrorMapper;
    accountEmailSettingsMapper: AccountSettingsToMapper;
  }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.accountEmailSettingsMapper = accountEmailSettingsMapper;
  }

  async getOne (id: number): Promise<AccountSettings> {
    try {
      const response = await this.transport.get(`/account-settings/${ id }`);
      return this.accountEmailSettingsMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateOne (id: string, payload: UpdateAccountSettings): Promise<AccountSettings> {
    try {
      const response = await this.transport.patch(`/account-settings/${ id }`, payload);
      return this.accountEmailSettingsMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
