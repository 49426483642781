





































import { mapActions, mapMutations, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { NotificationMutations } from '@/store/types/mutation-types';
import { ServiceFactory } from '@/services/ServiceFactory';
import { RootActions } from '@/store/types/action-types';

const integrationService = ServiceFactory.get('integrations');

export default mixins(AccountLayoutMixin).extend({
  name: 'MemberOutlookExpired',

  data: (): { loading: boolean } => ({
    loading: false,
  }),

  computed: {
    ...mapState({
      user: (state: any) => state.user,
      integrations: (state: any) => state.userIntegrations,
      token: (state: any) => state.idToken,
    }),
    outlook: {
      get: function (): any {
        return this.integrations.find((integration) => integration.name === 'outlook');
      },
    },
  },

  methods: {
    ...mapActions({
      fetchUserIntegrations: RootActions.FETCH_USER_INTEGRATIONS,
    }),
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    removeUserIntegration: async function () {
      this.loading = true;
      try {
        await integrationService.removeUserIntegration({
          token: this.token,
          id: this.outlook!._key,
        });
        await this.fetchUserIntegrations();
        this.$emit('outlook:processed');
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      } finally {
        this.loading = false;
      }
    },
  },
});
