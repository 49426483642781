import HttpClient, { RequestEntryParams } from '@/bundles/Http/transport/HttpClient';
import { HttpMethods } from '@/bundles/Http/enum/HttpMethods';
import { Nullable } from '@/utils/types';

export default class HttpTransport {
  readonly client: HttpClient;

  constructor (client: HttpClient) {
    this.client = client;
  }

  /**
   * Make get request
   * @param {string} url
   * @param {any} query
   * @param controller
   * @return {Promise}
   * */
  public get (url: string, query: any = {}, controller: Nullable<AbortController> = null) {
    return this.makeRequest({ method: HttpMethods.get, query, url, controller });
  }

  /**
   * Make post request
   * @param {string} url
   * @param {any} body
   * @return {Promise}
   * */
  public post (url: string, body: any) {
    return this.makeRequest({
      method: HttpMethods.post,
      data: body,
      url,
    });
  }

  /**
   * Make put request
   * @param {string} url
   * @param {any} body
   * @return {Promise}
   * */
  public put (url: string, body: any) {
    return this.makeRequest({
      method: HttpMethods.put,
      data: body,
      url,
    });
  }

  /**
   * Make patch request
   * @param {string} url
   * @param {any} body
   * @return {Promise}
   * */
  public patch (url: string, body: any) {
    return this.makeRequest({
      method: HttpMethods.patch,
      data: body,
      url,
    });
  }

  /**
   * Make delete request
   * @param {string} url
   * @return {Promise}
   * */
  public delete (url: string) {
    return this.makeRequest({
      method: HttpMethods.delete,
      url,
    });
  }

  /**
   * Make API request
   * @param {HttpMethods} method
   * @param {String} url
   * @param {any} [payload={}]
   * @param {any} [query={}]
   * @return {Promise<any>}
   * */
  private makeRequest ({
    method,
    url,
    query = {},
    data = {},
    controller,
  }: RequestEntryParams): Promise<any> {
    return this.client.request({ url, method, data, query, controller });
  }
}
