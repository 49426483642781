

































































import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import { IHeader } from '@/bundles/BaseTable/interfaces';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

import Label from '@/bundles/Common/components/Label.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

interface IOpenInvoicesTableData {
  headers?: IHeader[];
}

const headers = [
  { text: '#', value: '#', sortable: false },
  { text: 'Invoice No.', value: 'invoice_number.', sortable: true, class: '', icon: 'number' },
  { text: 'Deal Name', value: 'deal.name', sortable: true, class: '', icon: 'string' },
  { text: 'Broker(s)', value: 'broker', sortable: false, class: '', icon: 'string' },
  { text: 'Buyer/Tenant', value: 'buyer_tenant', sortable: false, class: '', icon: 'string' },
  { text: 'Seller/Landlord', value: 'seller_landlord', sortable: false, class: '', icon: 'string' },
  { text: 'Due Date', value: 'due_date', sortable: true, class: '', icon: 'date' },
  { text: 'Invoice Amount ($)', value: 'amount', sortable: true, class: '', icon: 'number' },
  { text: 'Amount Paid ($)', value: 'amount_paid', sortable: true, class: '', icon: 'number' },
  { text: 'Outstanding ($)', value: 'amount_outstanding', sortable: true, class: '', icon: 'number' },
  { text: 'Payment Status', value: 'status', sortable: true, class: '', icon: 'string' },
  { text: 'Aging', value: 'diffLabel', sortable: true, class: '', icon: 'number' },
  { text: '# Days Aged', value: 'daysDiff', sortable: true, class: '', icon: 'number' }
]

export default mixins(AccountLayoutMixin).extend({
  name: 'OpenInvoicesTable',

  components: {
    CommonChip,
    Label
  },

  props: {
    value: {
      type: Object as PropType<{ sortBy: [string]; sortDesc: [boolean]; }>,
      required: true
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    filterLoading: Boolean
  },

  data: (): IOpenInvoicesTableData => ({}),

  computed: {
    sortBy: {
      get (): [string] {
        return this.value.sortBy;
      },
      set (value: [string]): void {
        this.$emit('input', {
          ...this.value,
          sortBy: value
        })
      },
    },
    sortDesc: {
      get (): [boolean] {
        return this.value.sortDesc;
      },
      set (value: [boolean]): void {
        this.$emit('input', {
          ...this.value,
          sortDesc: value
        })
      },
    },
  },

  created () {
    this.headers = headers;
  },

  methods: {
    getCompanyNames (contacts: { name: string }[]): string {
      return contacts.map(item => item.name).join(', ') || '';
    },
    getBrokers (deal) {
      return deal.brokers.map(item => `${item.first_name} ${item.last_name}`).join(', ');
    }
  }
})
