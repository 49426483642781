import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';

const defaultDashboardWidgets = [
  { name: UserWidgetsEnum.tasks, cols: 12, order: 0 },
  { name: UserWidgetsEnum.critical_dates, cols: 12, order: 1 },
  { name: UserWidgetsEnum.open_balances, cols: 12, order: 2 },
  { name: UserWidgetsEnum.broker_ranking, cols: 12, order: 3 },
  { name: UserWidgetsEnum.new_availabilities, cols: 12, order: 4 },
  { name: UserWidgetsEnum.recent_comparables, cols: 12, order: 5 },
  { name: UserWidgetsEnum.open_invoices, cols: 12, order: 6 },
];

export { defaultDashboardWidgets };
