import AuthService from '@/bundles/Auth/services/AuthService';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AuthReturnToMapper from '@/bundles/Auth/mappers/AuthReturnToMapper';
import MultiAuthReturnToMapper from '@/bundles/Auth/mappers/MultiAuthReturnToMapper';

function authServiceFactory () {
  return new AuthService({
    transport: httpV2,
    errorMapper: new ErrorMapper(),
    authMapper: new AuthReturnToMapper(),
    multiAuthMapper: new MultiAuthReturnToMapper(),
  });
}

export const authService = authServiceFactory();
