import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';
import { IBaseModel } from '@/bundles/App/interfaces/IBaseModel';

export default class BaseModel implements IBaseModel {
  created: ICreatedUpdatedBy;
  updated: ICreatedUpdatedBy;

  constructor (payload: IBaseModel) {
    this.created = payload.created;
    this.updated = payload.updated;
  }
}
