






















































import Vue, { PropType } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { IAppNotification } from '@/utils/interfaces';
import { NotificationMutations } from '@/store/types/mutation-types';
import { AppNotificationTypeEnum } from '@/bundles/App/enums/appNotificationTypeEnum';
import { Nullable } from '@/utils/types';

interface IAppNotificationData {
  timeout: number;
  timeAliveStart: number;
  timeAliveEnd: Nullable<number>;
}

const timeoutInitial = 3500;

export default Vue.extend({
  name: 'AppNotification',

  props: {
    notificationId: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 1,
    },
    notification: {
      type: Object as PropType<IAppNotification>,
      default: () => ({
        message: '',
        status: 400,
        title: 'Error',
        url: ''
      }),
    },
  },

  data: (): IAppNotificationData => ({
    timeout: timeoutInitial,
    timeAliveStart: Date.now(),
    timeAliveEnd: null,
  }),

  computed: {
    ...mapState({
      notifications: (state: any) => state.Notifications.notifications
    }),
    isSuccess: function (): boolean {
      if (typeof this.notification.status === 'number') {
        return (this.notification.status >= 200 && this.notification.status < 300);
      }

      return false;
    },
    visibility: {
      get (): boolean {
        return !!this.notifications[this.notificationId];
      },
      set (val: boolean) {
        if (!val) {
          this.deleteMsg();
        }
      },
    },
    type (): AppNotificationTypeEnum {
      return this.isSuccess ? AppNotificationTypeEnum.success : AppNotificationTypeEnum.error;
    },
    animationDuration (): number {
      return timeoutInitial;
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      deleteNotification: NotificationMutations.DELETE_NOTIFICATION
    }),
    deleteMsg () {
      this.deleteNotification(this.notificationId);
    },
    openBugRequest () {
      // @ts-ignore
      this.$eventBus.$emit('open-request-form');
      this.$nextTick(() => {
        // @ts-ignore
        this.$eventBus.$emit('open-bug-report');
        // @ts-ignore
        this.$eventBus.$emit('fill-bug-report', {
          message: this.notification.message,
          url: this.notification.url
        });
        this.deleteMsg();
      })
    },
    messageClickHandler ($event: any) {
      if (!$event?.target?.closest('a')) {
        return;
      }

      this.deleteMsg();
    },
    mouseoverHandler () {
      this.timeAliveEnd = Date.now();
    },
    mouseoutHandler () {
      if (this.timeAliveEnd === null) {
        return;
      }

      const diff = this.timeAliveEnd - this.timeAliveStart;
      const timeout = this.timeout - diff;

      this.timeout = timeout < 0 ? 1 : timeout;
      this.timeAliveStart = Date.now();
      this.timeAliveEnd = null;
    }
  },
});
