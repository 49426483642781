import Request from '@/bundles/Requests/models/Request';
import { IRequestNotification } from '@/bundles/Panels/interfaces';
import { IBaseMapper } from '@/bundles/App/interfaces/IBaseMapper';

export default class RequestToMapper implements IBaseMapper<IRequestNotification> {
  mapToModel (data: IRequestNotification): Request {
    const tempData = data;

    const isBug = tempData.type === 'bug';

    tempData.title = `${isBug ? 'Bug' : 'Feature' } Request updated`;
    tempData.page = tempData.page || 0;
    tempData.screenshot = tempData.screenshot || '';
    tempData.type = isBug ? 'bug' : 'feature';
    tempData.color = isBug ? 'orange darken-4' : 'teal darken-2';
    tempData.icon = isBug ? 'mdi-bug' : 'mdi-star';

    return new Request(tempData);
  }
}
