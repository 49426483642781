






import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';
import mixins from 'vue-typed-mixins';

export default mixins(DialogMixin).extend({
  name: 'PhotoViewer',

  props: {
    imageUrl: {
      type: String,
      default: null,
    }
  },
});
