




































































































import { mapActions, mapMutations, mapState } from 'vuex';
import { placeholderContactImg } from '@/settings';
import { IUserAccount } from '@/bundles/User/interfaces';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { RootActions } from '@/store/types/action-types';

export default mixins(AccountLayoutMixin).extend({
  name: 'ProfileMenu',

  data: (): { profileMenu: boolean } => ({
    profileMenu: false,
  }),

  computed: {
    ...mapState({
      user: (state: any) => state.user,
      userFullName: (state: any) => state.userName,
    }),
    userAvatar: function (): string {
      return this.user?.profile?.img || placeholderContactImg;
    },
    userAccounts (): IUserAccount[] {
      return this.user?.account;
    },
    activeAccount (): IUserAccount {
      return this.user?.active_account;
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: 'ADD_NOTIFICATION'
    }),
    ...mapActions({
      logout: RootActions.LOG_OUT
    }),
    logoutHandler: async function () {
      try {
        await this.logout();
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      }
    },
  }
})
