import { httpV1 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AccountSettingsService from '@/bundles/Account/services/accountSettingsService';
import AccountSettingsToMapper from '@/bundles/Account/mappers/AccountSettingsToMapper';

function accountSettingsServiceFactory () {
  return new AccountSettingsService({
    transport: httpV1,
    errorMapper: new ErrorMapper(),
    accountEmailSettingsMapper: new AccountSettingsToMapper(),
  });
}

export const accountSettingsService = accountSettingsServiceFactory();
