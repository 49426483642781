import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';
import { Nullable } from '@/utils/types';

const propertyTypeColorsDictionary = {
  [PropertyTypeEnum.healthcare]: '#5D155C',
  [PropertyTypeEnum.industrial]: '#3172D6',
  [PropertyTypeEnum.land]: '#EC9E41',
  [PropertyTypeEnum.multiFamily]: '#54A8B5',
  [PropertyTypeEnum.office]: '#EC6756',
  [PropertyTypeEnum.retail]: '#F0CE60',
  [PropertyTypeEnum.selfStorage]: '#8962C9',
  [PropertyTypeEnum.hospitality]: '#5D9DDD',
  [PropertyTypeEnum.specialPurpose]: '#B78E55',
}

export const getPropertyTypeColor = (propertyType: Nullable<string>): string => { // property type NOT in lowercase
  if (propertyType && propertyTypeColorsDictionary.hasOwnProperty(propertyType)) {
    return propertyTypeColorsDictionary[propertyType];
  }

  return '#555555';
}
