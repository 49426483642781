var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAuthenticated)?_c('v-navigation-drawer',{staticClass:"c-app-bar",attrs:{"id":"sc-primary-nav-drawer","clipped":"","dark":"","app":"","overflow":"","stateless":_vm.$vuetify.breakpoint.smAndUp,"mini-variant":_vm.$vuetify.breakpoint.smAndUp && _vm.isMini,"permanent":_vm.$vuetify.breakpoint.smAndUp},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex justify-center mb-2"},[(!_vm.isMini)?_c('v-spacer'):_vm._e(),_c('v-btn',{staticStyle:{"opacity":".3"},attrs:{"icon":""},on:{"click":_vm.updateNavView}},[_c('v-icon',[_vm._v("mdi-chevron-double-"+_vm._s(_vm.isMini ? 'right' : 'left'))])],1)],1):_vm._e(),_c('v-divider'),(_vm.$vuetify.breakpoint.xs || !_vm.isMini)?_c('div',{staticClass:"pa-2 mt-0 text-center"},[_c('v-btn',{staticStyle:{"color":"#999"},on:{"click":_vm.openTrackerForm}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#999"},attrs:{"small":""}},[_vm._v("mdi-comment-question")]),_vm._v(" Support / Feedback ")],1)],1):_c('v-list-item',{staticClass:"user-actions-list-item",on:{"click":_vm.openTrackerForm}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"color":"#999"}},[_vm._v("mdi-comment-question")])],1)],1)]},proxy:true}],null,false,174212489),model:{value:(_vm.navState),callback:function ($$v) {_vm.navState=$$v},expression:"navState"}},[_c('v-list',{staticClass:"mb-5"},[(_vm.isMini && _vm.$route.name !== 'dashboard')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"logo-link pl-3",attrs:{"link":"","to":_vm.getAccountRoute({ name: 'dashboard' })},nativeOn:{"click":function($event){return _vm.hideNavBar($event)}}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"tile":"","src":"/img/sc-logo.png","contain":""}})],1),_c('v-list-item-title',{staticClass:"overline",staticStyle:{"color":"#6CC04A"}},[_vm._v("SlickCactus")])],1)]}}],null,false,267073371)},[_c('span',{staticClass:"overline",staticStyle:{"font-size":"1.2em","font-weight":"500"}},[_vm._v("Dashboard")])]):_c('v-list-item',{staticClass:"logo-link pl-3",attrs:{"link":"","two-line":""},nativeOn:{"click":function($event){return _vm.hideNavBar($event)}}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"tile":"","src":"/img/sc-logo.png","contain":""}})],1),_c('v-list-item-title',{staticClass:"overline",staticStyle:{"color":"#6CC04A"}},[_vm._v("SlickCactus")])],1),_c('v-list',_vm._l((_vm.items),function(link){return _c('div',{key:link.icon},[(_vm.isMini && _vm.$route.name !== link.route && ("/" + (_vm.$route.name)) !== ("" + (link.route)))?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"app-bar-nav-list-item",attrs:{"link":"","exact-path":"","to":_vm.getAccountRoute({ name: link.route })},nativeOn:{"click":function($event){return _vm.hideNavBar()}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-btn',{staticStyle:{"width":"auto!important"},attrs:{"icon":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(link.icon)+" ")])],1)],1),_c('v-list-item-title',[_vm._v(_vm._s(link.text))])],1)]}}],null,true)},[_c('span',{staticClass:"overline",staticStyle:{"font-size":"1.2em","font-weight":"500"}},[_vm._v(_vm._s(link.text))])]):_c('v-list-item',{staticClass:"app-bar-nav-list-item",attrs:{"to":_vm.getAccountRoute({ name: link.route }),"link":""}},[_c('v-list-item-icon',[_c('v-btn',{staticStyle:{"width":"auto!important"},attrs:{"icon":"","link":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(link.icon)+" ")])],1)],1),_c('v-list-item-title',[_vm._v(_vm._s(link.text))])],1)],1)}),0),(_vm.customNavigationLinks && _vm.customNavigationLinks.length)?_c('v-list',[_c('v-divider'),_vm._l((_vm.customNavigationLinks),function(customNavigationLink,index){return _c('div',{key:index},[(_vm.isMini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"app-bar-nav-list-item",attrs:{"target":"_blank","href":customNavigationLink.url}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-btn',{staticStyle:{"width":"auto!important"},attrs:{"icon":"","link":""}},[_c('v-icon',[_vm._v(_vm._s(customNavigationLink.icon))])],1)],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(customNavigationLink.title)+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"overline",staticStyle:{"font-size":"1.2em","font-weight":"500"}},[_vm._v(_vm._s(customNavigationLink.title))])]):_c('v-list-item',{staticClass:"app-bar-nav-list-item",attrs:{"target":"_blank","href":customNavigationLink.url}},[_c('v-list-item-icon',[_c('v-btn',{staticStyle:{"width":"auto!important"},attrs:{"icon":"","link":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(customNavigationLink.icon)+" ")])],1)],1),_c('v-list-item-title',[_vm._v(_vm._s(customNavigationLink.title))])],1)],1)})],2):_vm._e()],1)],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('TrackerWrapper')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }