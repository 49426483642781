import { IUploadMultipleSizesFile } from '@/bundles/Upload/interfaces';
import { Upload } from '@/bundles/Upload/models/Upload';

export class UploadMultiplySizesFile extends Upload implements IUploadMultipleSizesFile {
  thumbnail_url: string;
  small_url: string;
  medium_url: string;
  large_url: string;
  name_path: string;

  constructor (payload: IUploadMultipleSizesFile) {
    super(payload);
    this.thumbnail_url = payload.thumbnail_url;
    this.small_url = payload.small_url;
    this.medium_url = payload.medium_url;
    this.large_url = payload.large_url;
    this.name_path = payload.name_path;
  }
}
