















import Vue from 'vue';

export default Vue.extend({
  name: 'CommonChip',

  props: {
    label: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
  }
});
