import { IOfficeLocation } from '@/bundles/OfficeLocation/interfaces/IOfficeLocation';
import BasePostgresModel from '@/bundles/App/models/BasePostgresModel';
import { IAddress } from '@/utils/interfaces';
import { Address } from '@/bundles/App/models/Address';

export class OfficeLocation extends BasePostgresModel implements IOfficeLocation {
  name: string;
  address: IAddress;

  constructor (data: IOfficeLocation) {
    super(data);
    this.name = data.name;
    this.address = new Address(data.address);
  }
}
