import BasePostgresModel from '@/bundles/App/models/BasePostgresModel';
import { ISubscription } from '@/bundles/Subscriptions/interfaces/ISubsription';
import { SubscriptionTypeEnum } from '@/bundles/Subscriptions/enums/subscriptionTypeEnum';

export default class Subscription extends BasePostgresModel implements ISubscription {
  type: SubscriptionTypeEnum;
  name: string;
  active: boolean;
  date_start: Date;
  date_end: Date;
  account: number;

  constructor (payload: ISubscription) {
    super(payload);
    this.type = payload.type;
    this.name = payload.name;
    this.active = payload.active;
    this.date_start = payload.date_start;
    this.date_end = payload.date_end;
    this.account = payload.account;
  }
}
