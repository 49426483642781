import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/team';

export default {
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getList: async function (token) {
    try {
      return await instance.get(`${resource}/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMyTeam: async function ({ params = [], token }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/myTeam${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  list: async function ({ params = [], token, onDownloadEventHandler = null }) {
    try {
      const paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }

      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
