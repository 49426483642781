
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { Nullable } from '@/utils/types';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

export default Vue.extend({
  name: 'Label',

  functional: true,

  props: {
    value: {
      type: [String, Number] as PropType<Nullable<string | number>>,
      default: ''
    }
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const value = ctx.props.value;
    const className = `${ctx.data.staticClass || ''} ${ctx.data.class || ''}`.trim();

    if (!isValuePresent(value)) {
      return h('span', { class: `${className} na`.trim() }, 'N/A');
    }

    return h('span', { ...ctx.data, class: className || undefined }, value);
  }
});
