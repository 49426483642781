


























import mixins from 'vue-typed-mixins';
import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';

import CreateTaskForm from '@/bundles/Tasks/components/CreateTaskForm.vue';

interface IRecordTaskCard {
  showCreateTask: boolean;
}

export default mixins(DialogMixin).extend({
  name: 'RecordTaskCard',

  components: { CreateTaskForm },

  props: {
    tasksLength: {
      type: Number,
      default: 0
    },
    associatedRecords: {
      type: Array,
      default: () => []
    }
  },

  data: (): IRecordTaskCard => ({
    showCreateTask: false
  }),

  methods: {
    onSubmit: function () {
      this.$eventBus.$emit('fetch:tasks');
    }
  }
})
