import { ITextValue } from '@/types/common';

export enum SaleConditionsEnum {
  foreclosure = 'foreclosure',
  exchange = 'exchange',
  sale_leaseback = 'sale_leaseback',
  off_market = 'off_market',
}

export const SaleConditionsLabelsObj = {
  [SaleConditionsEnum.foreclosure]: 'Foreclosure',
  [SaleConditionsEnum.exchange]: '1031 Exchange',
  [SaleConditionsEnum.sale_leaseback]: 'Sale - Leaseback',
  [SaleConditionsEnum.off_market]: 'Off-Market',
}

export const getSaleConditionsTextValues = (): ITextValue<SaleConditionsEnum>[] => {
  return Object.entries(SaleConditionsLabelsObj).map(([value, text]) => ({
    value,
    text,
  })) as ITextValue<SaleConditionsEnum>[];
}
