import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDalFvk4-3DONtUNg3R12dJU8pEX9Rbl4U',
  authDomain: 'slickcactus-notification.firebaseapp.com',
  projectId: 'slickcactus-notification',
  storageBucket: 'slickcactus-notification.appspot.com',
  messagingSenderId: '549772868602',
  appId: '1:549772868602:web:0eea73a96f4bed800dc243',
};

// Initialize Firebase
let app;
let messaging;

if (navigator.serviceWorker) {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app)
}

export async function init () {
  if (!('Notification' in window) || !navigator.serviceWorker) {
    console.error('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    return handleToken();
  } else if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    return (permission === 'granted' && handleToken());
  } else {
    console.error('Notifications request denied!');
  }
}

async function handleToken () {
  if (!navigator.serviceWorker) {
    return;
  }
  try {
    const token = await getToken(messaging, { vapidKey: 'BK_xk3iC-OC5rf1e3iXV5F4FCEjA48serA2eURvhxkZX0FP22bIa9MNP1Goo-_u3oAmDDQ-vqkzyTSKXUYoFfco' });
    if (!token) {
      console.error('No registration token available. Request permission to generate one.');
    }
    return token;
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
}

export async function subscribeToNotifications () {
  if (!navigator.serviceWorker) {
    return;
  }
  onMessage(messaging, function (payload) {
    // eslint-disable-next-line no-new
    new Notification(payload.notification.title, { ...payload.notification });
  });
}
