import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { getInitialState } from '@/store/modules/settings/helper';

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations,
}
