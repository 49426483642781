import { BaseService } from '@/bundles/App/models/BaseService';
import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import { ISubscription } from '@/bundles/Subscriptions/interfaces/ISubsription';

export default class SubscriptionService extends BaseService<ISubscription, RecordMapper<ISubscription>> {
  async getSubscriptions (): Promise<ISubscription[]> {
    try {
      const response = await this.transport.get('/subscription/active');

      return response.map(subscription => this.recordMapper.mapToModel(subscription));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
