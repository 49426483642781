






















































































































































import mixins from 'vue-typed-mixins';
import { isEqual } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { Nullable } from '@/utils/types';
import { addDates } from '@/utils/filters';
import { ServiceFactory } from '@/services/ServiceFactory';
import { IHeader, ITableOptions } from '@/bundles/BaseTable/interfaces';
import { getTableOptions } from '@/bundles/BaseTable/helpers/consts';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { AvailabilitiesCardMixin } from '@/bundles/Availabilities/mixins/AvailabilitiesCardMixin';
import { getAvailabilityClientHeadersData } from '@/bundles/Availabilities/helpers';
import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';

import BaseTable from '@/bundles/BaseTable/components/BaseTable.vue';
import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import ClientContent from '@/bundles/Availabilities/components/content-view/ClientContent.vue';

const tableOptions = getTableOptions({
  itemsPerPage: 10,
  sortBy: ['created.date'],
  sortDesc: [true]
});

const AvailabilityService = ServiceFactory.get('availability');
interface IDashboardAvailabilitiesCardData {
  widgetType: UserWidgetsEnum;
  tableOptions: ITableOptions;
  dateStart: string;
  dateEnd: string,
  inHouse: boolean;
  propertyType: Nullable<PropertyTypeEnum>;
  menu: boolean;
  menu2: boolean;
  isFirstLoad: boolean;
  filtersMenu: boolean;
}

export default mixins(AvailabilitiesCardMixin).extend({
  name: 'DashboardAvailabilitiesCard',
  components: {
    BaseTable,
    DashboardCard,
    ClientContent
  },
  props: {
    defaultStartDate: {
      type: String,
      default: ''
    },
    defaultEndDate: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: true
    },
    sourceId: {
      type: String,
      default: ''
    },
    sourceType: {
      type: String,
      default: ''
    }
  },

  data: (): IDashboardAvailabilitiesCardData => ({
    widgetType: UserWidgetsEnum.new_availabilities,
    tableOptions,
    dateStart: addDates(new Date(Date.now()).toISOString(), { days: -14, }),
    dateEnd: new Date().toISOString().substr(0, 10),
    inHouse: true,
    propertyType: null,
    menu: false,
    menu2: false,
    isFirstLoad: true,
    filtersMenu: false,
  }),

  computed: {
    ...mapState({
      activeAccountId: (state: any) => state.activeAccount.id
    }),
    ...mapState('Settings', {
      opexDisplay: (state: any) => state.opexDisplay,
    }),
    ...mapGetters('Admin', ['getListByCategory']),
    propertyTypes (): PropertyTypeEnum[] {
      return this.getListByCategory('property_types').items;
    },
    endDate (): string {
      return this.defaultEndDate || this.dateEnd;
    },
    headers (): IHeader[] {
      return [
        { text: '#', value: 'number', sortable: false },
        ...getAvailabilityClientHeadersData(this.opexDisplay)
      ];
    },
  },
  watch: {
    tableOptions: {
      handler: function () {
        this.fetchAvailabilities();
      },
      deep: true,
      immediate: true,
    },
    inHouse: {
      handler: function () {
       this.fetchAvailabilities();
      }
    },
    dateStart: {
      handler: function () {
        this.menu = false;
        this.fetchAvailabilities();
      }
    },
    dateEnd: {
      handler: function () {
        this.menu2 = false;
        this.fetchAvailabilities();
      }
    }
  },
  methods: {
    async fetchAvailabilities () {
      try {
        this.filterLoading = true;
        const params = [
          { name: 'displayType', value: this.inHouse ? 'officeListings' : '' },
          { name: 'availability_in_house', value: this.inHouse ? 'only' : 'all' },
          { name: 'pageOffset', value: this.pageOffset },
          { name: 'pageLimit', value: this.tableOptions.itemsPerPage },
          { name: 'sort', value: this.tableOptions.sortBy },
          { name: 'descending', value: this.tableOptions.sortDesc },
          { name: 'availability_view_date', value: `${this.dateStart},${this.dateEnd}` },
        ];
        if (this.propertyType) {
          params.push({ name: 'property_type', value: this.propertyType });
        }
        if (isEqual(this.params, params)) {
          return;
        }

        if (!this.isFirstLoad) {
          await this.$vuetify.goTo('#dashboard-availabilities-card');
        }

        this.params = [ ...params ];
        const { data } = await AvailabilityService.list({ token: this.token, params });
        this.items = data.result;
        this.total = data.total;
      } catch (error: any) {
        const notification = { ...error };
        this.addNotification(notification);
      } finally {
        this.loading = false;
        this.filterLoading = false;
        this.isFirstLoad = false;
      }
    }
  }
})
