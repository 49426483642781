import { ITextValue } from '@/types/common';
import { AvailabilityStatus, IAvailability } from '../types';

export * from './headers/availabilitiesHeadersData';
export * from './mapHelpers';

export const getAvailabilityStatusLabel = (status: AvailabilityStatus) => {
  switch (status) {
    case AvailabilityStatus.Active: return AvailabilityStatus.Active;
    case AvailabilityStatus.Inactive: return AvailabilityStatus.Inactive;
    case AvailabilityStatus.Leased: return AvailabilityStatus.Leased;
    case AvailabilityStatus.Sold: return AvailabilityStatus.Sold;
    case AvailabilityStatus.Pending: return 'Listing Pending';
    case AvailabilityStatus.Withdrawn: return AvailabilityStatus.Withdrawn;
  }
}

export const getAvailabilityStatusItems = (): ITextValue<AvailabilityStatus>[] => {
  return [
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Active), value: AvailabilityStatus.Active },
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Inactive), value: AvailabilityStatus.Inactive },
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Leased), value: AvailabilityStatus.Leased },
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Sold), value: AvailabilityStatus.Sold },
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Pending), value: AvailabilityStatus.Pending },
    { text: getAvailabilityStatusLabel(AvailabilityStatus.Withdrawn), value: AvailabilityStatus.Withdrawn },
  ];
};

export const getPublishedToWebByAvailabilityData = (availability: IAvailability) => {
  const { published_to_web, status, in_house, agency_type } = availability;

  if (!!published_to_web?.updated?.name && published_to_web.updated.name !== 'System') {
    return published_to_web;
  }

  return {
    value: status === 'Active' && in_house && agency_type === 'Exclusive',
    updated: {
      date: Date.now(),
      name: 'System',
      id: 0
    }
  };
};
