import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';
import { IMarket } from '@/bundles/Markets/interfaces/IMarket';

export class Market extends BaseArangoModel implements IMarket {
  active: boolean;
  name: string;
  order: number;

  constructor (payload: IMarket) {
    super(payload);
    this.active = payload.active;
    this.name = payload.name;
    this.order = payload.order;
  }
}
