import Member from '@/bundles/Members/models/Member';
import ProfileToMapper from '@/bundles/Members/mappers/ProfileToMapper';

export default class MemberToMapper {
  mapToModel (data: any): Member {
    const member = new Member();
    const memberProfile = new ProfileToMapper().mapToModel(data.profile);
    member.id = data.id;
    member.email = data.email;
    member.is_active = data.is_active;
    member.profile = memberProfile;
    member.roles = data.roles;
    member.created = data.created;
    member.created_by = data.created_by;
    member.updated = data.updated;
    member.updated_by = data.updated_by;
    member.member_company_file = data.member_company_file || {};
    member.licenses = data.licenses || [];
    member.compensations = data.compensations || [];
    member.legacy_member_id = data.legacy_member_id || null;
    member.name = `${memberProfile.first_name} ${memberProfile.last_name}`;
    member.primary_location = data.primary_location || null;
    member.team = data.team || null;
    if (data.last_activity !== undefined) {
      member.last_activity = data.last_activity;
    }
    return member;
  }
}
