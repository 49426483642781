import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const initialState = {
  tempInfo: null,
  tempLink: null,
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
}
