/**
 * Query params schema
 * { 1_param_name: 1_param_value, 2_param_name: 2_param_value, ..., n_param_name: n_param_value }
 * */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpMethods } from '@/bundles/Http/enum/HttpMethods';
import { Nullable } from '@/utils/types';

export interface RequestEntryParams {
  method: HttpMethods;
  url: string;
  query?: any;
  data?: any;
  token?: string;
  controller?: Nullable<AbortController>;
}

export default class HttpClient {
  readonly instance: AxiosInstance;

  constructor (
    config: AxiosRequestConfig,
    requestInterceptor: (config: AxiosRequestConfig) => AxiosRequestConfig,
    responseErrorInterceptor: (error: any) => any,
  ) {
    this.instance = axios.create({ ...config });
    this.instance.interceptors.request.use(requestInterceptor);
    this.instance.interceptors.response.use(
      (response: any) => response,
      responseErrorInterceptor,
    );
  }

  /**
   * Mage api request
   * @param {RequestEntryParams} options
   * @return {Promise<any>}
   * */
  public async request ({
    method,
    url,
    query = {},
    data = {},
    token,
    controller,
  }: RequestEntryParams): Promise<any> {
    let headers = {};

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    let config = {
      method,
      url,
      data,
      params: query,
      headers,
    }

    if (controller) {
      config = Object.assign(config, { signal: controller.signal });
    }

    const response = await this.instance(config);

    if (!response || response.status >= 400) {
      throw response;
    }

    return response.data;
  }
}
