









import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'AssociatedItem',

  props: {
    item: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
  },
});
