var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemEntry.deals,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var tempItem = ref.item;
return [_c('v-toolbar',{staticClass:"broker-detail-toolbar",attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"body-2"},[_c('v-btn',{attrs:{"text":"","to":_vm.getAccountRoute({ name: 'deal', params: { id: tempItem._key }})}},[_vm._v(" "+_vm._s(tempItem.name)+" ")])],1),_c('div',{staticClass:"btn-deal-actions"},[_c('v-btn',{staticClass:"btn-deal-preview",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deal:show', tempItem)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('router-link',{staticClass:"btn-deal-open mr-2",staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.getAccountRoute({ name: 'deal', params: { id: tempItem._key }}),"target":"_blank"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1)],1)]}},{key:"item.consideration",fn:function(ref){
var tempItem = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrencyNoDecimal")(tempItem.consideration))+" ")]}},{key:"item.brokerGross",fn:function(ref){
var tempItem = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(tempItem.brokerGross))+" ")]}},{key:"item.brokerNet",fn:function(ref){
var tempItem = ref.item;
return [(_vm.reportTypeEntry !=='gross' )?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(tempItem.brokerNet)))]):_vm._e()]}},{key:"item.buildingSize",fn:function(ref){
var tempItem = ref.item;
return [_c('BuildingSizeLabel',{attrs:{"value":tempItem.buildingSize}})]}},{key:"item.landSize",fn:function(ref){
var tempItem = ref.item;
return [_c('LandSizeLabel',{attrs:{"value":tempItem.landSize}})]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"sc-data-footer"},[_c('td',{staticStyle:{"min-width":"150px"}}),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyNoDecimal")(_vm.itemEntry.consideration))+" ")]),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.itemEntry.brokerGross))+" ")]):_vm._e(),(_vm.brokerGrossShowCommission)?_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[(_vm.reportTypeEntry !== 'gross')?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.itemEntry.brokerNet)))]):_vm._e()]):_vm._e(),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('BuildingSizeLabel',{attrs:{"value":_vm.itemEntry.buildingSize}})],1),_c('td',{staticClass:"text-right",staticStyle:{"width":"200px"}},[_c('LandSizeLabel',{attrs:{"value":_vm.itemEntry.landSize}})],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }