import instance from '../instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/markets';

export default {
  getMarkets: async function (token) {
    try {
      return await instance.get(`${resource}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getSubMarkets: async function (token) {
    try {
      return await instance.get(`${resource}/subMarkets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getBusinessParks: async function (token) {
    try {
      return await instance.get(`${resource}/businessParks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
