import { IAddress } from '@/utils/interfaces';

export class Address implements IAddress {
  street: string;
  street2: string;
  city: string;
  state: string;
  postal: string;
  county: string;
  country: string;

  constructor (address: Record<string, any>) {
    this.street = address.street || '';
    this.street2 = address.street2 || '';
    this.city = address.city || '';
    this.state = address.state || '';
    this.postal = address.postal || '';
    this.county = address.county || '';
    this.country = address.country || '';
  }
}
