import { axiosQueue } from '@/bundles/Auth/services/AxiosQueue';
import axios, { AxiosRequestConfig } from 'axios';
import { REQUEST_CANCELLED_MESSAGE } from '@/bundles/Http/mappers/ErrorMapper';

export async function responseErrorInterceptor (error: any) {
  if (axios.isCancel(error)) {
    throw Error(REQUEST_CANCELLED_MESSAGE);
  }
  const ignoreUrls = ['auth/sign-in', 'auth/account-sign-in'];
  if (error.response?.status === 401 && !ignoreUrls.some((url) => error.response?.request?.responseURL?.includes(url))) {
    return new Promise((resolve, reject) => axiosQueue.addToQueue(error.response.config as AxiosRequestConfig, resolve, reject));
  }
  throw error;
}
