import * as constants from './comparable-types'

export default {
  [constants.PERSIST_ENTERED_INFO] (state, payload) {
    state.tempInfo = payload.info
    state.tempLink = payload.link
  },
  [constants.SET_COMPARABLE_FULL_LIST] (state, payload) {
    state.comparablesFullList = payload
  },
}
