import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getInitialAdminStoreState } from '@/store/modules/admin/helpers/getInitialAdminStoreState';

export default {
  namespaced: true,
  state: getInitialAdminStoreState(),
  getters,
  mutations,
  actions,
}
