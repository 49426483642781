import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/account/compensation';

export default {
  list: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/list${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ payload, token }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ payload, token, id }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
