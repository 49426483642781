import { eventBus } from '@/eventbus/eventbus';
import {
  capitalize,
  capitalizeWords,
  formatCurrency,
  formatCurrencyNoDecimal,
  formatDate,
  formatPercent,
  formatRawNumber,
  getDateDiff
} from './filters';
import { pipelineStages } from '@/bundles/Pipelines/types/stageEnum';
import { getJobFunction } from '@/bundles/Members/helpers/jobFunction';
import { isArray, uniq } from 'lodash';
import axios from 'axios';
import { getOccupierLeaseTermLength } from '@/bundles/Occupier/helpers';
import { getPropertyExpenses } from '@/bundles/Property/helpers/expenses/getPropertyExpenses';
import { getPipelinesMonthsOpen } from '@/bundles/Pipelines/helpers/pipelineDaysOpen';
import { getComparableSalesPricePerSF } from '@/bundles/Common/helpers/export/comparables';
import i18n from '@/bundles/Localization';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';

export function formatPhone (value) {
  // US national number format: (213) 373-4253
  const numbers = [
    {
      pattern: /^([0-9]{3})-?([0-9]{3})-?([0-9]{4})(ext|x)?([0-9]+)?$/i,
      // starts with 3 numbers, another 3 numbers, then 4 numbers. we either have ext or x, and then some more numbers (though we don't know how many)
      replace: '($1) $2-$3 $4 $5', // If $4 and $5 aren't there we remove the extra space with the .trim() call at the end.
    },
    {
      name: 'phone12',
      pattern: /(\+[0-9]{2})([0-9]{2})([0-9]{4})([0-9]{4})/,
      // a group of two with a plus, another group of two, and then 2 groups of 4.
      replace: '$1 $2 $3 $4',
    },
  ]

  const notAllowedChars = [/\./g, /^\+?1-?/g, /[()\-\s]/g]
  const removeNotAllowed = function (str, n) {
    n.forEach((item) => {
      str = str.replace(item, '')
    })
    return str
  }

  let phone = removeNotAllowed(value, notAllowedChars)
  numbers.forEach((rep) => {
    if (phone.match(rep.pattern)) {
      phone = phone.replace(rep.pattern, rep.replace)
    }
  })
  return phone.trim()
}

export function flatTree (arr) {
  return arr.reduce((acc, item) => {
    const { children, ...bareItem } = item
    acc.push(bareItem)

    if (Array.isArray(children)) {
      acc.push(...flatTree(children));

      return acc;
    }

    return acc;
  }, []);
}

function replaceAmpersand (value) {
  switch (true) {
    case typeof value === 'boolean':
    case typeof value === 'number':
      return value;
    case typeof value === 'string':
      return value.replace('&', 'ampersand');
    case Array.isArray(value):
      return value.map((item) => replaceAmpersand(item));
    default:
      return '';
  }
}

export function generateObjectParams (params) {
  if (!params || !params.length) {
    return '';
  }
  return params
    // can`t be just !param.value, coz value can be bool false
    .filter(param => param.value !== undefined || param.value !== null)
    .reduce((accum, value) => ({
      ...accum,
      [value.name]: replaceAmpersand(value.value),
    }), {});
}

export function generateParams (params) {
  if (!params || !params.length) {
    return '';
  }
  return params
    .filter(param => isArray(param.value) ? param.value.length : true)
    .map((param, index) => {
      let query = index === 0 ? '?' : '&';
      query += `${param.name}=`;
      query += handleParamValue(param.value);
      return query;
    })
    .reduce((accum, item) => accum + item);
}

function handleParamValue (value) {
  switch (true) {
    case typeof value === 'boolean':
    case typeof value === 'number':
      return value;
    case typeof value === 'string':
      return value.replace('&', 'ampersand');
    case Array.isArray(value):
      return value.join(',').replace('&', 'ampersand');
    default:
      return '';
  }
}

export function objectToArrayOfParams (obj) {
  let arrayOfParams = []
  const litsOfExeptionKeys = ['buildingSf', 'landAcres']
  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      let param
      if (typeof obj[key] === 'string' || key === 'locationType') {
        param = {
          name: key,
          value: obj[key],
          label: key,
        }
      } else if (
        litsOfExeptionKeys.includes(key) &&
        obj[key]?.filter((item) => !!item).length
      ) {
        param = obj[key][0]
          ? [
            {
              name: `${key}Min`,
              value: obj[key][0],
            },
          ]
          : []
        param =
          obj[key][1] && param.length
            ? [...param, { name: `${key}Max`, value: obj[key][1] }]
            : obj[key][1]
              ? [{ name: `${key}Max`, value: obj[key][1] }]
              : param
      } else if (
        Array.isArray(obj[key]) &&
        obj[key].filter((item) => !!item).length
      ) {
        param = {
          name: key,
          value: obj[key].join(','),
        }
      } else if (
        typeof obj[key] === 'object' &&
        objectToArrayOfParams(obj[key]).length
      ) {
        param = objectToArrayOfParams(obj[key])
      } else if (typeof obj[key] === 'boolean' && obj[key]) {
        param = {
          name: key,
          value: true,
        }
      }
      arrayOfParams = param ? [...arrayOfParams, param] : arrayOfParams
    }
  }
  return arrayOfParams.flat()
}

export function arrayCompare ({ array1, array2 }) {
  let counter = 0
  if (array1.length !== array2.length) return false
  array1.forEach((item) => {
    const temp = array2.find((elem) => elem === item)
    counter += temp ? 1 : 0
  })
  return counter === array1.length
}

export function downloadFile ({ url, filename, type }) {
  axios({
    method: 'POST',
    url: `${process.env.VUE_APP_V2_API_URL}/account/download`,
    data: { url, content_type: type }
  }).then((response) => {
    if (!response.data?.data) {
      return;
    }
    const FileSaver = require('file-saver');
    const file = new Blob([new Uint8Array(response.data.data)], {
      type: type,
    });
    FileSaver.saveAs(file, filename);
  }).catch();
}

export function downloadFileFromBase64 (filename, contentBytes, type) {
  const FileSaver = require('file-saver');
  const file = new Blob([Uint8Array.from(Buffer.from(contentBytes, 'base64'))], {
    type,
  });
  FileSaver.saveAs(file, filename);
}

function getDealPaidAmount (invoice, returnType) {
  let result = {
    due: 0,
    paid: 0,
    outstanding: 0
  };
  if (invoice && invoice.length) {
    // if (payments && payments.length) {
    invoice.forEach(i => {
      result.due += i.amount;
      result.paid += calculatePaidAmount(i.payments);
      result.outstanding = result.due - result.paid;
    })
    // }
  }
  return returnType === 'due' ? result.due : returnType === 'paid' ? result.paid : result.outstanding;
}

function calculatePaidAmount (payments) {
  return payments
    .map((payment) => payment.amount)
    .reduce((accum, value) => accum + value, 0);
}

export function generateDataForExport ({ type, arrayOfItems, formatters, includePhotos = {} }) {
  const moment = require('moment')
  const numeral = require('numeral')
  const { timeZone } = require('../settings')
  const buildingSizeMeasure = formatters.buildingSize(0).split(' ')[1];
  const landSizeMeasure = formatters.landSize(0).split(' ')[1];

  switch (type) {
    case 'occupiers':
      return arrayOfItems.map((item) => {
        const columns = { };

        if (includePhotos) {
          columns.Img = item.logo || '';
        }

        return {
          ...columns,
          'Name': item.name,
          'Address': item.property ? `${item.property.address.street}` : '',
          'Address 2': item.property && item.property.address.street2 ? `${item.property.address.street2}` : '',
          'City': item.property ? `${item.property.address.city}` : '',
          'State': item.property ? `${item.property.address.state}` : '',
          'Postal': item.property ? `${item.property.address.postal}` : '',
          [`Square Feet (${buildingSizeMeasure})`]: item.square_feet ? formatRawNumber(formatters.buildingSize(item.square_feet)) : '',
          [`Land Size (${landSizeMeasure})`]: item.land_size ? formatRawNumber(formatters.landSize(item.land_size)) : '',
          'Occupancy Type': item.occupancy_type,
          'Lease Term': getOccupierLeaseTermLength(item),
          'Commencement Date': item.ofRecord && item.ofRecord.lease_commencement
            ? formatDate(moment(item.ofRecord.lease_commencement)
            .tz(timeZone))
            : '',
          'Lease Expiration': item.ofRecord && item.ofRecord.lease_expiration
            ? formatDate(moment(item.ofRecord.lease_expiration)
            .tz(timeZone))
            : '',
          'Original Possession Date': item.possession_date
            ? formatDate(moment(item.possession_date)
            .tz(timeZone)) : '',
        }
      })
    case 'companies':
      return arrayOfItems.map((item) => {
        const columns = {};

        if (includePhotos) {
          columns.Img = item.logo || '';
        }

        return {
          ...columns,
          'Company Name': item.name,
          'Address': item.address.street,
          'Address 2': item.address.street2,
          'City': item.address.city,
          'State': item.address.state,
          'Postal': item.address.postal,
          'URL': item.url,
          'Industry Group': item.industry_group,
          'Year Established': item.year_established || '',
          'Employees': item.metrics?.employees,
          'Annual Revenue': item.metrics?.annualRevenue,
          'Longitude': item.coordinates?.lng || item.coordinates?.long || '',
          'Latitude': item.coordinates?.lat || ''
        }
      })
    case 'properties':
      return arrayOfItems.map((item) => {
        const columns = {};

        if (includePhotos) {
          columns.Img = item.photo.url || '';
        }

        return {
          ...columns,
          'Name': item.name,
          'Address': `${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.postal}`,
          'Property Type': item.type,
          'Submarket Cluster': item.market.submarket_cluster,
          'Business Park': item.market.business_park,
          [`Building Size (${buildingSizeMeasure})`]: item.size.building.total ? formatRawNumber(formatters.buildingSize(item.size.building.total)) : '',
          [`Land Size (${landSizeMeasure})`]: formatRawNumber(formatters.landSize(item.size.land.total)),
          'Year Built': item.year_built,
          'True Owner': item.owner && item.owner.length
            ? item.owner.map((elem) => elem.name).join('')
            : '',
          'Listing Company': uniq(item.broker?.map(item => item.company?.name))?.join(', '),
          'Listing Company Contact': item.broker?.map(item => item.name)?.join(', '),
          'Updated': moment(item.updated.date).format('YYYY-MM-DD'),
          'Updated By': `${item.updated.name}`,
          'Created': moment(item.created.date).format('YYYY-MM-DD'),
          'Created By': `${item.created.name}`,
          'Longitude': item.coordinates?.lng || item.coordinates?.long || '',
          'Latitude': item.coordinates?.lat || '',
        }
      })
    case 'contacts':
      return arrayOfItems.map((item) => {
        const columns = {};

        if (includePhotos) {
          columns.Img = item.img || '';
        }

        return {
          ...columns,
          'First Name': `${item.first_name}`,
          'Last Name': `${item.last_name}`,
          'Title': item.title,
          'Company Name': item.company ? item.company.name : '',
          'Street':
            item.company && item.company.address
              ? `${item.company.address.street}   `
              : '',
          'City':
            item.company && item.company.address
              ? `${item.company.address.city}   `
              : '',
          'State/Province': item.company && item.company.address
            ? `${item.company.address.state}   `
            : '',
          'Postal': item.company && item.company.address
            ? `${item.company.address.postal}   `
            : '',
          'Email': item.email,
          'Direct': `${ item.phone.direct ? formatPhone(item.phone.direct) : '' }`,
          'Office': `${ item.phone.office ? formatPhone(item.phone.office) : '' }`,
          'Mobile': `${ item.phone.mobile ? formatPhone(item.phone.mobile) : '' }`,
          'Longitude': item.coordinates?.lng || item.coordinates?.long || '',
          'Latitude': item.coordinates?.lat || ''
        }
      })
    case 'availabilities':
      return arrayOfItems.map((item) => {
        let clear_height = 'N/A';
        const pricePSF = item.available_space && item.sale?.asking_price > 0 ? `${formatCurrency(numeral(parseInt(item.sale.asking_price) / parseInt(item.available_space)))}` : '';
        const { min, max } = (item.details?.building?.ceiling_height || {});
        if (min || max) {
          clear_height = formatters.buildingDimension ? `${formatters.buildingDimension(min)} - ${formatters.buildingDimension(max)}` : `${min} - ${max}`;
        }

        const columns = {};

        if (includePhotos) {
          columns.Img = item.photo.url || '';
        }

        return {
          ...columns,
          'Name': `${item.property.name}`,
          'Address': `${item.property.address.street}`,
          'City': `${item.property.address.city}`,
          'State': `${item.property.address.state}`,
          'Postal': `${item.property.address.postal}`,
          [`Available Space (${buildingSizeMeasure})`]: item.available_space ? formatRawNumber(formatters.buildingSize(item.available_space)) : '',
          [`Divisible Space (${buildingSizeMeasure})`]: item.divisible_space ? formatRawNumber(formatters.buildingSize(item.divisible_space)) : '',
          [`Building Size (${buildingSizeMeasure})`]: item.building_size ? formatRawNumber(formatters.buildingSize(item.building_size)) : '',
          'Construction Status': item.property.construction_status,
          'Office Space': item.office_space,
          'Clear Height': clear_height,
          '# Dock Doors': item.details?.loading_doors?.doors?.truck_level?.total,
          '# Drive-In Doors': item.details?.loading_doors?.doors?.ground_level?.total,
          'Sprinklers': item.sprinklers,
          [`Price (${buildingSizeMeasure})`]: item.sale?.asking_price > 0 ? formatRawNumber(formatCurrencyNoDecimal(item.sale.asking_price)) : '',
          'Price PSF ($/SF)': pricePSF ? formatRawNumber(pricePSF) : '',
          'Lease Rate ($)': formatRawNumber(formatCurrency(item.lease_rate || '')),
          'Lease Type': item.lease_type ? `${item.lease_type}` : '',
          'Operating Expenses': getPropertyExpenses(item.property.expenses),
          'Date Available': item.date_available ? formatDate(item.date_available) : '',
          'Date on Market': item.date_on_market ? formatDate(item.date_on_market) : '',
          'Company 1': item.brokers.length > 0 ? item.brokers[0].company.name : '',
          'Broker 1': item.brokers.length > 0 ? item.brokers[0].name : '',
          'Email 1': item.brokers.length > 0 ? item.brokers[0].email : '',
          'Phone 1': item.brokers.length > 0 && item.brokers[0].phone?.direct ? formatPhone(item.brokers[0].phone?.direct) : '',
          'Company 2': item.brokers.length > 1 ? item.brokers[1].company.name : '',
          'Broker 2': item.brokers.length > 1 ? item.brokers[1].name : '',
          'Email 2': item.brokers.length > 1 ? item.brokers[1].email : '',
          'Phone 2': item.brokers.length > 1 && item.brokers[1].phone?.direct ? formatPhone(item.brokers[1].phone?.direct) : '',
          'Company 3': item.brokers.length > 2 ? item.brokers[2].company.name : '',
          'Broker 3': item.brokers.length > 2 ? item.brokers[2].name : '',
          'Email 3': item.brokers.length > 2 ? item.brokers[2].email : '',
          'Phone 3': item.brokers.length > 2 && item.brokers[2].phone?.direct ? formatPhone(item.brokers[2].phone?.direct) : '',
          'Company 4': item.brokers.length > 3 ? item.brokers[3].company.name : '',
          'Broker 4': item.brokers.length > 3 ? item.brokers[3].name : '',
          'Email 4': item.brokers.length > 3 ? item.brokers[3].email : '',
          'Phone 4': item.brokers.length > 3 && item.brokers[3].phone?.direct ? formatPhone(item.brokers[3].phone?.direct) : '',
          'Company 5': item.brokers.length > 4 ? item.brokers[4].company.name : '',
          'Broker 5': item.brokers.length > 4 ? item.brokers[4].name : '',
          'Email 5': item.brokers.length > 4 ? item.brokers[4].email : '',
          'Phone 5': item.brokers.length > 4 && item.brokers[4].phone?.direct ? formatPhone(item.brokers[4].phone?.direct) : '',
          'True Owner': item.true_owner.length ? uniq(item.true_owner?.map(item => item.company?.name))?.join(', ') : '',
          'Longitude': item.coordinates?.lng || item.coordinates?.long || '',
          'Latitude': item.coordinates?.lat || ''
        }
      })
    case 'comparables':
      return arrayOfItems.map((item) => {
        const brokers = [...item.contacts.buyer_agent, ...item.contacts.seller_agent, ...item.contacts.landlord_agent, ...item.contacts.tenant_agent, ...item.contacts.listing_agent];

        const columns = { ID: `${item._key}` };

        if (includePhotos) {
          columns.Img = item.photo.url || '';
        }

        return {
          ...columns,
          'Property Name': `${item.name}`,
          'Address': `${item.address.street}`,
          'City': `${item.address.city}`,
          'State': `${item.address.state}`,
          'Postal': `${item.address.postal || ''}`,
          Type: `${item.transaction_type === 'lease' ? 'Lease' : 'Sale'}`,
          'Property Type': `${item.property_type}`,
          'Submarket Cluster': item.filterMarketOne ? item.filterMarketOne : '',
          Submarket: item.filterMarketTwo ? item.filterMarketTwo : '',
          [i18n.t(LocalizationEnum.comparable_primary_use)]: item.primary_use ? item.primary_use : '',
          [`Building Size (${buildingSizeMeasure})`]: item.property.building_size ? formatRawNumber(formatters.buildingSize(item.property.building_size)) : '',
          [`Comp Space Size (${buildingSizeMeasure})`]: item.transaction.total_square_feet ? formatRawNumber(formatters.buildingSize(item.transaction.total_square_feet)) : '',
          [`Land Size (${landSizeMeasure})`]: formatRawNumber(formatters.landSize(item.transaction.total_acres)),
          'Sale Price ($)': item.transaction_type === 'sale'
            ? item.sale_price_total ? formatRawNumber(formatCurrencyNoDecimal(item.sale_price_total)) : ``
            : '',
          'Sale Price Per SF ($)': formatRawNumber(getComparableSalesPricePerSF(item)),
          'Lease Rate Per SF': item.average_rent_sf ? formatRawNumber(formatCurrency(item.average_rent_sf)) : '',
          'Transaction Type': item.lease_type,
          'Lease Commencement': formatDate(item.lease_commencement),
          'Lease Expiration': formatDate(item.lease_expiration),
          'Transaction Date': formatDate(item.transaction_date),
          'TI Landlord / SF': item.tenant_improvements.landlord.persf
            ? `${item.tenant_improvements.landlord.persf}`
            : ``,
          'TI Tenant / SF': item.tenant_improvements.tenant.persf
            ? `${item.tenant_improvements.tenant.persf}`
            : ``,
          'Buyer/Tenant Company':
            item.transaction_type === 'sale'
              ? item.companies.buyer
                ? `${item.companies.buyer.name}`
                : ``
              : item.companies.tenant
                ? `${item.companies.tenant.name}`
                : ``,
          'Buyer/Tenant Contact':
            item.transaction_type === 'sale'
              ? item.contacts.buyer.length
                ? `${item.contacts.buyer[0].first_name} ${item.contacts.buyer[0].last_name}`
                : ``
              : item.contacts.tenant.length
              ? `${item.contacts.tenant[0].first_name} ${item.contacts.tenant[0].last_name}`
              : ``,
          'Seller/Landlord Company':
            item.transaction_type === 'sale'
              ? item.companies.seller
                ? `${item.companies.seller.name}`
                : ``
              : item.companies.landlord
                ? `${item.companies.landlord.name}`
                : ``,
          'Seller/Landlord Contact':
            item.transaction_type === 'sale'
              ? item.contacts.seller.length
                ? `${item.contacts.seller[0].first_name} ${item.contacts.seller[0].last_name}`
                : ``
              : item.contacts.landlord.length
                ? `${item.contacts.landlord[0].first_name} ${item.contacts.landlord[0].last_name}`
                : ``,
                'Company 1': brokers.length > 0 ? brokers[0].company?.name : '',
                'Broker 1': brokers.length > 0 ? brokers[0].name : '',
                'Email 1': brokers.length > 0 ? brokers[0].email : '',
                'Phone 1': brokers.length > 0 && brokers[0].phone?.direct ? formatPhone(brokers[0].phone?.direct) : '',
                'Company 2': brokers.length > 1 ? brokers[1].company?.name : '',
                'Broker 2': brokers.length > 1 ? brokers[1].name : '',
                'Email 2': brokers.length > 1 ? brokers[1].email : '',
                'Phone 2': brokers.length > 1 && brokers[1].phone?.direct ? formatPhone(brokers[1].phone?.direct) : '',
                'Company 3': brokers.length > 2 ? brokers[2].company?.name : '',
                'Broker 3': brokers.length > 2 ? brokers[2].name : '',
                'Email 3': brokers.length > 2 ? brokers[2].email : '',
                'Phone 3': brokers.length > 2 && brokers[2].phone?.direct ? formatPhone(brokers[2].phone?.direct) : '',
                'Company 4': brokers.length > 3 ? brokers[3].company?.name : '',
                'Broker 4': brokers.length > 3 ? brokers[3].name : '',
                'Email 4': brokers.length > 3 ? brokers[3].email : '',
                'Phone 4': brokers.length > 3 && brokers[3].phone?.direct ? formatPhone(brokers[3].phone?.direct) : '',
                'Company 5': brokers.length > 4 ? brokers[4].company?.name : '',
                'Broker 5': brokers.length > 4 ? brokers[4].name : '',
                'Email 5': brokers.length > 4 ? brokers[4].email : '',
                'Phone 5': brokers.length > 4 && brokers[4].phone?.direct ? formatPhone(brokers[4].phone?.direct) : '',
                'Longitude': item.coordinates?.lng || item.coordinates?.long || '',
                'Latitude': item.coordinates?.lat || '',
                'Updated By': item.updated.name,
                'Updated Date': formatDate(item.updated.date),
                'Created By': item.created.name,
                'Created Date': formatDate(item.created.date)
        }
      })
    case 'deal':
      return arrayOfItems.map(item => {
        const columns = { ID: `${item._key}` };

        if (includePhotos) {
          columns.Img = item.photo.url || '';
        }

        return {
          ...columns,
          'Name': `${item.name}`,
          'Property Name': `${item.property.name}`,
          'Property Address': `${item.property.address.street}, ${item.property.address.city} ${item.roperty.address.state} ${item.comparable.property.address.postal}`,
          'Status': `${item.status}`,
          [`Building Size (${buildingSizeMeasure})`]: item.transaction.total_square_feet ? formatRawNumber(formatters.buildingSize(item.transaction.total_square_feet)) : '',
          [`Land Size (${landSizeMeasure})`]: formatRawNumber(formatters.landSize(item.transaction.total_acres)),
          'Property Type': `${item.property.type}`,
          'Transaction Type': `${item.transaction.transaction_type}`,
          'Landlord/Seller': `${ item.companies.landlord ? item.companies.landlord.name : item.companies.seller.name }`,
          'Tenant/Buyer': `${ item.companies.tenant ? item.companies.tenant.name : item.companies.buyer.name}`,
          'Billing Company': `${item.contacts.billing && item.contacts.billing.length ? item.contacts.billing[0].company.name : ''}`,
          'Primary Agent': `${item.brokers?.[0] ? item.brokers[0].name ? item.brokers[0].name : `${item.brokers[0].first_name} ${item.brokers[0].last_name}` : ''}`,
          'Total Commission': item.transaction ? item.transaction.total_commission : '',
          'In-House Gross': item.transaction ? item.transaction.inhouse_gross : '',
          'Consideration': item.transaction ? item.transaction.consideration : '',
          'Due': getDealPaidAmount(item.invoice, 'due'),
          'Paid': getDealPaidAmount(item.invoice, 'paid'),
          'Outstanding': getDealPaidAmount(item.invoice, 'outstanding'),
          'Transaction Date': `${formatDate(item.transaction.transaction_date)}`,
          'Longitude': item.comparable?.property?.coordinates?.lng || item.comparable?.property?.coordinates?.long || '',
          'Latitude': item.comparable?.property?.coordinates?.lat || '',
          'Updated': `${formatDate(item.updated.date)} ${item.updated.name}`,
          'Created': `${formatDate(item.created.date)} ${item.created.name}`
        }
      })
    case 'brokerDeal':
      return arrayOfItems.map(item => {
        const columns = { ID: `${item._key}` };

        if (includePhotos) {
          columns.Img = item.photo.url || '';
        }

        return {
          ...columns,
          'Name': `${item.name}`,
          'Property Name': `${item.property.name}`,
          'Property Address': `${item.property.address.street}, ${item.property.address.city} ${item.property.address.state} ${item.property.address.postal}`,
          'Status': `${item.status}`,
          [`Building Size (${buildingSizeMeasure})`]: item.transaction.total_square_feet ? formatRawNumber(formatters.buildingSize(item.transaction.total_square_feet)) : '',
          [`Land Size (${landSizeMeasure})`]: formatRawNumber(formatters.landSize(item.transaction.total_acres)),
          'Property Type': `${item.property.type}`,
          'Transaction Type': `${item.transaction.transaction_type}`,
          'Landlord/Seller': `${ item.companies.landlord ? item.companies.landlord.name : item.companies.seller ? item.companies.seller.name : '' }`,
          'Tenant/Buyer': `${ item.companies.tenant ? item.companies.tenant.name : item.companies.buyer ? item.companies.buyer.name : '' }`,
          'Billing Company': `${item.contacts.billing && item.contacts.billing.length && item.contacts.billing[0].company ? item.contacts.billing[0].company.name : ''}`,
          'Primary Agent': `${item.brokers?.[0] ? item.brokers[0].name ? item.brokers[0].name : `${item.brokers[0].first_name} ${item.brokers[0].last_name}` : ''}`,
          'Total Commission ($)': item.transaction ? formatRawNumber(formatCurrency(item.transaction.total_commission)) : '',
          'Consideration ($)': item.transaction ? formatRawNumber(formatCurrency(item.transaction.consideration)) : '',
          'Due': getDealPaidAmount(item.invoice, 'due'),
          'Paid': getDealPaidAmount(item.invoice, 'paid'),
          'Outstanding': getDealPaidAmount(item.invoice, 'outstanding'),
          'Primary Broker Name': item?.primary_broker?.name || 'N/A',
          'In-House Gross ($)': item.transaction ? formatRawNumber(formatCurrency(item.transaction.inhouse_gross)) : 'N/A',
          'Outside Gross ($)': formatRawNumber(getDealTotalOutsideBrokerGross(item)),
          'In-House Net ($)': formatRawNumber(getDealTotalBrokerNetByType(item, 'inhouseBrokers')),
          'Outside Net ($)': formatRawNumber(getDealTotalBrokerNetByType(item, 'outsideBrokers')),
          'Transaction Date': `${formatDate(item.transaction.transaction_date)}`,
          'Longitude': item.comparable?.property?.coordinates?.lng || item.comparable?.property?.coordinates?.long || '',
          'Latitude': item.comparable?.property?.coordinates?.lat || '',
          'Updated': `${formatDate(item.updated.date)} ${item.updated.name}`,
          'Created': `${formatDate(item.created.date)} ${item.created.name}`
        }
      })
    case 'requirements':
      return arrayOfItems.map(item => {
        const columns = { ID: getValueOrPlaceholder(item._key) };

        if (includePhotos) {
          columns.Img = item.logo || '';
        }

        return {
          ...columns,
          'Project Name': getValueOrPlaceholder(item.name),
          'Company': getValueOrPlaceholder(item.company?.name),
          [`Building Size Min (${buildingSizeMeasure})`]: item.size_min ? formatRawNumber(formatters.buildingSize(item.size_min)) : '',
          [`Building Size Max (${buildingSizeMeasure})`]: item.size_max ? formatRawNumber(formatters.buildingSize(item.size_max)) : '',
          'Transaction Min ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.transaction_min)),
          'Transaction Max ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.transaction_max)),
          [`Office Min (${buildingSizeMeasure})`]: getValueOrPlaceholder(formatRawNumber(formatters.buildingSize(item.industrial?.size_min))),
          [`Office Max (${buildingSizeMeasure})`]: getValueOrPlaceholder(formatRawNumber(formatters.buildingSize(item.industrial?.size_max))),
          'Property Type': getValueOrPlaceholder(item.property_type),
          'Use Type': getValueOrPlaceholder(item.industry),
          'Transaction Type': getValueOrPlaceholder(item.transaction_type),
          'Target Occ. Date': getDateValueOrPlaceholder(item.target_occupancy_date),
          'Status': getValueOrPlaceholder(item.status),
          'Search Area': item.search_area && item.search_area.length ? item.search_area.join(', ') : '',
          'Comments': item.comments,
          'Procurring Broker': `${item.brokers && item.brokers.length ? item.brokers[0].name : ''}`,
          'Updated': getDateValueOrPlaceholder(item.updated.date),
          'Created': getDateValueOrPlaceholder(item.created.date)
        }
      })
    case 'savedSets':
      return arrayOfItems.map(item => {
        return {
          'ID': getValueOrPlaceholder(item._key),
          'Name': getValueOrPlaceholder(item.name),
          'Set Type': getValueOrPlaceholder(item.class === 'filterSet' ? 'Filter Set' : item.class === 'static' ? 'Static Set' : ''),
          'Record Type': getValueOrPlaceholder(item.type),
          'Number Of Records': getValueOrPlaceholder(item.class === 'filterSet' ? item.filterCount?.length : item.count),
          'Privacy': getPrivacyLabel(item.privacy),
          'Updated': getDateValueOrPlaceholder(item.updated.date),
          'Created': getDateValueOrPlaceholder(item.created.date)
        }
      })
    case 'projects':
      return arrayOfItems.map(item => {
        return {
          'ID': getValueOrPlaceholder(item._key),
          'Project Name': getValueOrPlaceholder(item.name),
          'Move In Date': getDateValueOrPlaceholder(item.moveInDate),
          'Construction Type': getValueOrPlaceholder(item.constructionType),
          'Building Type': getValueOrPlaceholder(item.buildingType),
          'Updated': getDateValueOrPlaceholder(item.updated.date),
          'Created': getDateValueOrPlaceholder(item.created.date)
        }
      })
    case 'pipelines':
      return arrayOfItems.map(item => {
        const columns = { ID: getValueOrPlaceholder(item._key) };

        if (includePhotos) {
          columns.Img = item.logo || '';
        }

        return {
          ...columns,
          'Company Name': getValueOrPlaceholder(item.company?.[0]?.name),
          'Deal Name': getValueOrPlaceholder(item.deal_name),
          'Category': getValueOrPlaceholder(item.category),
          // TODO: need to show building size or land size based on property type
          'Size (SF)': getValueOrPlaceholder(formatRawNumber(item.size || '')),
          'Consideration ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.consideration)),
          'Commission (%)': item.commission ? getPercentValueOrPlaceholder(item.commission) : getPercentValueOrPlaceholder(),
          'Probability (%)': item.probability ? getPercentValueOrPlaceholder(item.probability) : getPercentValueOrPlaceholder(),
          'Close Date': getDateValueOrPlaceholder(item.close_date),
          '% Of Deal': item.broker_percentage ? getPercentValueOrPlaceholder(item.broker_percentage) : getPercentValueOrPlaceholder(),
          'In House Gross ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.in_house_gross)),
          'Broker Gross ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.broker_gross)),
          'Broker Gross Prob ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.broker_gross_probability)),
          'Pipeline Stage': getValueOrPlaceholder(getPipelineStage(item.stage)),
          'Months Open': getValueOrPlaceholder(getPipelinesMonthsOpen(item.daysOpen)),
          'Updated': getDateValueOrPlaceholder(item.updated.date),
          'Created': getDateValueOrPlaceholder(item.created.date)

        }
      })
    case 'members':
      return arrayOfItems.map(item => {
        const licenses = getValueOrPlaceholder(item.licenses);
        const isLicensesExists = Array.isArray(licenses);
        const licensesStatus = isLicensesExists ? licenses.map(license => {
          const isExpired = getDateDiff(license.date_expiration).milliseconds < 0;

          if (isExpired) {
            return `Expired on ${license.date_expiration}`;
          }

          return `${license.status} (${license.date_expiration})`;
        }).join(', ') : licenses;
        const roles = getValueOrPlaceholder(item.roles.map(role => capitalizeWords(role.name)));

        const columns = { ID: getValueOrPlaceholder(item.id) };

        if (includePhotos) {
          columns.Img = item.img || '';
        }

        return {
          ...columns,
          'First Name': item.profile.first_name,
          'Last Name': item.profile.last_name,
          'Job Function': getValueOrPlaceholder(getJobFunction(item.member_company_file?.job_function)),
          'Start Date': getValueOrPlaceholder(item.member_company_file.date_start),
          'Status': item.is_active ? 'Active' : 'Inactive',
          'Employee Type': getValueOrPlaceholder(capitalize(item.member_company_file?.employee_type)),
          'Employee Category': getValueOrPlaceholder(capitalize(item.member_company_file?.employee_category)),
          'Salary Normal ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.salary?.normal)),
          'Salary Hourly ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.salary?.hourly)),
          'Salary Monthly ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.salary?.monthly)),
          'Salary Annual ($)': formatRawNumber(getCurrencyValueOrPlaceholder(item.salary?.annual)),
          'Roles': Array.isArray(roles) ? roles.join(', ') : roles,
          'License Type': isLicensesExists ? licenses.map(license => license.type).join(', ') : licenses,
          'License State': isLicensesExists ? licenses.map(license => license.state).join(', ') : licenses,
          'License Status (Exp)': licensesStatus,
          'Updated': getDateValueOrPlaceholder(item.updated),
          'Created': getDateValueOrPlaceholder(item.created)
        }
      })
    case 'teams':
      return arrayOfItems.map(item => {
        const activeMembers = item.members.filter(member => member.is_active);
        const membersString = Array.isArray(getValueOrPlaceholder(activeMembers)) ? activeMembers.map(member => {
          return `${member.profile.first_name} ${member.profile.last_name}`;
        }).join(', ') : getValueOrPlaceholder(activeMembers);

        return {
          'ID': getValueOrPlaceholder(item.id),
          'Name': getValueOrPlaceholder(item.name),
          'Active Members': membersString,
        }
      })
    default:
      return []
  }
}

function getDealTotalOutsideBrokerGross (deal) {
  const gross = (deal.outsideBrokers || []).reduce((accum, broker) => accum + broker.total, 0);
  return formatCurrency(gross);
}

function getDealTotalBrokerNetByType (deal, type) {
  const net = (deal[type] || []).reduce((accum, broker) => accum + broker.brokerNet, 0);
  return formatCurrency(net);
}

function getPipelineStage (stage) {
  return pipelineStages[stage - 1];
}

function getPrivacyLabel (privacy) {
  switch (privacy) {
    case 'member':
      return 'Private to me';
    case 'team':
      return 'Private to my team(s)';
    case 'office':
      return 'Private to my Company';
    default:
      return 'Public';
  }
}

function getDateValueOrPlaceholder (value) {
  return value ? formatDate(value) : 'N/A';
}

function getCurrencyValueOrPlaceholder (value) {
  return value ? formatCurrency(value) : 'N/A';
}

function getValueOrPlaceholder (value) {
  if (Array.isArray(value) && value.length === 0) {
    return 'N/A';
  }

  if (Array.isArray(value)) {
    return value;
  }

  return `${value || 'N/A'}`;
}

function getPercentValueOrPlaceholder (value) {
  return value ? formatPercent(value) : 'N/A';
}

export function generateHeaders (type) {
  switch (type) {
    case 'company':
      return [
        { text: 'Logo', value: 'logo', sortable: false, class: '' },
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Name',
              value: 'name',
              sortable: true,
              class: '',
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Address',
              value: 'address.street',
              sortable: true,
              class: '',
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Url',
              value: 'url',
              sortable: true,
              class: '',
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'contact':
      return [
        { text: 'Image', value: 'photo', sortable: false, class: 'data-image' },
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              'text': `First Name`,
              'value': 'first_name',
              'sortable': true,
              'class': '',
              'icon': 'string'
            },
            {
              'text': 'Last Name',
              'value': 'last_name',
              'sortable': true,
              'class': '',
              'icon': 'string'
            },
          ]
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Company',
              value: 'company',
              sortable: true,
              class: '',
              icon: 'string',
            },
          ],
        },
        // {
        //   value: 'multi',
        //   sortable: false,
        //   multi: [
        //     {text: 'Title', value: 'title', sortable: true, class: '', icon: 'string'}
        //   ]
        // },
        {
          text: 'Contact',
          value: 'market',
          class: '',
          sortable: false,
          icon: 'string',
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'occupier':
      return [
        { text: 'Logo', value: 'logo', class: '', sortable: false },
        { text: '#', value: '#', class: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Name',
              value: 'name',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Name',
              value: 'property.name',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Address',
              value: 'property.address.street',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available Space',
              value: 'square_feet',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Land Size',
              value: 'land_size',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Occupancy Type',
              value: 'occupancy_type',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Lease Term',
              value: 'ofRecord.lease_term',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Commencement',
              value: 'ofRecord.lease_commencement',
              class: '',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Expiration',
              value: 'ofRecord.lease_expiration',
              class: '',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Possession',
              value: 'possession_date',
              class: '',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        // {
        //   value: 'multi',
        //   sortable: false,
        //   multi: [
        //     { text: 'Views', value: 'views', sortable: true, class: '' }
        //   ]
        // }
      ]
    case 'requirement':
      return [
        { text: 'Logo', value: 'logo', sortable: false },
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Project Name',
              value: 'project_name',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Company',
              value: 'company_name',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Transaction Min',
              value: 'size_min',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Transaction Max',
              value: 'size_max',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Office Min',
              value: 'industrial.size_min',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Office Max',
              value: 'industrial.size_max',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Type',
              value: 'property_type',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Use Type',
              value: 'industry',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Transaction Type',
              value: 'transaction_type',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Target Occ. Date',
              value: 'target_occupancy_date',
              sortable: true,
              icon: 'date',
            },
            { text: 'Status', value: 'status', sortable: true, icon: 'string' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Broker',
              value: 'broker.last_name',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Company',
              value: 'broker.company',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'property':
      return [
        { text: '', value: 'image', sortable: false },
        { text: '#', value: '#', sortable: false },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Name', value: 'name', sortable: true, icon: 'string' },
            {
              text: 'Address',
              value: 'address.street',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Type',
              value: 'type',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Submarket Cluster',
              value: 'market.submarket_cluster',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Business Park',
              value: 'market.business_park',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building SF',
              value: 'size.building.total',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Land Acres',
              value: 'size.land.total',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Year Built',
              value: 'year_built',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'True Owner',
              value: 'owner',
              sortable: true,
              icon: 'string',
            },
            { text: 'Contact', value: '', sortable: false },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Listing Company',
              value: 'broker',
              sortable: true,
              icon: 'string',
            },
            { text: 'Contact', value: '', sortable: false },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        // { text: 'Views', value: 'views', class: '', sortable: true }
      ]
    case 'availability':
      return [
        { text: '', value: 'image', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available Space',
              value: 'available_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Divisible Space',
              value: 'divisible_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Office Space',
              value: 'office_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Date on Market',
              value: 'date_on_market',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Sales Price',
              value: 'sale_price',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Lease Rate',
              value: 'lease_rate',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
      ]
    case 'availabilityAdmin':
      return [
        { text: 'Photo', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Address',
              value: 'property_address',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Direct Available Space',
              value: 'available_space',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Divisible',
              value: 'divisible_space',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Office Space',
              value: 'office_space',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Rentable Building Area',
              value: 'rentable_building_area',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Market / Submarket',
              value: 'market',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Construction Status',
              value: 'construction_status',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building Dimensions',
              value: 'dimensions',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Skylights',
              value: 'skylights',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Sprinklers',
              value: 'sprinkler',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Min Clear Height',
              value: 'min_clear_height',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Max Clear Height',
              value: 'max_clear_height',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Column Spacing',
              value: 'column_spacing',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: '# Drive-In Doors', value: 'gl', class: '', sortable: true }],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: 'DH', value: 'dh', class: '', sortable: true }],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Rail Status',
              value: 'rail_status',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Rail Lines',
              value: 'rail_line',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Car Parks', value: 'parking', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Comments',
              value: 'property_comments',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Listing Comments',
              value: 'listing_comments',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Listing Type',
              value: 'listing_type',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Agency Type',
              value: 'agency_type',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Deal Status',
              value: 'deal_status',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Asking Rate',
              value: 'lease_asking_rate',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Type', value: 'lease_type', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Opex/SF', value: 'lease_opex', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'TI/SF', value: 'lease_tt', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Asking Price / SF',
              value: 'sale_asking_price',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Inventory',
              value: 'inventory',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Company', value: 'company', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Landlord', value: 'landlord', class: '', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'On Market',
              value: 'on_market',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available',
              value: 'available',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Last Update',
              value: 'last_update',
              class: '',
              sortable: true,
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Updated', value: 'updated.date', sortable: true },
            { text: 'Created', value: 'created.date', sortable: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: 'Views', value: 'views', class: '', sortable: true }],
        },
      ]
    case 'availabilityClient':
      return [
        { text: '', value: 'image', sortable: false },
        { text: '#', value: 'number', sortable: true },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Name',
              value: 'name',
              class: '',
              sortable: true,
              icon: 'string'
            },
            {
              text: 'Address',
              value: 'address',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Property Type',
              value: 'propertyType',
              class: '',
              sortable: true,
              icon: 'string'
            }
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available Space',
              value: 'available_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Divisible Space',
              value: 'divisible_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building Size',
              value: 'building_size',
              class: '',
              sortable: true,
            },
            {
              text: 'Construction Status',
              value: 'property.construction_status',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Vacancy Status',
              value: 'vacancy_status',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Land Size',
              value: 'land_size',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Divisible Land Size',
              value: 'divisible_land_size',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Office Space',
              value: 'office_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Clear Height',
              value: 'min_clear_height',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Dock Doors',
              value: 'dock_doors',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Ramp / Grade',
              value: 'ramp_grade',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Sprinklers',
              value: 'sprinklers',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Sales Price',
              value: 'sale_price',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Per SF',
              value: 'sale_price',
              class: '',
              sortable: false,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Lease Rate',
              value: 'lease_rate',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Type',
              value: 'lease_type',
              class: '',
              sortable: false,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Operating Exp',
              value: 'operation_expenses',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'TI Allowance',
              value: 'ti_allowance',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Available',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available',
              value: 'available_date',
              class: '',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'On Market (months)',
              value: 'date_on_market',
              class: '',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        // {text: 'Comments', value: 'comments', class: '', sortable: false},
        { text: 'Brokers', value: 'broker', class: '', sortable: false },
        { text: 'True Owner', value: 'true_owner', class: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Status',
              value: 'status',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        // {text: 'Views', value: 'views', class: '', sortable: true}
      ]
    case 'availabilityWorkflow':
      return [
        { text: '', value: 'image', sortable: false },
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Name',
              value: 'name',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Address',
              value: 'address',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Property Type',
              value: 'propertyType',
              class: '',
              sortable: true,
              icon: 'string'
            }
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Available Space',
              value: 'available_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: '',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Divisible Space',
              value: 'divisible_space',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building Size',
              value: 'building_size',
              class: '',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Construction Status',
              value: 'property.construction_status',
              class: '',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Marketing Push',
              value: 'marketing_push',
              class: 'text-center',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Listing Agreement',
              value: 'listing_agreement',
              class: 'text-center',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Expiration Date',
              value: 'listing_agreement.expiration_date',
              class: 'text-center',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Property Sign',
              value: 'sign',
              class: 'text-center',
              sortable: true,
              center: true,
              icon: 'string',
            },
            {
              text: 'Install Date',
              value: 'sign.install_date',
              class: 'last-date-label text-center',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Flyer (90 days)',
              value: 'property_flyer',
              class: 'text-center',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Last Updated',
              value: 'property_flyer.date',
              class: 'last-date-label text-center',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Eblast (5 weeks)',
              value: 'has_eblast',
              class: 'text-center',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Last Sent',
              value: 'eblast',
              class: 'last-date-label text-center',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          text: 'Multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Costar/Loopnet Syndication (30 days)',
              value: 'has_syndication',
              class: 'text-center',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Last Update',
              value: 'eblast_last_sent',
              class: 'last-date text-center',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'schedule':
      return [
        { text: 'Schedule Name', value: 'name' },
        { text: i18n.t(LocalizationEnum.schedule_type), value: 'schedule_type' },
        {
          value: 'multi',
          multiple: [
            { text: 'Avg Rent / Period', value: 'name' },
            { text: 'Avg Rent / SF', value: 'name' },
          ],
          sortable: false,
        },
        // { text: 'Square Feet', value: 'ofRecord.total_rent_sf' },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: 'Lease Term', value: 'ofRecord.lease_term' }],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Commencement Date', value: 'ofRecord.lease_commencement' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Lease Expiration', value: 'ofRecord.lease_expiration' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: 'Original Possession', value: 'possession_date' }],
        },
      ]
    case 'comparable':
      return [
        { text: '', value: 'image', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Tenant Name',
              value: 'name',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Property Name',
              value: 'name',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Address',
              value: 'address.street',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Trans Type', value: 'type', sortable: true, icon: 'string' },
            {
              text: 'Property Type',
              value: 'property_type',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Submarket Cluster',
              value: 'submarket_cluster',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Submarket',
              value: 'submarket',
              sortable: true,
              icon: 'string',
            },
            {
              text: i18n.t(LocalizationEnum.comparable_primary_use),
              value: 'primary_use',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building Size',
              // TODO: replace 'square_feet' with 'transaction.total_square_feet' and update API for proper sorting
              value: 'square_feet',
              sortable: true,
              icon: 'number',
            },
            // TODO: replace 'acres' with 'transaction.total_acres' and update API for proper sorting
            { text: 'Land Size', value: 'acres', sortable: true, icon: 'number' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Price',
              value: 'sale_price_total',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Per SF',
              value: 'sale_price_sf',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Lease Rate Starting',
              value: 'starting_rent_sf',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Lease Rate Effective',
              value: 'effective_rent_sf',
              sortable: false,
              icon: 'number',
            },
            {
              text: 'Lease Commencement',
              value: 'lease_commencement',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Trans Date',
              value: 'transaction_date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'TI Landlord / SF',
              value: 'ti_landlord',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'TI Tenant / SF',
              value: 'ti_tenant',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Buyer/Tenant Company',
              value: 'buyer_tenant_company',
              sortable: false,
              icon: 'string',
            },
            {
              text: 'Buyer/Tenant Contact',
              value: 'buyer_tenant_contact',
              sortable: false,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Seller/Landlord Company',
              value: 'seller_landlord_company',
              sortable: false,
              icon: 'string',
            },
            {
              text: 'Seller/Landlord Contact',
              value: 'seller_landlord_contact',
              sortable: false,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'project':
      return [
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Project Name',
              value: 'name',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Move in Date',
              value: 'moveInDate',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Construction Type',
              value: 'constructionType',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Building Type',
              value: 'buildingType',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Duration of Move',
              value: 'durationOfMove',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'pipeline':
      return [
        { text: '#', value: '', sortable: false },
        { text: 'Logo', value: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Company',
              value: 'company_name',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Deal Name',
              value: 'deal_name',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Category',
              value: 'category',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Size', value: 'size', sortable: true, icon: 'number' },
            { text: 'Land Size', value: 'land_size', sortable: true, icon: 'number' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Consideration',
              value: 'consideration',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Commission %',
              value: 'commission',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Probability %',
              value: 'probability',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Close Date',
              value: 'close_date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: '% of Deal',
              value: 'broker_percentage',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'In House Gross',
              value: 'in_house_gross',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Broker Gross',
              value: 'broker_gross',
              sortable: true,
              icon: 'number',
            },
            {
              text: 'Broker Gross Prob',
              value: 'broker_gross_probability',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Pipeline Stage',
              value: 'stage',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'deals':
      return [
        {
          'text': '#',
          'value': '',
          'sortable': false
        },
        {
          'text': '',
          'value': 'image',
          'sortable': false
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Deal Name',
            'value': 'name',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Property Name',
            'value': 'comparable.property.name',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Address',
            'value': 'comparable.property.address',
            'sortable': true,
            'icon': 'string'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Size',
            'value': 'transaction.total_square_feet',
            'sortable': true,
            'icon': 'number'
          }, {
            'text': 'Acres',
            'value': 'transaction.total_acres',
            'sortable': true,
            'icon': 'number'
          }, {
            'text': 'Property Type',
            'value': 'comparable.property.type',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Transaction Type',
            'value': 'transaction.transaction_type',
            'sortable': true,
            'icon': 'string'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Landlord / Seller',
            'value': 'occupier_contact',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Tenant / Buyer',
            'value': 'transaction_contact',
            'sortable': true,
            'icon': 'string'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Billing Company',
            'value': 'billing_contact',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Primary Agent',
            'value': 'primary_broker',
            'sortable': true,
            'icon': 'string'
          }]
        },
        {
          'text': 'Multi',
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Marketing Push',
            'value': 'marketing_push',
            'class': 'text-center',
            'sortable': true,
            'icon': 'number'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Press Release',
            'value': 'press_release.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Total Commission',
            'value': 'transaction.commission',
            'sortable': true,
            'icon': 'number'
          }, {
            'text': 'In-house Gross',
            'value': 'transaction.inhouse',
            'sortable': true,
            'icon': 'string'
          }, {
            'text': 'Consideration',
            'value': 'consideration',
            'sortable': true,
            'icon': 'number'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Invoice Approved',
            'value': 'approved.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Commission/Listing Agreement',
            'value': 'invoiced.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Lease/Contract',
            'value': 'invoiced.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Invoice Sent',
            'value': 'invoiced.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Payment Status',
            'value': 'paid.date',
            'class': 'text-center',
            'icon': 'date'
          }]
        },
        {
          'value': 'multi',
          'sortable': false,
          'multi': [{
            'text': 'Updated',
            'value': 'updated.date',
            'sortable': true,
            'icon': 'date'
          }, {
            'text': 'Created',
            'value': 'created.date',
            'sortable': true,
            'icon': 'date'
          }, {
            'text': 'Transaction Date',
            'value': 'transaction_date',
            'sortable': true,
            'icon': 'date'
          }]
        }
      ]
    case 'bugs-and-requests':
      return [
        { text: '#', value: '#', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Type', value: 'type', sortable: true, icon: 'string' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Name',
              value: 'name',
              width: 300,
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Status',
              value: 'status',
              align: 'center',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Duration',
              value: 'duration',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Priority',
              value: 'priority',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Difficulty',
              value: 'difficulty',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Votes',
              value: 'votes.length',
              sortable: true,
              icon: 'number',
            },
          ],
        },
        { text: 'Attachments', value: 'screenshot', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [{ text: 'Description', value: 'description', width: 300 }],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Page', value: 'page', sortable: true, icon: 'string' },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Closed',
              value: 'closed.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'savedSet':
      return [
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Name',
              value: 'name',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Set Type',
              value: 'set_type',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Record Type',
              value: 'type',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Privacy',
              value: 'privacy',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        // { value: 'multi',
        //   sortable: false,
        //   multi: [
        //     { text: 'Actions', value: 'actions', sortable: false, single: true, icon: 'string' }
        //   ]
        // },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
        { text: 'Edit', sortable: false },
        // { value: 'multi', sortable: false, multi: [{ text: 'Created By', value: 'created.name', sortable: true }, { text: 'Date', value: 'created.date', sortable: true }] },
        // { value: 'multi', sortable: false, multi: [{ text: 'Updated By', value: 'updated.name', sortable: true }, { text: 'Date', value: 'updated.date', sortable: true }] }
      ]
    case 'member':
      return [
        { text: 'Photo', value: 'photo', sortable: false, class: 'photo' },
        { text: '#', value: '', sortable: false },
        {
          text: 'Name',
          value: 'last_name',
          class: 'name',
          icon: 'string',
        },
        { text: 'Email', value: 'email', class: 'email', icon: 'string' },
        { text: 'Phone', value: 'phone', class: 'phone', icon: 'string' },
        { text: 'Roles', value: 'roles', class: 'roles', icon: 'string' },
        {
          text: 'Start Date',
          value: 'start_date',
          class: 'name',
          icon: 'string',
        },
        { text: 'Status', value: 'status', class: 'name', icon: 'string' },
        { text: 'Title', value: 'title', class: 'name', icon: 'string' },
        {
          text: 'Employee Type',
          value: 'employee_type',
          class: 'name',
          icon: 'string',
        },
        { text: 'Salary', value: 'last_name', class: 'name', icon: 'string' },
        {
          text: 'multi',
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'License Type',
              value: 'license.type',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'State',
              value: 'license.state',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Expiration',
              value: 'license.date_expiration',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Status',
              value: 'license.status',
              sortable: true,
              icon: 'string',
            },
          ],
        },
      ]
    case 'team':
      return [
        { text: '#', value: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Team Name',
              value: 'teamName',
              sortable: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Member Name',
              value: 'memberList',
              sortable: true,
              icon: 'string',
            },
          ],
        },
      ]
    case 'filter':
      return [
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Name', value: 'name', sortable: true, single: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'List', value: 'list', sortable: true, single: true },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Record Count',
              value: 'record-count',
              sortable: true,
              single: true,
              class: 'text-center',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            { text: 'Updated', value: 'updated.date', sortable: true },
            { text: 'Created', value: 'created.date', sortable: true },
          ],
        },
      ]
    case 'eblast':
      return [
        { text: '#', value: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Subject',
              value: 'settings.subject',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Recipients',
              value: 'recipients.recipient_count',
              sortable: true,
              single: true,
              icon: 'number',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Status',
              value: 'status',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'pressrelease':
      return [
        { text: '#', value: '', sortable: false },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Subject',
              value: 'settings.subject',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Status',
              value: 'status',
              sortable: true,
              single: true,
              icon: 'string',
            },
          ],
        },
        {
          value: 'multi',
          sortable: false,
          multi: [
            {
              text: 'Updated',
              value: 'updated.date',
              sortable: true,
              icon: 'date',
            },
            {
              text: 'Created',
              value: 'created.date',
              sortable: true,
              icon: 'date',
            },
          ],
        },
      ]
    case 'production':
      return [
        { text: '#', value: '#', sortable: false },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Transaction Date',
          value: 'transactionDate',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Total Commission',
          value: 'expected',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Total In-House Commission',
          value: 'expected',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Total Paid',
          value: 'payments',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Total Outstanding',
          value: 'remaining',
          sortable: true,
          icon: 'date',
        },
        {
          text: 'Broker Gross',
          value: 'brokerGross',
          sortable: true,
          class: '',
          icon: 'string',
        },
        {
          text: 'Broker Gross Outstanding',
          value: 'brokerGrossRemaining',
          sortable: true,
          icon: 'date',
        }
      ]
  }
}

export function openFeedbackFormHandler () {
  eventBus.$emit('toggleFeedbackForm', { drawer: true })
}

export class Masonry {
  constructor () {
    this.gridStyles = getComputedStyle(document.querySelector('.grid'))
    this.rowHeight = parseInt(
      this.gridStyles.getPropertyValue('--grid-row-height')
    )
    this.gap = parseInt(this.gridStyles.getPropertyValue('--grid-gutter'))
    this.rowHeight = isNaN(this.rowHeight) ? 0 : this.rowHeight
  }
  make () {
    let items = document.querySelectorAll('.grid-item')
    items.forEach((item) => {
      item.classList.remove('grid-item-ready')
      const height = item.firstChild.offsetHeight
      const rowSpan = Math.ceil(
        (height + this.gap) / (this.rowHeight + this.gap)
      )
      item.style.gridRowEnd = `span ${rowSpan}`
      item.classList.add('grid-item-ready')
    })
  }
}

export const isObject = (obj) => !Array.isArray(obj) && Object(obj) === obj

export const validateRange = ({ min, max }) => {
  const minNumber = formatRawNumber(min) || 0;
  const maxNumber = formatRawNumber(max) || 0;

  if (maxNumber > 0 && minNumber > 0) {
    return maxNumber >= minNumber || 'Min Should Be Less Or Equal To Max';
  }

  return true;
};
