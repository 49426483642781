














import Vue from 'vue';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'AvailabilityTransactionStatusChip',

  components: { CommonChip },

  props: {
    status: {
      type: String,
      required: true
    },
  },
});
