export enum SpaceMeasurementUnits {
  sf = 'sf',
  acres = 'acres',
  sm = 'sm' // TODO: need to sign square meters in more obvious way.
}

export enum DistanceMeasurementUnits {
  miles = 'mi',
  kilometers = 'km',
}

export enum CraneMeasurementUnits {
  capacity = 'tons',
  height = 'ft / in',
  clearance = 'ft / in',
}

export enum BuildingDimensionMeasurementUnits {
  inches = 'inches',
  cm = 'cm'
}
