import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const options = {
  theme: {
    dark: false,
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  }
};

export default new Vuetify(options);
