























































import Vue from 'vue';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { TaskPrivacyEnum } from '@/bundles/Tasks/enums/TaskPrivacyEnum';

import TasksCard from '@/bundles/Tasks/components/TasksCard.vue';
import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import CreateTaskForm from '@/bundles/Tasks/components/CreateTaskForm.vue';

interface ITasks {
  filteredTasksCount: {
    tome: number;
    byme: number;
    team: number;
  };
  loadingTasks: {
    tome: boolean;
    byme: boolean;
    team: boolean;
  };
  showTaskForm: boolean;
}

export default Vue.extend({
  name: 'Tasks',

  components: {
    CreateTaskForm,
    DashboardCard,
    TasksCard,
  },

  props: {
    expanded: Boolean,
    marketing: Boolean
  },

  data: (): ITasks => ({
    filteredTasksCount: {
      tome: 0,
      byme: 0,
      team: 0
    },
    loadingTasks: {
      tome: false,
      byme: false,
      team: false
    },
    showTaskForm: false,
  }),

  computed: {
    widgetType (): UserWidgetsEnum {
      return UserWidgetsEnum.tasks;
    },
    totalTasks (): number {
      return this.filteredTasksCount.tome + this.filteredTasksCount.byme + this.filteredTasksCount.team;
    },
    taskPrivacyEnum (): typeof TaskPrivacyEnum {
      return TaskPrivacyEnum;
    }
  },

  methods: {
    submitHandler () {
      this.$eventBus.$emit('fetch:tasks', { isPaginationChanged: false });
    }
  }
});
