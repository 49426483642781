
import Vue, { CreateElement, RenderContext, VNode } from 'vue';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'ConfidentialChip',

  functional: true,

  render (h: CreateElement, { data }: RenderContext): VNode {
    return h(CommonChip, {
      ...data,
      attrs: {
        color: 'orange darken-3',
        dark: true,
      }
    }, [
      h('v-icon', {
        class: 'mr-1',
        attrs: {
          small: true
        }
      }, 'mdi-shield-lock-outline'),
      'Confidential',
    ]);
  }
});
