import * as constants from './spatial-types'
import { ServiceFactory } from '@/services/ServiceFactory'
import { CustomLayerTypesEnum } from '@/bundles/Map/helpers/customLayers/customLayerTypes';

const GeoService = ServiceFactory.get('geo')

export default {
  // todo: check if we can remove fetching old layers
  async [constants.GET_LAYERS] ({ rootState, commit, dispatch }) {
    try {
      const { data } = await GeoService.getLayersTree({
        token: rootState.idToken,
      });
      commit(constants.SET_LAYERS, data);

      return {
        message: 'loaded',
        success: true,
      }
    } catch (e) {
      throw e
    }
  },
  async [constants.GET_LAYERS_NEW] ({ rootState, commit }) {
    try {
      const params = [
        {
          name: 'types',
          value: [
            CustomLayerTypesEnum.industrial,
            CustomLayerTypesEnum.office,
            CustomLayerTypesEnum.retail,
            CustomLayerTypesEnum['multi-family'],
            CustomLayerTypesEnum['farm and ranch'],
            CustomLayerTypesEnum.residential,
            CustomLayerTypesEnum.transportation
          ]
        }
      ];

      const { data } = await GeoService.getLayersTreeNew({
        params,
        token: rootState.idToken,
      });

      commit(constants.SET_LAYERS_NEW, data);
    } catch (e) {
      throw e
    }
  },
}
