import { FiltersDataConstants } from '@/store/modules/filtersData/filters-types';
import { IFiltersDataState } from '@/store/modules/filtersData/index';
import { Market } from '@/bundles/Markets/models/Market';
import Member from '@/bundles/Members/models/Member';

export default {
  [FiltersDataConstants.SET_MARKETS] (state: IFiltersDataState, data: Market[]) {
    state.markets = [...data];
  },
  [FiltersDataConstants.SET_LOADED] (state: IFiltersDataState, value: boolean) {
    state.isLoaded = value;
  },
  [FiltersDataConstants.SET_MEMBERS] (state: IFiltersDataState, data: Member[]) {
    state.members = [...data];
  },
  [FiltersDataConstants.SET_MEMBERS_LOADED] (state: IFiltersDataState, value: boolean) {
    state.isMembersLoaded = value;
  },
}
