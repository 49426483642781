












import Vue from 'vue';

export default Vue.extend({
  name: 'AvailabilityStatusChip',

  props: {
    status: {
      type: String,
      required: true
    },
  },
});
