export enum appPanelName {
  company = 'companies',
  contact = 'contacts',
  occupier = 'occupiers',
  requirement = 'requirements',
  property = 'properties',
  availability = 'availabilities',
  comparable = 'comparables',
  project = 'projects',
  pipeline = 'pipelines',
  deal = 'deals',
  savedSet = 'saved Sets',
  request = 'requests',
  member = 'members',
  team = 'teams',
  filter = 'filters',
  eblast = 'eblasts',
  pressrelease = 'Press Releases',
  pressreleases = 'PressReleases',
  dashboard = 'Dashboard',
  map = 'Spatial GIS',
  marketing = 'Marketing Dashboard',
  'eblast-list' = 'Eblasts',
  'profile-admin' = 'My Profile',
  marketingTemplates = 'Marketing Templates',
  marketingCampaign = 'Projects',
  customRows = 'Custom Rows'
}
