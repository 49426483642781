








































import Vue, { PropType } from 'vue';
import { getInvoiceStatusProps } from '@/bundles/Deal/helpers';
import { IHeader } from '@/bundles/BaseTable/interfaces';

import BaseTableHeaderMulti from '@/bundles/BaseTable/components/BaseTableHeaderMulti.vue';

const headers: IHeader[] = [
  {
    multi: [{ text: 'Invoice #', sortable: true, value: 'invoice_number', icon: 'number' }],
    value: 'multi',
    sortable: false
  },
  {
    multi: [{ text: 'Invoice Date', sortable: true, value: 'invoice_date', icon: 'date' }],
    value: 'multi',
    sortable: false
  },
  {
    multi: [{ text: 'Invoice Amount', sortable: true, value: 'amount', icon: 'number' }],
    value: 'multi',
    sortable: false
  },
  {
    multi: [{ text: 'Due Date', sortable: true, value: 'due_date', icon: 'date' }],
    value: 'multi',
    sortable: false
  },
  {
    multi: [{ text: 'Status', sortable: true, value: 'status', icon: 'text' }],
    value: 'multi',
    sortable: false
  }
];

export default Vue.extend({
  name: 'OpenBalancesInvoiceTable',

  components: {
    BaseTableHeaderMulti
  },

  props: {
    itemsEntry: {
      type: Array as PropType<any>,
      default: () => []
    }
  },

  data: () => ({
    headers,
    items: [],
    sortBy: ['due_date'],
    sortDesc: [true]
  }),

  mounted () {
    this.items = this.itemsEntry.map(item => {
      const { status, color } = getInvoiceStatusProps(item);
      return {
        ...item,
        status,
        color
      };
    });
  },

  methods: {
    isActiveSort: function (item: IHeader): boolean {
      return this.sortBy[0] === item.value;
    },
    sortChangeHandler (payload: { sortBy: [string]; sortDesc: [boolean] }) {
      const { sortBy, sortDesc } = payload;
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
    },
  }
})
