import * as constants from './settings-types';

export default {
  [constants.SET_PERMISSION_FOR_TYPE] (state, { type, permission }) {
    state.permission[type] = Object.assign(state.permission[type], permission)
  },
  [constants.SET_DEVICE_TOKENS] (state, tokens) {
    state.device_tokens = tokens;
  },
  [constants.SET_SETTINGS_KEY] (state, key) {
    state.settings_key = key;
  },
  [constants.SET_ALL_PERMISSIONS] (state, permission) {
    state.permission = Object.assign({}, permission);
  },
  [constants.INCREASE_PER_PAGE] (state) {
    state.rowsPerPage += 50
  },
  [constants.RESET_PER_PAGE] (state) {
    state.rowsPerPage = 50
  },
  [constants.CHANGE_LIST_SETTINGS] (state, { name, value }) {
    state.list[name] = value
  },
  [constants.CHANGE_LIST_PAGES_SETTINGS] (state, { name, value }) {
    state.list.pages[name] = Object.assign({}, state.list.pages[name], value)
  },
  [constants.SET_LAYOUT_SETTINGS] (state, { value, page }) {
    if (!state.layoutSettings.some((layout) => layout.page === value.page)) {
      state.layoutSettings.push(value);
      return;
    }

    state.layoutSettings = state.layoutSettings.map(item => {
      if (item.page === value.page) {
        return { ...value, isFetched: true };
      }

      return item;
    });
  },
  [constants.SET_USER_NOTIFICATION_SETTINGS] (state, value) {
    state.userNotificationsSettings = value || { email: false };
  },
  [constants.SET_USER_OPEX_DISPLAY] (state, value) {
    state.opexDisplay = value;
  },
}
