import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';
import { Nullable } from '@/utils/types';

export enum CommissionModesEnum {
  percentage = 'percentage',
  raw = 'raw',
  per_sf = 'per_sf',
}

// TODO: add proper type
export type IPipeline = any;

export enum SourceEnum {
  cold_call = 'cold-call',
  sign_call = 'sign-call',
  inbound_broker_referral = 'inbound-broker-referral',
  outbound_client_referral = 'outbound-client-referral',
  inbound_web = 'inbound-web',
  past_client = 'past-client',
  direct_marketing = 'direct-marketing'
}

export enum PipelineUnqualifiedEnum {
  marketing_unqualified_lead = 'Marketing Unqualified Lead',
  sales_unqualified_lead = 'Sales Unqualified Lead',
  other = 'Other',
}

export enum BusinessLineEnum {
  brokerage = 'brokerage',
  property_management = 'property-management'
}

export const BusinessLineLabels = {
  [BusinessLineEnum.brokerage]: 'Brokerage',
  [BusinessLineEnum.property_management]: 'Integrated Services'
};

export interface ICommissionFormData {
  commission: Nullable<number>;
  commission_total: Nullable<number>;
  commission_per: Nullable<number>;
  property_type?: PropertyTypeEnum;
  land_size?: Nullable<number>;
  size?: Nullable<number>;
}
