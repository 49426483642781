const STATE_KEYS: string[] = [
  'company',
  'contact',
  'deal',
  'property',
  'availability',
  'comparable',
  'pipeline',
  'occupier'
];

const getDefaultValue = () => ({
  sideBarWidth: '50%',
});

export function getInitialState () {
  const state = {};

  STATE_KEYS.forEach(key => {
    state[key] = Object.assign({}, getDefaultValue());
  });

  return state;
}
