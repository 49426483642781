import mutations from './mutations'

const initialState = {
  filters: [
    {
      title: 'Emails',
      children: [
        {
          title: 'Emails',
          name: 'emails',
          value: true,
        },
        {
          title: 'Tracking Emails',
          name: 'tracking_emails',
          value: true,
        },
        {
          title: 'Unsubscribe',
          name: 'unsubscribe',
          value: true,
        },
      ],
    },
    {
      title: 'Marketing',
      children: [
        {
          title: 'Marketing',
          name: 'marketing',
          value: true,
        },
      ],
    },
    {
      title: 'Notes',
      children: [
        {
          title: 'Notes',
          name: 'notes',
          value: true,
        },
      ],
    },
    {
      title: 'Web',
      children: [
        {
          title: 'Visits',
          name: 'spatial_search_view',
          value: true,
        },
      ],
    },
    {
      title: 'Tasks',
      children: [
        {
          title: 'Reminders',
          name: 'tasks',
          value: true,
        },
      ],
    },
  ]
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
}
