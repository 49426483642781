import { ITextValue } from '@/types/common';
import { toSnakeCase } from '@/bundles/App/helpers/toSnakeCase';

enum ContactRolesEnum {
  agent = 'agent',
  contractor = 'contractor',
  employee = 'employee',
  architect = 'architect',
  civil_engineer = 'civil_engineer',
  developer = 'developer',
  listing_agent = 'listing_agent',
  'listing_agent_-_sublease' = 'listing_agent_-_sublease',
  owner = 'owner',
  property_manager = 'property_manager',
  structural_engineer = 'structural_engineer',
  true_owner = 'true_owner',
  previous_owner = 'previous_owner',
  landlord_contact = 'landlord_contact',
  landlord_broker = 'landlord_broker',
  local_manager = 'local_manager',
  regional_manager = 'regional_manager',
  seller_broker = 'seller_broker',
  tenant_broker = 'tenant_broker',
  tenant_contact = 'tenant_contact',
  ultimate_decision_maker = 'ultimate_decision_maker',
  key_stake_holder = 'key_stake_holder',
  influencer = 'influencer',
  listing_broker = 'listing_broker',
  procuring_broker = 'procuring_broker',
  vendor_material_handling = 'vendor_material_handling',
  buyer = 'buyer',
  tenant = 'tenant',
  seller = 'seller',
  landlord = 'landlord',
  tenant_agent = 'tenant_agent',
  buyer_agent = 'buyer_agent',
  title_company = 'title_company',
  primary_verification = 'primary_verification',
  secondary_verification = 'secondary_verification',
  buyer_broker = 'buyer_broker',
  seller_agent = 'seller_agent',
  landlord_agent = 'landlord_agent',
  source = 'source',
  other = 'other',
  primary_client = 'primary_client',
  billing = 'billing',
  internal_team_member = 'internal_team_member',
  consulting_agent = 'consulting_agent',
  sublessor = 'sublessor',
}

const contactRoles = [
  { value: ContactRolesEnum.agent, text: 'Agent' },
  { value: ContactRolesEnum.contractor, text: 'Contractor' },
  { value: ContactRolesEnum.employee, text: 'Employee' },
  { value: ContactRolesEnum.architect, text: 'Architect' },
  { value: ContactRolesEnum.civil_engineer, text: 'Civil Engineer' },
  { value: ContactRolesEnum.developer, text: 'Developer' },
  { value: ContactRolesEnum.listing_agent, text: 'Listing Agent' },
  { value: ContactRolesEnum['listing_agent_-_sublease'], text: 'Listing Agent - Sublease' },
  { value: ContactRolesEnum.owner, text: 'Owner' },
  { value: ContactRolesEnum.property_manager, text: 'Property Manager' },
  { value: ContactRolesEnum.structural_engineer, text: 'Structural Engineer' },
  { value: ContactRolesEnum.true_owner, text: 'True Owner' },
  { value: ContactRolesEnum.previous_owner, text: 'Previous Owner' },
  { value: ContactRolesEnum.landlord_contact, text: 'Landlord Contact' },
  { value: ContactRolesEnum.landlord_broker, text: 'Landlord Broker' },
  { value: ContactRolesEnum.local_manager, text: 'Local Manager' },
  { value: ContactRolesEnum.regional_manager, text: 'Regional Manager' },
  { value: ContactRolesEnum.seller_broker, text: 'Seller Broker' },
  { value: ContactRolesEnum.tenant_broker, text: 'Tenant Broker' },
  { value: ContactRolesEnum.tenant_contact, text: 'Tenant Contact' },
  { value: ContactRolesEnum.ultimate_decision_maker, text: 'Ultimate Decision Maker' },
  { value: ContactRolesEnum.key_stake_holder, text: 'Key Stake Holder' },
  { value: ContactRolesEnum.influencer, text: 'Influencer' },
  { value: ContactRolesEnum.listing_broker, text: 'Listing Broker' },
  { value: ContactRolesEnum.procuring_broker, text: 'Procuring Broker' },
  { value: ContactRolesEnum.vendor_material_handling, text: 'Vendor Material Handling' },
  { value: ContactRolesEnum.buyer, text: 'Buyer' },
  { value: ContactRolesEnum.tenant, text: 'Tenant' },
  { value: ContactRolesEnum.seller, text: 'Seller' },
  { value: ContactRolesEnum.landlord, text: 'Landlord' },
  { value: ContactRolesEnum.tenant_agent, text: 'Tenant Agent' },
  { value: ContactRolesEnum.landlord_agent, text: 'Landlord Agent' },
  { value: ContactRolesEnum.buyer_agent, text: 'Buyer Agent' },
  { value: ContactRolesEnum.seller_agent, text: 'Seller Agent' },
  { value: ContactRolesEnum.title_company, text: 'Title Company' },
  { value: ContactRolesEnum.primary_verification, text: 'Primary Verification' },
  { value: ContactRolesEnum.secondary_verification, text: 'Secondary Verification' },
  { value: ContactRolesEnum.buyer_broker, text: 'Buyer Broker' },
  { value: ContactRolesEnum.primary_client, text: 'Primary Client' },
  { value: ContactRolesEnum.billing, text: 'Billing' },
  { value: ContactRolesEnum.source, text: 'Source' },
  { value: ContactRolesEnum.other, text: 'Other' },
  { value: ContactRolesEnum.internal_team_member, text: 'Internal Team Member' },
  { value: ContactRolesEnum.consulting_agent, text: 'Consulting Agent' },
  { value: ContactRolesEnum.consulting_agent, text: 'Consulting Agent' },
  { value: ContactRolesEnum.sublessor, text: 'Sublessor' },
];

const getContactRoles = (types: ContactRolesEnum[]): ITextValue<string>[] => {
  return contactRoles.filter((contactType) => {
    return types.some(type => type === contactType.value);
  });
};

const arrToSnakeCase = (arr: string[]): string[] => {
  return arr.map(item => toSnakeCase(item));
};

export { contactRoles, ContactRolesEnum, getContactRoles, arrToSnakeCase };
