export enum LeaseDateType {
  lease_option = 'lease_option',
  critical_date = 'critical_date',
}

export enum CriticalDateType {
  effective_date_start = 'effective_date_start',
  effective_date_end = 'effective_date_end',
  no_later_than_date = 'no_later_than_date',
  no_earlier_than_date = 'no_earlier_than_date',
}
