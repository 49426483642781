import { IMemberPermissions, IMemberSettings } from '@/bundles/Members/interfaces/IMemberSettings';
import { Nullable } from '@/utils/types';
import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';
import { OpexDisplayEnum } from '@/bundles/Members/enums';

export class MemberSettings extends BaseArangoModel implements IMemberSettings {
  device_tokens: string[];
  featuredSets: string[];
  member: number;
  permissions: IMemberPermissions;
  annual_goal: Nullable<number>;
  opex_display: OpexDisplayEnum;
  _rev: string;

  constructor (data: IMemberSettings) {
    super(data);
    this.device_tokens = data.device_tokens;
    this.featuredSets = data.featuredSets;
    this.member = data.member;
    this.permissions = data.permissions;
    this.annual_goal = data.annual_goal;
    this._rev = data._rev;
    this.opex_display = data.opex_display;
  }
}
