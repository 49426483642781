import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import TaskService from '@/bundles/Tasks/services/TaskService';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { Task } from '@/bundles/Tasks/models/Task';

function taskServiceFactory () {
  return new TaskService(
    httpV2,
    new ErrorMapper(),
    new RecordMapper<ITask>(Task),
  );
}

export const taskService = taskServiceFactory();
