export interface ITasksTablePagination {
  sortBy: string;
  descending: boolean;
  itemsPerPage: number;
  page: number;
  itemsPerPageOptions: number[];
}

export const getDefaultTaskItemsPerPage = (): number => 5;

export const getDefaultTaskPagination = (): ITasksTablePagination => ({
  sortBy: 'date_due',
  descending: false,
  itemsPerPage: getDefaultTaskItemsPerPage(),
  page: 1,
  itemsPerPageOptions: [2, 5, 10, 20]
});
