import store from '@/store/store';
import { formatBuildingDimensionLabel } from '@/bundles/MeasurementUnits/helpers';
import { Nullable } from '@/utils/types';

export const getBuildingDimensionFormatted = (value: Nullable<number | string>): string => {
  if (value === null) {
    return '';
  }

  const defaultLandSizeUnit = store.state.MeasurementUnits.buildingDimension;
  const selectedLandSizeUnit = store.state.accountSettings?.properties?.building_dimension_type?.value;

  return formatBuildingDimensionLabel(value, {
    from: defaultLandSizeUnit,
    to: selectedLandSizeUnit
  });
}
