export const SET_PERMISSION_FOR_TYPE = 'SET_PERMISSION_FOR_TYPE';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const SET_DEVICE_TOKENS = 'SET_DEVICE_TOKENS';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const INCREASE_PER_PAGE = 'INCREASE_PER_PAGE';
export const RESET_PER_PAGE = 'RESET_PER_PAGE';
export const CHANGE_LIST_SETTINGS = 'CHANGE_LIST_SETTINGS';
export const CHANGE_LIST_PAGES_SETTINGS = 'CHANGE_LIST_PAGES_SETTINGS';
export const SET_USER_NOTIFICATION_SETTINGS = 'SET_USER_NOTIFICATION_SETTINGS';
export const SET_SETTINGS_KEY = 'SET_SETTINGS_KEY';
export const GET_LAYOUT_SETTINGS = 'GET_LAYOUT_SETTINGS';
export const SET_LAYOUT_SETTINGS = 'SET_LAYOUT_SETTINGS';
export const INITIALIZE_PAGE_LAYOUT = 'INITIALIZE_PAGE_LAYOUT';
export const UPDATE_PAGE_LAYOUT = 'UPDATE_PAGE_LAYOUT';
export const SET_USER_OPEX_DISPLAY = 'SET_USER_OPEX_DISPLAY';
