








































































import Vue, { PropType } from 'vue';
import Cropper from 'cropperjs';
import { CropperFuncNamesEnum, formatBytes } from '@/bundles/Upload/helpers';
import { Nullable } from '@/utils/types';
import { IUploadOptions } from '@/bundles/Upload/interfaces';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { uploadService } from '@/bundles/Upload/factory/UploadServiceFactory';

interface IUploadCropper {
  cropper: Nullable<Cropper>;
  isRotate: boolean;
  isShowCropped: boolean;
  isLoading: boolean;
  croppedImageSrc: string;
}

const FILL_COLOR = '#FFFFFF';

export default Vue.extend({
  name: 'UploadCropper',

  props: {
    imageSrc: {
      type: String,
      default: null
    },
    uploadedFiles: {
      type: Array as PropType<File[]>,
      default: () => []
    },
    activeFunc: {
      type: String as PropType<CropperFuncNamesEnum>,
      default: null
    },
    options: {
      type: Object as PropType<IUploadOptions>,
      required: true,
    },
    isImg: {
      type: Boolean,
      required: true,
    },
    sourceType: {
      type: String,
      default: null,
    },
    sourceId: {
      type: [String, Number],
      default: null,
    },
    isMultiplySizesUpload: Boolean,
    attachmentType: {
      type: String,
      default: null,
    }
  },

  data: (): IUploadCropper => ({
    cropper: null,
    isRotate: false,
    isShowCropped: false,
    isLoading: false,
    croppedImageSrc: ''
  }),

  watch: {
    activeFunc: {
      handler (newName: Nullable<CropperFuncNamesEnum>, prevName: undefined | CropperFuncNamesEnum) {
        if (newName) {
          this.toggleCropperFunc(newName);
        } else if (prevName && !newName) {
          this.cropper?.destroy();
          this.cropper = null;
          this.isRotate = false;
        }
      },
      immediate: true,
    }
  },

  mounted () {
    this.$eventBus.$on('upload:zoom', this.zoomChangeHandler);
  },

  beforeDestroy () {
    this.cropper?.destroy();
    this.$eventBus.$off('upload:zoom', this.zoomChangeHandler);
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION
    }),
    initCropper () {
      if (this.cropper) {
        return;
      }

      this.$nextTick(() => {
        this.cropper = new Cropper((this.$refs.image as HTMLImageElement), {
          background: false,
          viewMode: 0,
          autoCropArea: 1,
          checkCrossOrigin: false,
          ...this.options
        });
      });
    },
    submit () {
      if (!this.uploadedFiles.length) {
        return;
      }

      this.isLoading = true;

      if (this.cropper) {
        const fileType = this.uploadedFiles[0].type || 'image/png';

        const imageData = this.cropper.getData();

        this.cropper.getCroppedCanvas({
          width: Math.round(imageData.width),
          height: Math.round(imageData.height),
          fillColor: FILL_COLOR
        }).toBlob((blob) => {
          if (!blob) {
            return;
          }

          const file = new File(
            [blob],
            this.uploadedFiles[0].name || 'cropped.png', // cropper load only when one file present
            { type: fileType } // file can be null if we upload image from url
          );

          this.saveFile({ files: [file] });
        }, fileType, 0.95);
        return;
      }

      if (this.uploadedFiles.length) {
        this.saveFile({ files: this.uploadedFiles });
      }
    },
    async saveFile ({ files }: { files: File[] }) {
      try {
        this.isLoading = true;

        const params: Array<{ name: string; value: any }> = [];

        if (this.attachmentType) {
          params.push(
            { name: 'is_attachment', value: true },
            { name: 'attachment_type', value: this.attachmentType }
          );
        }

        // bind is used to save context of this in uploadService
        const uploadFunction = this.isMultiplySizesUpload ? uploadService.uploadMultiplySizes.bind(uploadService) : uploadService.upload.bind(uploadService);
        const responseFiles = await uploadFunction({
          record: this.sourceType,
          key: this.sourceId,
          files,
          params
        });

        this.$emit('fileUploaded', { originalFiles: files, files: responseFiles });
      } catch (error) {
        const notification = { ...error, status: 0 }; // to show error notification
        this.addNotification(notification);
      } finally {
        this.isLoading = false;
      }
    },
    rotate (isLeft = false) {
      this.cropper?.rotate(isLeft ? -90 : 90);
    },
    toggleCropperFunc (name: CropperFuncNamesEnum) {
      if (!this.isImg) {
        return;
      }

      if (!this.cropper) {
        this.initCropper();
      }

      this.isRotate = name === CropperFuncNamesEnum.rotate;
    },
    hideCroppedImage () {
      this.croppedImageSrc = '';
      this.isShowCropped = false;
    },
    checkCropped () {
      if (this.croppedImageSrc) {
        this.hideCroppedImage();
        return;
      }

      if (!this.cropper) {
        return;
      }

      const imageData = this.cropper.getData();

      this.cropper.getCroppedCanvas({
        width: Math.round(imageData.width),
        height: Math.round(imageData.height),
        fillColor: FILL_COLOR
      }).toBlob((blob) => {
        this.croppedImageSrc = URL.createObjectURL(blob);
        this.isShowCropped = true;
      });
    },
    formatBytes (bytes: number): string {
      return formatBytes(bytes);
    },
    zoomChangeHandler (isZoomIn = false) {
      if (!this.cropper) {
        return;
      }

      this.cropper.zoom(isZoomIn ? 0.1 : -0.1);
    }
  }
});
