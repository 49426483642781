import mutations from './mutations'
import { getDefaultTaskItemsPerPage } from '@/bundles/Tasks/helpers/defaultPagination';

const initialState = {
  tableOptions: {
    itemsPerPage: getDefaultTaskItemsPerPage(),
  },
}
export default {
  namespaced: true,
  state: initialState,
  getters: {},
  actions: {},
  mutations,
}
