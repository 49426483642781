// when adding more sources, add mocks for added modules
export enum StoreModulesNamesEnum {
  Notifications = 'Notifications',
  MeasurementUnits = 'MeasurementUnits',
  Admin = 'Admin',
  Spatial = 'Spatial',
  Company = 'Company',
  Settings = 'Settings',
  FiltersData = 'FiltersData',
} // todo: add more modules here
