import * as constants from './requirement-types'
import instance from '../../../services/instance'

export default {
  [constants.GET_REQUIREMENTS_FULL_LIST] ({ commit, rootState }, params = null) {
    let url = params ? `/requirement/list?${params}` : '/requirement/list'
    return new Promise((resolve, reject) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((res) => {
          let { requirements } = res.data
          resolve(requirements)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
