import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import HttpClient from '@/bundles/Http/transport/HttpClient';
import { requestInterceptor } from '@/bundles/Http/interceptors/requestInterceptor';
import { responseErrorInterceptor } from '@/bundles/Http/interceptors/responseErrorInterceptor';
import { AxiosRequestConfig } from 'axios';

function httpFactory (baseURL: string, credentials = false) {
  const config: Partial<AxiosRequestConfig> = {
    baseURL,
  };
  if (credentials) {
    config.withCredentials = true;
  }
  const client = new HttpClient(
    config,
    requestInterceptor,
    responseErrorInterceptor,
  );

  return new HttpTransport(client);
}

const httpV2 = httpFactory(process.env.VUE_APP_V2_API_URL!, true);
const httpV1 = httpFactory(process.env.VUE_APP_V1_API_URL!);

export {
  httpV2,
  httpV1,
};
