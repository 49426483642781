export const GET_INSTALLATION_INFO = 'GET_INSTALLATION_INFO';
export const COMPLETE_INSTALLATION = 'COMPLETE_INSTALLATION';
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_PIPELINE_ADDED = 'SOCKET_PIPELINE_ADDED';
export const TOGGLE_SPLASH_SCREEN = 'TOGGLE_SPLASH_SCREEN';
export const LOGIN = 'LOGIN';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_AVAILABILITY_VIEW = 'SET_AVAILABILITY_VIEW';
export const SET_SETTINGS = 'SET_SETTINGS';
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const TOGGLE_APP_UPDATE_MODAL = 'TOGGLE_APP_UPDATE_MODAL';
export const PREFILL_SELECTED = 'PREFILL_SELECTED';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';
export const SET_NAV_STATE = 'SET_NAV_STATE';
export const SET_NAV_VIEW = 'SET_NAV_VIEW';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const SET_CARD_MAP_STYLE = 'SET_CARD_MAP_STYLE';
