const getLayersByType = (state) => (type) => state.layersNew[type];

const getMarketLayers = (state) => (type) =>
  state.layers.filter(
    (layer) =>
      layer.source_type &&
      layer.source_type.includes(type) &&
      !layer.source_type.includes('cluster')
  );

const getStandardLayerById = (state) => (id) => state.standardLayers.find(layer => layer.id === id) || {};

const getCustomLayerTypes = (state) => () => {
  return Object.keys(state.layersNew).filter(item => item !== 'system' && item !== 'standard');
};

const getCustomLayerSections = (state) => (layerType) => {
  return state.layersNew[layerType] || [];
}

export default {
  getLayersByType,
  getMarketLayers,
  getStandardLayerById,
  getCustomLayerTypes,
  getCustomLayerSections
}
