import MarketService from '@/bundles/Markets/services/MarketService';
import { httpV1 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { MarketToMapper } from '@/bundles/Markets/mappers/MarketToMapper';

function marketServiceFactory () {
  return new MarketService({
    transport: httpV1,
    errorMapper: new ErrorMapper(),
    recordMapper: new MarketToMapper(),
  });
}

export const marketService = marketServiceFactory();
