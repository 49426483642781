export type IAvailability = any; // TODO sychdo: create proper availability interface

export enum AvailabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Leased = 'Leased',
  Pending = 'Pending',
  Sold = 'Sold',
  Withdrawn = 'Withdrawn'
}
