























import Vue, { PropType } from 'vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'CollaboratorsSelect',

  components: { CommonChip },

  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    members: {
      type: Array as PropType<{id: number; full_name: string}[]>,
      default: () => [],
    },
    loading: Boolean,
  },

  data: (): { searchInput: string } => ({
    searchInput: '',
  }),

  computed: {
    collaborators: {
      get (): number[] {
        return this.value;
      },
      set (value: number[]) {
        this.$emit('input', value);
      },
    },
  },
});
