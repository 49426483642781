import instance from '../instance'
import { generateParams } from '@/utils/helpers'

const resource = '/activity'
const analyticsResource = '/analytics'

export default {
  getActivityList ({ id, token }) {
    return instance.get(`${resource}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  /**
   * Get activities by record id
   * @param {String} token
   * @param {Array} [params=[]]
   * */
  getActivities ({ token, params = [] }) {
    const stringParams = generateParams(params)
    return instance.get(`${analyticsResource}/activities${stringParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  create (token, payload) {
    return instance.post(`${resource}/${token}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
}
