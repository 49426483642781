// using new modules from store in this file requires update inside jest-setup.mock.ts
import store from '@/store/store';
import numeral from 'numeral'
import { DateTime } from 'luxon';
import moment from 'moment-timezone'
import { CraneMeasurementUnits } from '@/bundles/MeasurementUnits/types';
export * from './unitOfMeasureFilters';

export function formatNumber (value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
}

export function formatDecimal (value) {
  return numeral(value).format('0,0.00'); // displaying other groupings/separators is possible, look at the docs
}

export function formatRawDecimal (value) {
  return formatRawNumber(numeral(value).format('0.00'));
}

export function formatThousands (value) {
  return numeral(value).format('0,000');
}

export function formatRawInteger (value) {
  return formatRawNumber(numeral(value).format('0'));
}

export function formatCaps (value) {
  return value[0].toUpperCase() + value.substring(1);
}

export function formatPhone (value) {
  // US national number format: (213) 373-4253
  const numbers = [
    {
      pattern: /^([0-9]{3})-?([0-9]{3})-?([0-9]{4})(ext|x)?([0-9]+)?$/i,
      // starts with 3 numbers, another 3 numbers, then 4 numbers. we either have ext or x, and then some more numbers (though we don't know how many)
      replace: '($1) $2-$3 $4 $5', // If $4 and $5 aren't there we remove the extra space with the .trim() call at the end.
    },
    {
      name: 'phone12',
      pattern: /(\+[0-9]{2})([0-9]{2})([0-9]{4})([0-9]{4})/,
      // a group of two with a plus, another group of two, and then 2 groups of 4.
      replace: '$1 $2 $3 $4',
    },
  ]

  const notAllowedChars = [/\./g, /^\+?1-?/g, /[()\-\s]/g]
  const removeNotAllowed = function (str, n) {
    n.forEach((item) => {
      str = str.replace(item, '');
    })
    return str;
  }

  let phone = removeNotAllowed(value, notAllowedChars)
  numbers.forEach((rep) => {
    if (phone.match(rep.pattern)) {
      phone = phone.replace(rep.pattern, rep.replace);
    }
  })
  return phone.trim();
}

export function formatCurrencyShowDecimalWhenExist (value) {
  return numeral(value).format('$0,0[.]00');
}

export function formatCurrency (value) {
  return '$' + numeral(value).format('0,0.00'); // displaying other groupings/separators is possible, look at the docs
}

export function formatCurrencyNoDecimal (value) {
  return '$' + numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
}

export function formatPercent (value) {
  return numeral(value).format('0.00') + '%'; // displaying other groupings/separators is possible, look at the docs
}

export function formatParkingRatio (value) {
  return numeral(value).format('0,0.00000'); // displaying other groupings/separators is possible, look at the docs
}

export function formatAcres (value) {
  return numeral(value).format('0.000'); // displaying other groupings/separators is possible, look at the docs
}

export function formatAcresCutZero (value) {
  return numeral(value).format('0.[000]');
}

export function formatNumberCutZero (value) {
  return numeral(value).format('0,0.[00000000]');
}

export function formatCurrencyCutZero (value) {
  return '$' + numeral(value).format('0,0.[00000000]');
}

export function formatSf (value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
}

export function formatRawNumber (value) {
  return numeral(value).value();
}

export function formatJSDate (value, isoFormat = false) {
  if (value) {
    if (isoFormat) {
      return moment(value).format('YYYY-MM-DD');
    }
    return moment(value).format(store.state.accountSettings.date_display_format);
  }
}

export function formatJSTime (value) {
  if (value) {
    const tz = moment.tz.guess();
    const date = moment(value);
    return date.tz(tz).format('h:mm a');
  }
}

export function formatJSDateTime (value) {
  if (value) {
    const tz = moment.tz.guess();
    const date = moment(value);
    return date.tz(tz).format(`${store.state.accountSettings.date_display_format} h:mm a`);
  }
}

export function formatDate (value) {
  if (value) {
    return moment(value).format(store.state.accountSettings.date_display_format);
  }
}

export function formatDateTime (value) {
  if (value) {
    const date = moment(value);
    return date.format(`${store.state.accountSettings.date_display_format} h:mm a`);
  }
}

export function formatDateMonthYear (value) {
  if (value) {
    return moment(value).format('MMMM YYYY');
  }
}

export function formatTime (value) {
  if (value) {
    const date = moment(value);
    return date.format('h:mm a');
  }
}

export function formatMomentTime (value) {
  if (value) {
    return moment(value).format('hh:mm');
  }
}

export function formatYear (value) {
  if (value) {
    const tz = moment.tz.guess();
    const date = moment(value);
    return date.tz(tz).format('YYYY');
  }
}

export function capitalize (value) {
  if (!value) return '';
  const temp = value.toString();
  return temp.charAt(0).toUpperCase() + temp.slice(1);
}

export function capitalizeWords (value) {
    if (!value) return '';
    const temp = value.toString();
    return temp.split(' ').map((item) => capitalize(item)).join(' ');
}

export function replaceSlugAndCapitalize (value) {
  if (!value) return '';
  const temp = value.split('_');
  return temp.map((item) => capitalize(item)).join(' ');
}

export function replaceWithBr (value) {
  return value.replace(/\n/gi, '<br>');
}

export function formatDuration (value) {
  if (value) {
    return moment.duration(value).humanize();
  }
}

export function formatCapacity (value) {
  return `${value} ${CraneMeasurementUnits.capacity}`;
}

export function formatHeight (value) {
  return `${value} ${CraneMeasurementUnits.height}`;
}

export function formatClearance (value) {
  return `${value} ${CraneMeasurementUnits.clearance}`;
}

export function formatUrl (value) {
  if (!value) {
    return '';
  }

  return value.includes('http') ? value : `//${value}`;
}

export function fullStreetAddress (address = {}) {
  return [address.street, address.street2].filter(Boolean).join(', ');
};

export function getDateDiff (dateISO, values = ['milliseconds']) {
  return DateTime.fromISO(dateISO).diffNow(values).values || {};
};

export function getDateDiffAbs (dateISO, values = ['milliseconds']) {
  const result = getDateDiff(dateISO, values);

  const resultAbs = {};

  Object.entries(result).forEach(([key, value]) => {
    resultAbs[key] = Math.floor(Math.abs(value));
  });

  return resultAbs
};

export function getDiffBetweenDates (startDateISO, endDateISO, options = ['milliseconds']) {
  const startDate = DateTime.fromISO(startDateISO);
  const endDate = DateTime.fromISO(endDateISO);

  return endDate.diff(startDate, options).toObject();
}

export function addDates (dateISO, values = {}) {
  return DateTime.fromISO(dateISO).plus(values).toISODate();
}

export function minusDates (dateISO, values = {}) {
  return DateTime.fromISO(dateISO).minus(values).toISODate();
}
