import { register } from '@/utils/sw-register-service';

export function registerWorker () {
  register('./service-worker.js', {
    ready (registration) {
      console.debug('Service worker is active.');
    },
    registered (registration) {
      registration.update();
      setInterval(() => {
        registration.update();
      }, 600000); // e.g. hourly checks
    },
    updated (registration) {
      document.dispatchEvent(
        new CustomEvent('sw:updated', { detail: registration }),
      );
      console.debug('Service worker New content is available; please refresh.', registration);
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.update();
    },
  });
  register('./js/firebase-messaging-sw.js', {
    ready (registration) {
      console.debug('Firebase service worker is active.');
    },
  });
}
