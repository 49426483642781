import { ITransactionLease, ITransactionSale } from '@/bundles/Comparables/types';

export const getComparableTransactionTypeLabel = (transaction: ITransactionSale | ITransactionLease) => {
  if ('investment_sale' in transaction && transaction.investment_sale) {
    return `${transaction.transaction_type} - Investment`;
  }

  if ('sublease' in transaction && transaction.sublease) {
    return `${transaction.transaction_type} - Sublease`;
  }

  return transaction.transaction_type;
}
