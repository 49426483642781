




import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { UserAccountsMixin } from '@/bundles/App/mixins/userAccountsMixin';

export default mixins(AccountLayoutMixin, UserAccountsMixin).extend({
  name: 'AccountWrapper',

  watch: {
    '$route.params.accountId' (newVal, prevVal) {
      if (isNaN(newVal) || newVal === prevVal) {
        return;
      }

      const isAllowReLogin = this.accounts.some(account => account.id === +newVal);

      if (!isAllowReLogin) {
        this.$router.push(this.getAccountRoute({
          name: 'no-access',
          query: { id: newVal }
        }));
        return;
      }

      if (this.activeAccount?.id !== +newVal) {
        this.reLogin(newVal);
      }
    },
  },

  methods: {
    async reLogin (id) {
      try {
        this.toggleScreen(true);
        await this.accountLogin({ account: +id });
        this.resetFilters();

        await this.$router.push(this.getAccountRoute({
          name: this.$route.name as string,
          params: this.$route.params,
          query: this.$route.query,
        }));

        if (this.isMultipleAccountAuth) {
          this.clearTemporary();
        }

        window.location.reload(); // added for reload all calls which is needed on first load app
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      }
    }
  }
});
