import * as constants from './project-types'
import instance from '../../../services/instance'

export default {
  [constants.UPDATE_PROJECT] ({ commit, rootState }, project) {
    return new Promise((resolve, reject) => {
      console.log('update project: ', project)
      let key = project._key
      instance
        .put(`/project/${key}`, project, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((result) => {
          commit(constants.UPDATE_PROJECT, result.data.new)
          resolve({ msg: 'Updated' })
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.UPDATE_PROJECT_SETS] ({ commit, rootState }, { id, payload }) {
    return new Promise((resolve, reject) => {
      instance
        .put(`/project/${id}`, payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then(() => {
          // commit(constants.UPDATE_PROJECT, result.data)
          resolve({ msg: 'Updated' })
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
}
