











import Vue from 'vue';

export default Vue.extend({
  name: 'DashboardTaskSkeleton',

  props: {
    loading: Boolean
  }
});
