export enum TaskRepeatEnum {
  no_repeat = 'no_repeat',
  daily = 'daily',
  everyWeekOn = 'everyWeekOn',
  custom = 'custom'
}

export const TaskRepeatLabels = {
  [TaskRepeatEnum.no_repeat]: 'Do not repeat',
  [TaskRepeatEnum.daily]: 'Daily',
  [TaskRepeatEnum.everyWeekOn]: 'Weekly on Monday',
  [TaskRepeatEnum.custom]: 'Custom',
}

export enum TaskRecurrenceTypes {
  day = 'day',
  week = 'week',
  month = 'month'
}

export enum TaskDaysEnum {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday'
}
