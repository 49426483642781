const getCompanyById = (state) => (id) =>
  state.companiesFullList.find((company) => company._id === id)

const getCompanyByName = (state) => (name) =>
  state.companiesFullList.find((company) => company.name === name)

export default {
  getCompanyById,
  getCompanyByName,
}
