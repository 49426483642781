import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import SubscriptionService from '@/bundles/Subscriptions/services/SubscriptionService';
import { ISubscription } from '@/bundles/Subscriptions/interfaces/ISubsription';
import Subscription from '@/bundles/Subscriptions/models/Subscription';

function subscriptionServiceFactory () {
  return new SubscriptionService(
    httpV2,
    new ErrorMapper(),
    new RecordMapper<ISubscription>(Subscription),
  );
}

export const subscriptionService = subscriptionServiceFactory();
