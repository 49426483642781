import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import UserCredentialsDto from '@/bundles/Auth/dto/user-credentials';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { AuthReturn } from '@/bundles/Auth/models/AuthReturn';
import { MultiAuthReturn } from '@/bundles/Auth/models/MultiAuthReturn';
import AuthReturnToMapper from '@/bundles/Auth/mappers/AuthReturnToMapper';
import MultiAuthReturnToMapper from '@/bundles/Auth/mappers/MultiAuthReturnToMapper';
import { IUserReturn } from '@/bundles/Auth/interfaces/IUserReturn';
import * as Sentry from '@sentry/vue';

export default class AuthService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly authMapper: AuthReturnToMapper;
  private readonly multiAuthMapper: MultiAuthReturnToMapper;

  constructor ({
    transport,
    errorMapper,
    authMapper,
    multiAuthMapper,
  }: { transport: HttpTransport, errorMapper: ErrorMapper, authMapper: AuthReturnToMapper, multiAuthMapper: MultiAuthReturnToMapper }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.authMapper = authMapper;
    this.multiAuthMapper = multiAuthMapper;
  }

  async signIn (userCredentials: UserCredentialsDto): Promise<AuthReturn|MultiAuthReturn> {
    try {
      const response = await this.transport.post(
        '/auth/sign-in',
        userCredentials,
      );
      const isMultiAuthSignIn = 'user' in response;

      const mapper = isMultiAuthSignIn ? this.multiAuthMapper : this.authMapper;

      if (isMultiAuthSignIn) {
        Sentry.setUser({
          id: response.user.id,
          email: response.user.email
        });
      }

      return mapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async me (): Promise<IUserReturn> {
    try {
      const response = await this.transport.get('auth/me');

      Sentry.setUser({
        id: response.id,
        email: response.email
      });

      return response;
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async multiAccountSignIn (payload: { account: number }): Promise<AuthReturn> {
    try {
      return await this.transport.post(
        '/auth/account-sign-in',
        payload,
      );
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async changePassword (payload: { current_password: string; new_password: string; }): Promise<void> {
    try {
      return await this.transport.patch(
        '/auth/change-password',
        payload,
      );
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async resetPasswordRequest (payload: { email: string }): Promise<void> {
    try {
      return await this.transport.post(
        '/auth/magic-link',
        payload,
      );
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async resetPassword (payload: { password: string }): Promise<void> {
    try {
      return await this.transport.patch(
        '/auth/reset-password',
        payload,
      );
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async loginWithMagicLink (token: string): Promise<AuthReturn|MultiAuthReturn> {
    try {
      const response = await this.transport.post(
        '/auth/verify-magic-link',
        { token }
      );

      const isMultiAuthSignIn = 'user' in response;

      const mapper = isMultiAuthSignIn ? this.multiAuthMapper : this.authMapper;

      if (isMultiAuthSignIn) {
        Sentry.setUser({
          id: response.user.id,
          email: response.user.email
        });
      }

      return mapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async verifyEmail (verifier: string) {
    try {
      await this.transport.patch(`/auth/verify-email?verifier=${verifier}`, {});
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async logOut () {
    try {
      await this.transport.post(`/auth/logout`, {});
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async validateCaptcha (response: string) {
    try {
      await this.transport.post('/auth/validate-captcha', { response });
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async tokenRefresh () {
    try {
      const response = await this.transport.post('/auth/token-refresh', {});
      return this.authMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
