var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"hide-default-footer":"","item-key":"number","mobile-breakpoint":0,"expanded":_vm.expanded,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.filterLoading},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"sc-broker-ranking-broker sc-open-balances-row",class:("" + (isExpanded ? 'expanded' : '')),on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',{staticClass:"sc-data-list-select",staticStyle:{"width":"80px"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{staticClass:"ml-0 pl-0 pr-0"},[_c('v-chip',{staticClass:"sc-nbr",attrs:{"small":"","label":"","dark":""}},[_c('span',[_vm._v(_vm._s(item.number))])])],1)],1)],1),_c('td',{staticClass:"sc-data-list-subject"},[_c('router-link',{staticClass:"link-to-project",staticStyle:{"display":"inline"},attrs:{"to":_vm.getAccountRoute({ name: 'deal', params: { id: item._key }})}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('br'),(item.status === 'pending')?_c('v-chip',{staticClass:"info overline mt-2",attrs:{"small":"","dark":"","to":_vm.getAccountRoute({ name: 'deal', params: { id: item._key }})}},[_vm._v(" Pending invoice approval ")]):_vm._e()],1),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.transactionDate))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.expected))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.payments))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.remaining))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.inHouseExpected))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.brokerGross))+" ")]),_c('td',{staticClass:"sc-data-list"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.brokerGrossRemaining))+" ")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{staticClass:"broker-ranking-detail"},[_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('OpenBalancesInvoiceTable',{attrs:{"items-entry":item.invoices}})],1)],1)])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"grey lighten-2 tfoot"},[_c('td',[_c('v-chip',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.totals.count))])],1),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.expected))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.paid)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.remaining)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.inHouseExpected))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.brokerGross)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.totals.brokerGrossRemaining)))])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }