import { getInitialTableOptionsByType, keys } from './helpers';
import * as constants from './types';

export default {
  [constants.SET_TABLE_OPTIONS] (state, { tableOptions, type }) {
    state[type].tableOptions = tableOptions;
  },
  [constants.RESET_TABLE_OPTIONS_BY_TYPE] (state, type) {
    state[type].tableOptions = getInitialTableOptionsByType(type);
  },
  [constants.RESET_TABLE_OPTIONS] (state) {
    keys.forEach(key => {
      state[key].tableOptions = getInitialTableOptionsByType(key);
    });
  },

  [constants.SET_SELECTED] (state, { selected, type }) {
    state[type].selected = selected;
  },
  [constants.RESET_SELECTED_BY_TYPE] (state, type) {
    state[type].selected = [];
  },
  [constants.RESET_SELECTED] (state) {
    keys.forEach(key => {
      state[key].selected = [];
    });
  },
}
