import instance from '@/services/instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/integrations';

export default {
  getOutlookMail: async function ({ token, outlookToken, email }) {
    try {
      return await instance.get(`${resource}/outlook-mails/?email=${email}&token=${outlookToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getUserIntegrations: async function ({ token, id }) {
    try {
      return await instance.get(`${ resource }/${ id }/integrations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMyIntegrations: async function ({ token }) {
    try {
      return await instance.get(`${ resource }/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeUserIntegration: async function ({ token, id }) {
    try {
      return await instance.delete(`${ resource }/${ id }`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
