// TODO: move service to proper bundle structure when multi-account branch will be merged
import { generateParams } from '@/utils/helpers';
import instance from '@/services/instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/report-template';

export default {
  /**
   * Fetch templates list
   * @param {string} token
   * @param {any[]} params
   * @return {any}
   * */
  list: async function (token: string, params: any[]): Promise<any> {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${ resource }${ paramsString }`, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * Fetch single template by key
   * @param {string} token
   * @param {string} key
   * @returns {any}
   * */
  getOne: async function (token: string, key: string): Promise<any> {
    try {
      return await instance.get(`${ resource }/${ key }`, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * Create a new templte
   * @param {string} token
   * @payload {string} payload
   * @returns {any}
   * */
  create: async function (token: string, payload: object): Promise<any> {
    try {
      return await instance.post(`${ resource }`, payload, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  /**
   * update a new templte
   * @param {string} token
   * @payload {string} payload
   * @id {string}
   * */
  update: async function (token: string, id: string, payload: object): Promise<any> {
    try {
      return await instance.patch(`${ resource }/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${ token }`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
