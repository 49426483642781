














import Vue from 'vue';

export default Vue.extend({
  name: 'NotificationBadge',

  props: {
    notificationsLength: {
      type: Number,
      default: 0
    }
  }
})
