import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { getCompanyInitialStoreState } from '@/store/modules/company/helper';

export default {
  namespaced: true,
  state: getCompanyInitialStoreState(),
  getters,
  actions,
  mutations,
}
