import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { FiltersDataConstants } from '@/store/modules/filtersData/filters-types';
import { marketService } from '@/bundles/Markets/factory/marketServiceFactory';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';

const errorMapper = new ErrorMapper();

export default {
  async [FiltersDataConstants.GET_MARKETS] ({ commit }) {
    try {
      const data = await marketService.getMarkets();

      commit(FiltersDataConstants.SET_MARKETS, data);
      commit(FiltersDataConstants.SET_LOADED, true);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [FiltersDataConstants.GET_MEMBERS] ({ commit }) {
    try {
      const { data } = await memberService.list([{ name: 'take', value: 0 }]);

      commit(FiltersDataConstants.SET_MEMBERS, data);
      commit(FiltersDataConstants.SET_MEMBERS_LOADED, true);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
}
