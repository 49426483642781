















































import Vue, { PropType } from 'vue';
import { CropperFuncNamesEnum } from '@/bundles/Upload/helpers';

export default Vue.extend({
  name: 'UploadSidebar',

  props: {
    isFilePresent: Boolean,
    activeFunc: {
      type: String as PropType<CropperFuncNamesEnum>,
      default: null
    },
    isImg: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    cropperFuncNames (): typeof CropperFuncNamesEnum {
      return CropperFuncNamesEnum;
    }
  },

  methods: {
    turnFunc (name: CropperFuncNamesEnum) {
      this.$emit('turnFunc', name)
    },
    zoomChange (isZoomIn) {
      this.$eventBus.$emit('upload:zoom', isZoomIn);
    }
  }
})
