export const pipelineStages = [
  'Appointment Pending',
  'Appointment Scheduled',
  'Presentation Scheduled',
  'Exclusive Pending',
  'Exclusive Secured',
  'RFP/LOI',
  'Contract/Lease Working',
  'Under Contract',
  'On Hold',
  'Closed - Won',
  'Closed - Construction Pending',
  'Closed - Lost',
];
