export enum RootMutations {
  SET_TEMPORARY_AUTH_DATA = 'SET_TEMPORARY_AUTH_DATA',
  SET_AUTH_INFO = 'SET_AUTH_INFO',
  CLEAR_TEMPORARY_INFO = 'CLEAR_TEMPORARY_INFO',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_NAV_STATE = 'SET_NAV_STATE',
  SET_NAV_VIEW = 'SET_NAV_VIEW',
  SET_USER_LOGO = 'SET_USER_LOGO',
  SET_ACCOUNT_SETTING = 'SET_ACCOUNT_SETTING',
  SET_USER_INTEGRATIONS = 'SET_USER_INTEGRATIONS',
  SET_USER_ROLES = 'SET_USER_ROLES',
  UPDATE_APP_VERSION = 'UPDATE_APP_VERSION',
  SET_MAP_ZOOM = 'SET_MAP_ZOOM',
  SET_MAP_CARDS_STYLE = 'SET_MAP_CARDS_STYLE',
  SET_MAP_APPROVE_STYLE = 'SET_MAP_APPROVE_STYLE',
  SET_MAP_STYLE = 'SET_MAP_STYLE',
  SET_MUNICIPAL_LAYERS = 'SET_MUNICIPAL_LAYERS',
  SET_PROPERTY_GROUPING = 'SET_PROPERTY_GROUPING',
  SET_TRANSPORTATION_LAYERS = 'SET_TRANSPORTATION_LAYERS',
  SET_RESIDENTIAL_LAYERS = 'SET_RESIDENTIAL_LAYERS',
  SET_FARM_AND_RANCH_LAYERS = 'SET_FARM_AND_RANCH_LAYERS',
  SET_MULTI_FAMILY_LAYERS = 'SET_MULTI_FAMILY_LAYERS',
  SET_PACKAGE_LAYERS = 'SET_PACKAGE_LAYERS',
  SET_RETAIL_LAYERS = 'SET_RETAIL_LAYERS',
  SET_OFFICE_LAYERS = 'SET_OFFICE_LAYERS',
  SET_INDUSTRIAL_LAYERS = 'SET_INDUSTRIAL_LAYERS',
  SET_STANDARD_LAYERS = 'SET_STANDARD_LAYERS',
  SET_MAP_LAYERS = 'SET_MAP_LAYERS',
  SET_MAP_CENTER = 'SET_MAP_CENTER',
  CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA',
  SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN',
  AUTH_USER = 'AUTH_USER',
  SET_VISITOR_ID = 'SET_VISITOR_ID',
  ADD_CANCEL_TOKEN = 'ADD_CANCEL_TOKEN',
  CLEAR_CANCEL_TOKENS = 'CLEAR_CANCEL_TOKENS',
}

export enum NotificationMutations {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
}
