import { RecordMapper } from '@/bundles/App/models/RecordMapper';
import { BaseService } from '@/bundles/App/models/BaseService';
import { IUserLayoutWidget } from '@/bundles/Settings/interfaces/IUserLayoutWidget';
import { IUserLayout } from '@/bundles/Settings/interfaces/IUserLayout';

export default class UserLayoutServices extends BaseService<IUserLayout, RecordMapper<IUserLayout>> {
  async getLayoutSettings (page: 'dashboard'): Promise<IUserLayout> {
    try {
      const response = await this.transport.get(`/layout-settings/me?page=${page}`);

      return this.recordMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async initializeLayoutSettings ({ widgets, page }: { widgets: Array<IUserLayoutWidget>, page: 'dashboard' }): Promise<IUserLayout> {
    try {
      const response = await this.transport.post(`/layout-settings/`, { widgets, page });

      return this.recordMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateLayoutSettings ({ widgets, page }: { widgets: Array<IUserLayoutWidget>, page: 'dashboard' }): Promise<IUserLayout> {
    try {
      const response = await this.transport.patch(`/layout-settings/me?page=${page}`, { widgets, page });

      return this.recordMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
