import * as constants from './filters-types';
import { FILTERS_KEYS, getDefaultFilterObjectShape } from '@/bundles/Filters/helpers/getDefaultFiltersObj';

export default {
  [constants.SET_FILTER] (state, { filter, type }) {
    state[type].savedSetId = 'savedSet' in filter ? filter.savedSet : '';

    if ('savedSet' in filter) { // prevent savedSet from being saved in filters
      delete filter.savedSet;
    }

    state[type].filters = filter;
  },
  [constants.SET_FILTER_NAME] (state, { filterName, type }) {
    state[type].filterName = filterName;
  },
  [constants.SET_FILTER_ID] (state, { filterId, type }) {
    state[type].filterId = filterId;
  },
  [constants.SET_SEARCH] (state, { search, type }) {
    state[type].keyword = search;
  },
  [constants.TOGGLE_FILTERED] (state, { filtered, type }) {
    state.filters[type].filtered = filtered;
  },
  [constants.RESET_FILTER] (state, type) {
    state[type].filters = {};
  },
  [constants.SET_LAST_SELECTED_TYPE] (state, { source, type }) {
    state[source].lastSelectedType = type;
  },
  [constants.RESET_ALL_FILTERS] (state) {
    FILTERS_KEYS.forEach(key => {
      state[key] = getDefaultFilterObjectShape(key);
    });
  },
}
