import { JobFunction, JobFunctionLabels } from '@/bundles/Members/enums';
import { Nullable } from '@/utils/types';

export const getJobFunction = (jobFunction: JobFunction): Nullable<string> => {
  if (jobFunction && (jobFunction in JobFunctionLabels)) {
    return JobFunctionLabels[jobFunction];
  }

  return null;
}
