import ActivityService from './activity'
import ContactService from './contact'
import ContactsService from './contacts'
import CompanyService from './company'
import GeoService from './geo'
import TeamService from './team'
import MemberService from './member'
import SavedSetsService from './saved-sets'
import OrderService from './order'
import ListingService from './listing'
import OccupierService from './occupier'
import PropertyService from './property'
import SharingService from './sharing'
import PhotoService from './photo'
import CompensationService from './compensation'
import RequirementService from './requirement'
import AccountService from './account'
import AdminService from './admin'
import ComparableService from './comparable'
import PipelineService from './pipeline'
import DealsService from './deals'
import AttachmentsService from './attachments'
import AttachmentService from './attachment'
import SettingsService from './settings'
import ChangeLogService from './changeLog'
import SpatialService from './spatial'
import NotesService from './note'
import ProjectService from './project'
import AvailabilityService from './availability'
import InvoiceService from './invoice'
import ImportService from './import'
import DocumentsService from './documents'
import SubscriptionService from './subscription'
import IntegrationsService from './integrations'
import TaskService from './task'
import RequestsService from './requests'
import FilterView from './filter-view'
import Eblast from './eblast'
import PublicService from './public'
import PressRelease from './pressrelease'
import BrokerService from './broker'
import ReportService from './report';
import ReportTemplateService from './report-template';
import MarketsService from './markets';
import ParcelService from './parcel';
import ApiService from './api';
import OutlookService from './outlook';
import DealiusService from './dealius';

const services = {
  activity: ActivityService,
  contact: ContactService,
  contacts: ContactsService,
  company: CompanyService,
  geo: GeoService,
  team: TeamService,
  member: MemberService,
  savedSets: SavedSetsService,
  order: OrderService,
  listing: ListingService,
  occupier: OccupierService,
  property: PropertyService,
  sharing: SharingService,
  photo: PhotoService,
  compensation: CompensationService,
  requirement: RequirementService,
  account: AccountService,
  admin: AdminService,
  comparable: ComparableService,
  pipeline: PipelineService,
  attachments: AttachmentsService,
  attachment: AttachmentService,
  deals: DealsService,
  settings: SettingsService,
  changeLog: ChangeLogService,
  spatial: SpatialService,
  note: NotesService,
  project: ProjectService,
  availability: AvailabilityService,
  import: ImportService,
  invoice: InvoiceService,
  documents: DocumentsService,
  subscription: SubscriptionService,
  integrations: IntegrationsService,
  task: TaskService,
  requests: RequestsService,
  'filter-view': FilterView,
  mailchimp: Eblast,
  eblast: Eblast,
  public: PublicService,
  pressrelease: PressRelease,
  brokers: BrokerService,
  report: ReportService,
  'report-template': ReportTemplateService,
  markets: MarketsService,
  parcel: ParcelService,
  api: ApiService,
  outlook: OutlookService,
  dealius: DealiusService,
}

export const ServiceFactory = {
  get: (name) => services[name],
}
