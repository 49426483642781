import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/eblast';

export default {
  list: async function ({ params = [], token }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/campaigns${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendTest: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/test`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendEblast: async function ({ token, id }) {
    try {
      const payload = {};
      return await instance.post(`${resource}/${id}/send`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOne: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`eblast/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/contacts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateSubject: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/subject`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addRequirements: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/addRequirement`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getComparable: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/comparable`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createCampaign: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getShared: async function (id) {
    try {
      return await instance.get(`${resource}/shared/${id}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateSender: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/sender`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateRecipients: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/recipients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
