import { ICustomError } from '@/bundles/Http/interfaces/ICustomError';

export const REQUEST_CANCELLED_STATUS = -1; // should be less than 0, so will not be added to notifications
export const REQUEST_CANCELLED_MESSAGE = 'Request cancelled';

export default class ErrorMapper {
  getErrorMessage (error: any): Error {
    let message = error.message;

    if (error.response && 'data' in error.response) {
      message = error.response.data.message || error.response.data.error;
    }

    return new Error(message);
  }
  mapToCodeModel (error: any): ICustomError {
    const isCustomError = this.getErrorMessage(error).message === REQUEST_CANCELLED_MESSAGE;
    const defaultStatus = isCustomError ? REQUEST_CANCELLED_STATUS : 400;

    const parsedError = {
      message: this.getErrorMessage(error).message,
      title: error?.response?.statusText || 'Error',
      status: error?.response?.status || defaultStatus,
      url: error?.request?.responseURL
    };

    throw parsedError;
  }
}
