









import Vue, { PropType } from 'vue';
import { SaleConditionsEnum, SaleConditionsLabelsObj } from '@/bundles/Comparables/enums/SaleConditionsEnum';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

interface ISaleConditionsLabel {
  SaleConditionLabel: typeof SaleConditionsLabelsObj;
}

export default Vue.extend({
  name: 'SaleConditionsLabel',

  components: { CommonChip },

  props: {
    saleConditions: {
      type: Array as PropType<SaleConditionsEnum[]>,
      required: true,
    },
  },

  data: (): ISaleConditionsLabel => ({
    SaleConditionLabel: SaleConditionsLabelsObj,
  }),
})
