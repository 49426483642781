// TODO: use this enum everywhere property type is used
export enum PropertyTypeEnum {
  healthcare = 'Healthcare',
  hospitality = 'Hospitality',
  industrial = 'Industrial',
  land = 'Land',
  multiFamily = 'Multifamily',
  office = 'Office',
  retail = 'Retail',
  selfStorage = 'Self-Storage',
  specialPurpose = 'Special Purpose',
}
