import { ICompany } from '@/bundles/Companies/types';
import { Nullable } from '@/utils/types';

export interface IFile {
  url: string;
  name: string;
  mimetype: string;
}

export enum ExportRecordType {
  occupiers = 'occupiers',
  companies = 'companies',
  properties = 'properties',
  contacts = 'contacts',
  availabilities = 'availabilities',
  comparables = 'comparables',
  deals = 'deals',
  broker_deals = 'broker_deas',
  requirements = 'requirements',
  saved_sets = 'saved_sets',
  projects = 'projects',
  pipelines = 'pipelines',
  teams = 'teams',
  users = 'users',
  broker_gross = 'broker_gross',
  open_invoices = 'open_invoices'
}

export enum ExportType {
  list_export = 'list_export',
}

export interface IKeywords {
  neighbors: ICompany[];
  location: string[];
  highlights: string[];
  advantages: string[];
  general: string[];
}

export interface IComments {
  public: Nullable<string>;
  private: Nullable<string>;
}

export enum QuarterEnum {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export const QuarterMonths = {
  [QuarterEnum.Q1]: ['January', 'March'],
  [QuarterEnum.Q2]: ['April', 'June'],
  [QuarterEnum.Q3]: ['July', 'September'],
  [QuarterEnum.Q4]: ['October', 'December'],
}

export const QuarterLabels = {
  [QuarterEnum.Q1]: 'Q1 (Jan - March)',
  [QuarterEnum.Q2]: 'Q2 (April - June)',
  [QuarterEnum.Q3]: 'Q3 (July - September)',
  [QuarterEnum.Q4]: 'Q4 (October - December)',
}
