import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { subscriptionService } from '@/bundles/Subscriptions/factory/subscriptionServiceFactory';
import { SubscriptionsActions } from './subscription-types';

const errorMapper = new ErrorMapper();

export default {
  async [SubscriptionsActions.GET_SUBSCRIPTIONS] ({ commit }) {
    try {
      const subscriptions = await subscriptionService.getSubscriptions();

      commit(SubscriptionsActions.SET_SUBSCRIPTIONS, subscriptions);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
}
