import { IBaseArangoModel } from '@/bundles/App/interfaces/IBaseArangoModel';
import BaseModel from '@/bundles/App/models/BaseModel';

export class BaseArangoModel extends BaseModel implements IBaseArangoModel {
  _id: string;
  _key: string;

  constructor (payload: IBaseArangoModel) {
    super(payload);
    this._id = payload._id;
    this._key = payload._key;
  }
}
