import Vue from 'vue';

const DialogMixin = Vue.extend({
  props: {
    value: Boolean,
  },

  computed: {
    showDialog: {
      get (): boolean {
        return this.value;
      },
      set (value: boolean) {
        this.$emit('input', value);
      }
    },
  },
})

export { DialogMixin }
