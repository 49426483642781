import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';

const NBRPropertyTypeClassDictionary = {
  [PropertyTypeEnum.industrial]: 'nbr-industrial',
  [PropertyTypeEnum.land]: 'nbr-land',
  [PropertyTypeEnum.healthcare]: 'nbr-healthcare',
  [PropertyTypeEnum.multiFamily]: 'nbr-multi-family',
  [PropertyTypeEnum.office]: 'nbr-office',
  [PropertyTypeEnum.retail]: 'nbr-retail',
  [PropertyTypeEnum.selfStorage]: 'nbr-self-storage',
  [PropertyTypeEnum.hospitality]: 'nbr-hospitality',
  [PropertyTypeEnum.specialPurpose]: 'nbr-special-purpose',
}

export const getNBRPropertyTypeClass = (propertyType: string): string => {
  return NBRPropertyTypeClassDictionary[propertyType] || 'nbr-default';
}
