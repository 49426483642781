import { Nullable } from '@/utils/types';
import { capitalize } from '@/utils/filters';

export const getDealTransactionTypeLabel = (deal: Nullable<Record<string, any>>): Nullable<string> => {
  const capitalizedDealTransactionType = capitalize(deal?.transaction?.transaction_type || deal?.transaction_type);

  if (deal?.comparable?.transaction?.investment_sale || deal?.comparable_transaction_investment_sale) {
    return `${capitalizedDealTransactionType} - Investment`;
  }

  if (deal?.comparable?.transaction?.sublease || deal?.comparable_transaction_sublease) {
    return `${capitalizedDealTransactionType} - Sublease`;
  }

  return capitalizedDealTransactionType || null;
}
