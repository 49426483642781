const types = [
  'Companies',
  'Contacts',
  'Occupiers',
  'Requirements',
  'Properties',
  'Availabilities',
  'Comparables',
  'Projects',
  'Saved Sets',
  'Pipelines',
  'Closed Deals',
];

export const getAssociatedTypes = (allowAll: boolean): string[] => {
  return allowAll ? ['All Types', ...types] : types;
}
