import instance from '../instance';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/parcel';

export default {
  getOne: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/photos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
