export * from './propertyHeadersData';
export * from './mapHelpers';

export const sprinklerTypes = [
  'No',
  'Calc',
  'Yes',
  'Dry',
  'Wet',
  'Class IV',
  'ESFR',
  'ESFR K14',
  'ESFR K17',
  'ESFR K22',
  'ESFR 22.4',
  'ESFR K25',
  'ESFR K28'
];
