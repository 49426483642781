import * as constants from './project-types'

export default {
  [constants.UPDATE_PROJECT] (state, project) {
    state.projects = state.projects.map((item) => {
      return item._key === project._key ? project : item
    })
  },
  [constants.SET_VIEW] (state, view) {
    state.fullscreen = view
  },
}
