

























import mixins from 'vue-typed-mixins';
import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';
import { PropType } from 'vue';
import { TaskCreateUpdateDTO } from '@/bundles/Tasks/dto/TaskCreateUpdateDTO';
import { taskService } from '@/bundles/Tasks/factories/taskServiceFactory';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { Nullable } from '@/utils/types';

import BaseTaskForm from '@/bundles/Tasks/components/BaseTaskForm.vue';
import ConfirmationDialog from '@/bundles/Common/components/ConfirmationDialog.vue';

interface IEditTaskForm {
  saveLoading: boolean;
  showConfirmationDialog: boolean;
}

export default mixins(DialogMixin).extend({
  name: 'EditTaskForm',

  components: { BaseTaskForm, ConfirmationDialog },

  props: {
    collectionType: {
      type: String,
      default: null
    },
    associatedRecords: {
      type: Array as PropType<any[]>, // todo: type this
      default: () => []
    },
    taskEntry: {
      type: Object as PropType<Nullable<ITask>>,
      default: null
    },
  },

  data: (): IEditTaskForm => ({
    saveLoading: false,
    showConfirmationDialog: false
  }),

  methods: {
    async submitHandler (task: TaskCreateUpdateDTO) {
      try {
        if (!this.taskEntry) {
          return;
        }

        this.saveLoading = true;

        const newTask = await taskService.updateTask({
          task,
          id: (this.taskEntry as ITask)._key
        });

        if (this.taskEntry.assignee !== task.assignee) { // need to fetch tasks in different sections
          this.$eventBus.$emit('fetch:tasks');
        } else {
          this.$emit('submit', newTask);
        }
        this.showDialog = false;
      } catch (error: any) {
        this.addNotification(error);
      } finally {
        this.saveLoading = false;
      }
    },
    async archiveHandler () {
      try {
        if (!this.taskEntry) {
          return;
        }
        this.showConfirmationDialog = false;
        this.saveLoading = true;
        await taskService.archive({ keys: [(this.taskEntry as ITask)._key] });

        const tasksText = this.$tc(this.LocalizationEnum.task);
        const wasText = this.$tc(this.LocalizationEnum.was);

        this.$emit('fetch:tasks');
        this.showDialog = false;
        this.addNotification({
          title: 'Success',
          status: 200,
          message: `${tasksText} '${this.taskEntry.title}' ${wasText} archived.`,
        });
      } catch (error: any) {
        this.addNotification(error);
      } finally {
        this.saveLoading = false;
      }
    },
    closeHandler () {
      this.showDialog = false;
      this.$emit('close');
    }
  }
});
