<template>
  <v-main class="sign-page">
    <v-container fill-height>
      <v-row>
        <v-col
          cols="12"
          sm="8"
          lg="6"
          offset-lg="3"
          class="mx-auto"
        >
          <SignInCard />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SignInCard from '@/components/SignInCard';

export default {
  name: 'Welcome',

  components: { SignInCard },
}
</script>

<style lang="scss" scoped>
.sign-page {
  background: linear-gradient(90deg, #184f48 0%, #8eb380 100%);
}
</style>
