import { IAssigneeCompletedInfo, ICollection, ITask } from '@/bundles/Tasks/interfaces/ITask';
import { TaskTypesEnum } from '@/bundles/Tasks/enums/TaskTypesEnum';
import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';

export class Task extends BaseArangoModel implements ITask {
  title: string;
  type: TaskTypesEnum;
  assignee: number;
  due_date: string; // date string
  due_time: string; // (hh:mm)
  description: string;
  is_recurrent: boolean;
  schedule?: string; // cron expression
  schedule_parsed?: any; // add interface
  collaborators: number[];
  status: string;
  collections: ICollection[];
  marketing: boolean;
  assignee_info?: IAssigneeCompletedInfo;
  completed_by?: IAssigneeCompletedInfo;

  constructor (payload: ITask) {
    super(payload);
    this.title = payload.title;
    this.type = payload.type;
    this.assignee = payload.assignee;
    this.due_date = payload.due_date;
    this.due_time = payload.due_time;
    this.description = payload.description;
    this.is_recurrent = payload.is_recurrent;
    this.status = payload.status;
    this.marketing = payload.marketing;

    if (payload.is_recurrent) {
      this.schedule = payload.schedule;
      this.schedule_parsed = payload.schedule_parsed;
    }

    this.collections = payload.collections || [];
    this.collaborators = payload.collaborators || [];

    if (payload.assignee_info) {
      this.assignee_info = payload.assignee_info;
    }

    if (payload.completed_by) {
      this.completed_by = payload.completed_by;
    }
  }
}
