import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { FILTERS_KEYS, getDefaultFilterObjectShape } from '@/bundles/Filters/helpers/getDefaultFiltersObj';

function filterStateBuilder () {
  const state = {};

  FILTERS_KEYS.forEach(key => {
    state[key] = getDefaultFilterObjectShape(key);
  });

  return state;
}

const initialState = filterStateBuilder();

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
