import { roundToDecimal } from '@/bundles/App/helpers/mathHelpers';
import { Nullable } from '@/utils/types';

export function getPipelinesMonthsOpen (days: Nullable<number>): Nullable<number> {
  if (!days) {
    return null;
  }

  return roundToDecimal(days / 30, 1);
}

export function getPipelinesMonthsOpenLabel (days: Nullable<number>, pipelineStage: number): Nullable<string> {
  if (pipelineStage >= 10) {
    return 'Closed';
  }

  if (!days) {
    return null;
  }

  return `${getPipelinesMonthsOpen(days)} months open`;
}
